import React from 'react';

/** @deprecated Use Icons.Action.Question instead.*/
const Question = () => (
  <svg viewBox="0 0 21 21" width="1em" height="1em">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle cx={9.5} cy={9.5} r={9.5} stroke="#000" strokeWidth={1.5} />
      <text
        fill="#000"
        fontFamily="IBMPlexMono-SemiBold, IBM Plex Mono SemiBold"
        fontSize={13}
        fontWeight={500}
      >
        <tspan x={6} y={14}>
          {`?`}
        </tspan>
      </text>
    </g>
  </svg>
);

export default Question;
