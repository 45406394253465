import React from 'react';

/** @deprecated Use Icons.Action.Remove instead.*/
const Remove = () => (
  <svg viewBox="0 0 26 26" width="1em" height="1em">
    <g
      transform="translate(1 1)"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    >
      <rect width={24} height={24} stroke="#E4172F" rx={12} />
      <path stroke="#E2001A" d="M5 12.5h14" />
    </g>
  </svg>
);

export default Remove;
