import React from 'react';

const Communications = () => (
  <svg viewBox="0 0 1024 948.102" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M961.789 0H393.176q-26.129 0-44.17 18.041-18.041 18.042-18.041 42.926v236.404H62.211q-26.128 0-44.17 18.041Q0 333.453 0 359.582v573.589q0 3.733 2.488 7.466 2.489 3.732 6.222 4.977 1.244 1.244 3.11 1.866 1.867.622 3.111.622 2.488 0 4.977-1.244l4.977-2.489L185.39 807.504h445.434q24.884 0 42.926-18.041 18.041-18.041 18.041-44.17V510.134h145.575l161.749 135.621q2.489 2.488 4.977 3.11 2.489.622 4.977.622h3.111q1.866 0 3.11-1.244 3.733-1.244 6.222-4.977 2.488-3.733 2.488-8.709V62.211q0-26.128-18.041-44.17Q987.917 0 961.789 0zM663.174 745.293q0 13.686-9.332 23.018t-23.018 9.332H180.413q-2.488 0-4.977.622-2.488.622-4.977 3.11L29.861 900.821V359.582q0-13.686 9.332-23.018t23.018-9.332h568.613q13.686 0 23.018 9.332t9.332 23.018v385.711z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Communications;
