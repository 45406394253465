import React from 'react';

const Divider = () => (
  <svg viewBox="0 0 3 20" width="1em" height="1em">
    <g fill="none" fillRule="evenodd">
      <path fill="#F1E9E9" d="M3 0v20H2V0z" />
      <path fill="#F4F0F0" d="M2 0v20H0V0z" />
    </g>
  </svg>
);

export default Divider;
