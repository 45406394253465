import React from 'react';

const Upload = () => (
  <svg viewBox="0 0 1024 778.498" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M907.71 325.182q0-5.384.538-10.229.539-4.846.539-10.23 0-62.452-24.766-118.443-23.689-54.915-65.144-96.37-41.455-41.456-96.37-65.144Q666.515 0 604.063 0q-44.147 0-85.064 11.844-40.917 12.922-75.912 34.457-34.994 21.535-64.067 52.761-29.073 31.226-47.378 69.99-19.381-16.152-43.07-24.766-23.689-8.614-49.531-8.614-59.222 0-101.216 41.994-41.993 41.993-41.993 101.215 0 12.921 2.692 25.842 2.692 12.922 8.075 24.766-49.531 24.765-78.065 71.605Q0 447.933 0 503.924q0 79.681 57.068 137.287 57.069 57.607 137.826 57.607H491.003v60.299q0 7.537 5.922 13.459 5.923 5.922 14.537 5.922h85.064q8.614 0 14.536-5.922 5.922-5.922 5.922-13.459v-60.299H829.106q79.681 0 137.287-57.607Q1024 583.605 1024 503.924q0-59.222-31.764-107.137-31.765-47.916-84.526-71.605zm-78.604 347.794H616.984V528.69h78.604q9.691 0 13.998-4.307 4.307-4.307 5.384-6.461 3.23-5.384 1.615-11.306-1.615-5.922-5.923-11.306l-135.671-152.9q-7.538-8.614-19.921-8.614-12.382 0-19.92 8.614L397.325 494.233q-6.461 7.538-5.922 13.46.538 5.922 1.615 9.152 1.077 2.154 5.384 6.461t13.998 4.307h78.603v145.363H198.124h-3.23q-69.99 0-119.521-49.531-49.531-49.531-49.531-119.521 0-51.684 27.996-93.678t74.297-62.452q2.153-1.077 4.307-2.692 2.153-1.615 2.153-4.845 1.077-2.154 1.077-4.846t-1.077-4.845q-6.46-12.921-9.691-25.843-3.23-12.921-3.23-25.842 0-48.454 34.456-82.91 34.457-34.457 82.911-34.457 24.766 0 47.378 9.691 22.611 9.691 39.84 29.073 2.153 2.153 4.845 3.23 2.692 1.077 5.922 0 3.231 0 5.922-2.154 2.692-2.153 3.769-5.383 33.38-77.527 103.369-123.828 69.99-46.301 153.977-46.301 57.068 0 107.676 22.612 51.685 21.535 89.371 59.222 37.687 37.687 59.222 89.371 22.612 50.608 22.612 107.676 0 7.538-.538 13.998-.538 6.461-1.615 13.998 0 4.307 2.153 7.538 2.154 3.23 6.461 5.383 49.531 19.382 79.142 61.914 29.611 42.532 29.611 96.37 0 69.99-49.531 119.521-49.531 49.531-119.521 49.531z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Upload;
