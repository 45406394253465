import React from 'react';

const Favorite = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <g clipPath="url(#clip0_918_6532)">
      <path
        d="M12.244 14.941a.301.301 0 00-.117-.048.714.714 0 00-.244 0 .184.184 0 00-.108.048l-3.77 2.629 1.446-4.264a.375.375 0 00-.156-.448l-3.693-2.472h4.571c.092 0 .17-.059.235-.175a1.82 1.82 0 00.137-.292l1.445-4.323 1.387 4.323c.026.077.075.175.147.291.071.117.153.176.244.176h4.65l-3.79 2.472a.412.412 0 00-.137.195.42.42 0 000 .253l1.524 4.264-3.77-2.629zM20 9.822a.394.394 0 00-.166-.175.464.464 0 00-.225-.058h-5.528L12.4 4.312a.44.44 0 00-.167-.244A.413.413 0 0012.01 4a.503.503 0 00-.244.058.394.394 0 00-.166.176L9.92 9.588H4.39a.464.464 0 00-.224.058.395.395 0 00-.166.175c0 .078.007.166.02.263.013.097.058.165.136.204l4.474 3.115-1.68 5.18c-.04.09-.049.168-.03.233.02.065.069.136.147.214.04.039.104.065.196.078.09.013.175 0 .253-.04l4.494-3.192 4.473 3.193c.04.039.118.058.235.058.117 0 .195-.02.234-.058a.245.245 0 00.157-.205c.013-.097.02-.191.02-.282l-1.68-5.179 4.473-3.115c.039-.039.062-.107.068-.204.007-.097.01-.185.01-.263z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_918_6532">
        <path fill="#fff" transform="translate(4 4)" d="M0 0h16v15.127H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Favorite;
