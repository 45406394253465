import styled from 'styled-components';
import { constants } from './constants';

export const Backdrop = styled.div<{ active?: boolean }>`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  background: rgba(8, 5, 5, 0.25);
  transition: opacity ${constants.NAV_ANIMATION_TIME}ms;
`;
