export enum Color {
  // legacy color palette, to be deleted
  /** @deprecated Use grey90 instead */
  black = '#262626',
  /** @deprecated Use grey90 instead */
  blackTwo = '#292929',
  /** @deprecated Use grey70 instead */
  brownishGrey = '#6b6b6b',
  /** @deprecated Use red40 instead */
  carnation = '#f07f8c',
  /** @deprecated Use grey50 instead */
  coolGrey = '#8e8f90',
  /** @deprecated Use grey50 instead */
  darkGrey = '#a8a8a8',
  /** @deprecated Use green50 instead */
  greenHaze = '#00B630',
  /** @deprecated Use grey50 instead */
  greyish = '#a6a6a6',
  /** @deprecated Use grey40 instead */
  greyishTwo = '#afafaf',
  /** @deprecated Use red40 instead */
  lightPink = '#fbd9dd',
  /** @deprecated Use grey30 instead */
  pinkishGrey = '#cccccc',
  /** @deprecated Use grey30 instead */
  pinkishGreyTwo = '#d1d1d1',
  /** @deprecated Use grey70 instead */
  possumGrey = '#4f4f4f',
  /** @deprecated Use grey70 instead */
  rdBlackTint2 = '#595959',
  /** @deprecated Use grey30 instead */
  rdBlackTint6 = '#d9d9d9',
  /** @deprecated Use white instead */
  rdBrightWhite = '#ffffff',
  /** @deprecated Use red40 instead */
  rdDarkRed = '#e4172f',
  /** @deprecated Use grey20 instead */
  rdGrey = '#e6e6e6',
  /** @deprecated Use grey70 instead */
  rdLightBlack = '#4e4e4e',
  /** @deprecated Use grey0 instead */
  rdLightRed = '#fef7f8',
  /** @deprecated Use grey100 instead */
  rdPureBlack = '#000000',
  /** @deprecated Use red40 instead */
  rdRed = '#e2001a',
  /** @deprecated Use red50 instead */
  rdRedHover = '#b10b1f',
  /** @deprecated Use red40 instead */
  scarlet = '#d50018',
  /** @deprecated Use grey30 instead */
  shadowIdle = 'rgba(0,0,0,0.15)',
  /** @deprecated Use red40 instead */
  softPink = '#f6b2ba',
  /** @deprecated Use red40 instead */
  strawberry = '#e83348',
  /** @deprecated Use grey50 instead */
  warmGrey = '#929292',
  /** @deprecated Use grey50 instead */
  warmGreyTwo = '#9d9d9d',
  /** @deprecated Use grey10 instead */
  whiteSmoke = '#f4f4f4',
  /** @deprecated Use grey20 instead */
  whiteThree = '#f4f0f0',
  /** @deprecated Use grey10 instead */
  whiteTwo = '#f7f7f7',
  /** @deprecated Use grey10 instead */
  white99 = 'rgba(245, 245, 245, 0.99)',

  // new color palette: https://zpl.io/a8nomNd
  red40 = 'var(--colors-red-40)',
  red50 = 'var(--colors-red-50)',
  red60 = 'var(--colors-red-60)',
  red70 = 'var(--colors-red-70)',
  white = 'var(--colors-white)',
  grey0 = 'var(--colors-grey-0)',
  grey10 = 'var(--colors-grey-10)',
  grey20 = 'var(--colors-grey-20)',
  grey30 = 'var(--colors-grey-30)',
  grey40 = 'var(--colors-grey-40)',
  grey50 = 'var(--colors-grey-50)',
  grey60 = 'var(--colors-grey-60)',
  grey70 = 'var(--colors-grey-70)',
  grey80 = 'var(--colors-grey-80)',
  grey90 = 'var(--colors-grey-90)',
  grey100 = 'var(--colors-grey-100)',
  green40 = 'var(--colors-green-40)',
  green50 = 'var(--colors-green-50)',
  green60 = 'var(--colors-green-60)',
}
