import React from 'react';

const Download = () => (
  <svg viewBox="0 0 695.403 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M669.203 972.691H26.2q-10.916 0-18.558 7.642Q0 987.974 0 997.8q0 10.916 7.642 18.558Q15.284 1024 26.2 1024h643.003q10.916 0 18.558-7.642 7.642-7.642 7.642-18.558 0-9.826-7.642-17.467-7.642-7.642-18.558-7.642zm-37.118-518.55L371.173 713.962V25.109q0-10.917-7.642-18.013Q355.889 0 346.064 0q-10.917 0-18.013 7.096-7.096 7.096-7.096 18.013v688.853L60.043 454.141q-4.367-4.367-8.734-6.005-4.367-1.637-9.825-1.637-4.367 0-9.279 1.637-4.913 1.638-8.188 6.005-7.642 7.642-7.642 18.013 0 10.371 7.642 18.012l303.488 302.397q3.275 4.367 8.188 6.004 4.913 1.638 10.371 1.638t9.825-1.638q4.367-1.637 7.642-4.912l303.488-303.489q7.642-7.641 7.642-18.012t-7.642-18.013q-6.55-7.642-17.467-7.642-10.917 0-17.467 7.642z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Download;
