import React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../Foundation';
import { IconWrapper } from '../../Icons';
import ArrowLeft from '../../Icons/generated/ArrowLeft';
import ArrowRight from '../../Icons/generated/ArrowRight';

type ButtonPosition = 'left' | 'right';
type ButtonProps = {
  position: ButtonPosition;
};

const Button = styled.button<ButtonProps>`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus {
    outline: none;
  }

  ${(props) =>
    props.position === 'left' &&
    css`
      left: 5px;
    `}
  ${(props) =>
    props.position === 'right' &&
    css`
      right: 5px;
    `}
`;

type CarouselButtonProps = ButtonProps & {
  className?: string;
  onClick?: () => void;
};

export const CarouselButton = ({
  position,
  className,
  onClick,
}: CarouselButtonProps) => {
  // Check if className (coming from react-slick) contains "disabled"
  const active = (className || '').indexOf('slick-disabled') < 0;
  const iconColor = active ? Color.rdRed : '#c5c5c5';

  // Set a aria-label text
  const ariaLabel = position === 'left' ? 'Previous slide' : 'Next slide';

  return (
    <Button
      type="button"
      position={position}
      onClick={onClick}
      disabled={!active}
      aria-label={ariaLabel}
    >
      {position === 'left' ? (
        <IconWrapper fill={iconColor}>
          <ArrowLeft />
        </IconWrapper>
      ) : (
        <IconWrapper fill={iconColor}>
          <ArrowRight />
        </IconWrapper>
      )}
    </Button>
  );
};
