import styled, { css } from 'styled-components';
import { Color, RddkTheme } from '../../Foundation';
import { Box } from '../../Layout';

const stepHover = css`
  box-sizing: initial;
  padding: 3px 0;
`;
const stepHoverPast = css`
  transition: box-shadow 0.3s ease-in-out;
  :hover,
  :focus {
    box-shadow: 0 3px 0 0 #d8d8d8;
  }
  :focus {
    outline: none;
  }
  :focus:not(:focus-visible) {
    box-shadow: none;
  }
`;
const stepItemStyled = css`
  height: 16px;
  font-family: ${RddkTheme.fonts.sans};
  font-size: 16px;
  font-style: italic;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.darkGrey};
`;
const linkStyled = css`
  a {
    text-decoration: none;
    color: inherit;
    transition: box-shadow 0.3s ease-in-out;
    :focus {
      box-shadow: 0 3px 0 0 #d8d8d8;
    }
    :focus {
      outline: none;
    }
    :focus:not(:focus-visible) {
      box-shadow: none;
    }
  }
`;
export const StepItemStyled = styled(Box)`
  ${stepItemStyled}
  ${stepHover}
  ${linkStyled}
`;
export const PastStepItemStyled = styled(Box)`
  ${stepItemStyled}
  ${stepHover}
  ${stepHoverPast}
  border:none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: ${Color.black};
  ${linkStyled}
`;
export const StepDividerStyled = styled(Box)`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 13px;
  font-weight: 600;
  opacity: 0.67;
  color: #f1e9e9;
`;
