import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  zIndex,
  ZIndexProps,
} from 'styled-system';

const boxProps = compose(
  space,
  color,
  layout,
  flexbox,
  border,
  shadow,
  textAlign,
  position,
  zIndex
);

export interface BoxProps
  extends BorderProps,
    ColorProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    ShadowProps,
    TextAlignProps,
    PositionProps,
    ZIndexProps {}

export const Box = styled.div<BoxProps>(boxProps);

Box.displayName = 'Box';
