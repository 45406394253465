import React from 'react';

const CallInformation = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M188.789 62.133l-94.395 94.394q-21.507 20.313-32.261 47.198-10.754 26.884-10.754 55.561 0 29.872 10.754 56.159t32.261 47.794L665.54 934.385q43.015 43.015 101.564 41.223 58.548-1.792 101.563-44.808l94.395-94.394-180.425-180.425-69.302 69.303q-7.169 7.169-18.521 7.169-11.351 0-18.52-7.169L298.716 347.706q-7.169-7.169-7.169-17.923 0-10.754 7.169-17.923l69.303-70.497-179.23-179.23zM764.714 1024q-37.041 0-73.484-14.338-36.444-14.339-65.12-43.016L58.548 399.085Q0 341.732 0 259.883q0-81.848 58.548-139.202L170.866 8.364Q178.035 0 188.789 0q10.754 0 17.923 8.364l216.27 215.076q7.17 8.364 7.17 18.52 0 10.157-7.17 17.326l-70.497 70.497 341.732 341.732 70.497-69.303q7.169-8.364 17.923-8.364 10.754 0 17.923 8.364l216.271 216.271q7.169 7.169 7.169 17.923 0 10.754-7.169 17.923L904.513 966.646q-28.676 28.677-65.12 43.016Q802.95 1024 764.714 1024z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default CallInformation;
