import snakeCase from 'lodash/snakeCase';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Color, TextType, Typography } from '../../Foundation';
import { Icons } from '../../Icons';
import { mediaQuery } from '../../Layout';

const Container = styled.div`
  position: relative;
  border-top: 1px ${Color.grey20} solid;

  &:hover {
    background: #fafafa;
  }

  &:last-child {
    border-bottom: 1px ${Color.grey20} solid;
  }
`;

const TextWrapper = styled.div`
  padding-bottom: 10px;
`;

const FoldWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  perspective: 1000px;
  border-left: 4px solid transparent;
  transition: max-height 0.4s ease-out, border-left 0.4s ease-out;
  padding: 0 20px;
`;

const IconWrapper = styled.div`
  transform-origin: center center;
  transition: transform 1s ease;
  transition-delay: 0.25s;
  height: 14px;
`;

const Label = styled.label`
  position: relative;
  padding: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-left: 4px solid transparent;
  transition: border-left 0.4s;

  ${mediaQuery.sm} {
    min-height: 60px;
  }
`;

const Title = styled(Typography)`
  padding: 10px 15px 10px 0;
`;

type CheckboxProps = {
  maxHeight: number;
};
const Checkbox = styled.input.attrs({ type: 'checkbox' })<CheckboxProps>`
  position: absolute;
  opacity: 0;
  &:checked ~ ${FoldWrapper} {
    max-height: ${({ maxHeight }) => maxHeight}px;
    transition-duration: 0.7s;
  }
  &:checked ~ ${Label} ${IconWrapper} {
    transition-delay: 0s;
    transform: rotateX(180deg);
  }
  &:checked ~ ${Label}, &:checked ~ ${FoldWrapper} {
    border-left: 4px ${Color.red40} solid;
    background: #fafafa;
  }
`;

export type TextAccordionItemProps = {
  title: string;
  displayAsLink?: boolean;
  children?: any;
  itemId?: any;
  titleType?: TextType;
};

export const TextAccordionItem: React.FC<TextAccordionItemProps> = ({
  title,
  children,
  itemId,
  displayAsLink,
  titleType = 'h6',
}) => {
  const id = itemId ? itemId : snakeCase(title);
  const ref = useRef<HTMLDivElement>(null);
  const [checked, setChecked] = useState(false);
  const textWrapper = useRef<HTMLDivElement>(null);
  const [maxHeight, setHeight] = useState(0);
  let pageRefresh: boolean = true;

  /**
   * Function to handle clicking on the label.
   */
  const handleClick = () => {
    setChecked(!checked);
    pageRefresh = false;
    if (displayAsLink) {
      window.history.pushState(null, 'null', '#' + id);
    }
    setAccordionItemMaxHeight();
  };

  /**
   * Function to set max height for the accordion item.
   */
  const setAccordionItemMaxHeight = () => {
    let elementHeight = textWrapper?.current?.clientHeight ?? 0;
    if (elementHeight > 0) elementHeight += 16;
    setHeight(elementHeight);
  };

  /**
   * When the window.location.hash changes (eg user clicks an anchor link)
   * the function checks if the anchor link matches this item ID and then
   * scrolls it into the viewport and opens it.
   */
  const handleLocationHashChange = useCallback(() => {
    const isLinked = window?.location?.hash === '#' + id;
    if (!isLinked) {
      return;
    }

    if ((!displayAsLink && !checked) || pageRefresh) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

        setChecked(true);
        pageRefresh = false;
        setAccordionItemMaxHeight();
      }, 250);
    }
  }, [id]);

  /**
   * On mount, run the handleLocationHashChange function and add popstate listener to it.
   */
  useEffect(() => {
    handleLocationHashChange();
    window.addEventListener('popstate', handleLocationHashChange);
    return () =>
      window.removeEventListener('popstate', handleLocationHashChange);
  }, [handleLocationHashChange]);

  const onKeyPress = (event: any) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <Container ref={ref} tabIndex={0} onKeyPress={onKeyPress}>
      <Checkbox
        id={id}
        checked={checked}
        maxHeight={maxHeight}
        readOnly
      ></Checkbox>
      <Label htmlFor={id} onClick={handleClick}>
        <Title type={titleType} as="h3">
          {title}
        </Title>
        <IconWrapper className="accordion-icon">
          <Icons.DownArrow />
        </IconWrapper>
      </Label>
      <FoldWrapper>
        <TextWrapper ref={textWrapper}>{children}</TextWrapper>
      </FoldWrapper>
    </Container>
  );
};
