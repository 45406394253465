import React from 'react';

const Lines = () => (
  <svg viewBox="0 0 26 12" fill="none" width="1em" height="1em">
    <path
      d="M.667 11.99v-1.542h18.885v1.541H.667zm2.875-5.22V5.23h18.885v1.54H3.542zm2.906-5.218V.011h18.885v1.541H6.448z"
      fill="#424242"
    />
  </svg>
);

export default Lines;
