import React from 'react';

/** @deprecated */
const Share = () => (
  <svg viewBox="0 0 16 16" width="1em" height="1em">
    <g stroke="#000" strokeWidth={2} fill="none" fillRule="evenodd">
      <path d="M8.47 0H16v6.588M4.765 11.235L15.406.471" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 11.294l-.097 3.732a1 1 0 01-1 .974H1a1 1 0 01-1-1V1a1 1 0 011-1h2.858"
      />
    </g>
  </svg>
);

export default Share;
