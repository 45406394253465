import React from 'react';
import styled, { css } from 'styled-components';

export type FifthElementPosition = 'top-left' | 'bottom-right';
export type FifthElementVariant = 'large' | 'medium' | 'small';

export type FifthElementProps = {
  position?: FifthElementPosition;
  variant?: FifthElementVariant;
  skew?: boolean;
};

/**
 * This components renders the Fifth Element (the slanted, grey lines in the corners).
 * It can positioned either 'top-left' or 'bottom-right', and it comes in three variants: large, medium and small.
 * PLEASE NOTE: It is recommended to wrap your entire page layout in Div element with "overflow: hidden".
 * This will make sure the FifthElement does not enforce longer pages than needed.
 * It is recommended to use the Wrapper.Page component.
 */
export const FifthElement: React.FC<FifthElementProps> = ({
  position = 'top-left',
  variant = 'large',
  skew = true,
}) => {
  const LinesContainer = getLinesContainer(variant);

  return (
    <Container>
      <LinesContainer position={position} skew={skew}>
        <div className="line1" />
        <div className="line2" />
        <div className="line3" />
        <div className="line4" />
        <div className="line5" />
        <div className="line6" />
        <div className="line7" />
        <div className="line8" />
      </LinesContainer>
    </Container>
  );
};

const getLinesContainer = (variant: FifthElementVariant) => {
  switch (variant) {
    default:
    case 'large':
      return Large;
    case 'medium':
      return Medium;
    case 'small':
      return Small;
  }
};

const Container = styled.div`
  height: 0;
  position: relative;
  z-index: 0;
`;

const Lines = styled.div<FifthElementProps>`
  position: absolute;
  height: 2000px;
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.skew &&
    css`
      transform: skew(-12deg);
    `}

  ${(props) =>
    props.position === 'bottom-right'
      ? css`
          bottom: 0;
          right: 0;
          transform-origin: bottom right;
        `
      : css`
          top: 0;
          left: 0;
          flex-direction: row-reverse;
          transform-origin: top left;
        `}

  div {
    opacity: 0;
    flex: 1;
    background: rgb(222, 222, 222);
    background: linear-gradient(
      270deg,
      rgba(222, 222, 222, 1) 0%,
      rgba(246, 246, 246, 1) 100%
    );

    ${(props) =>
      props.position === 'bottom-right' &&
      css`
        background: linear-gradient(
          90deg,
          rgba(222, 222, 222, 1) 0%,
          rgba(246, 246, 246, 1) 100%
        );
      `}
  }
`;

const Large = styled(Lines)`
  width: 20%;

  div {
    &.line1 {
      opacity: 0.125;
    }
    &.line2 {
      opacity: 0.25;
    }
    &.line3 {
      opacity: 0.375;
    }
    &.line4 {
      opacity: 0.5;
    }
    &.line5 {
      opacity: 0.625;
    }
    &.line6 {
      opacity: 0.75;
    }
    &.line7 {
      opacity: 0.875;
    }
    &.line8 {
      opacity: 1;
    }
  }
`;

const Medium = styled(Lines)`
  width: 10%;

  div {
    &.line1 {
      opacity: 0.2;
    }
    &.line2 {
      opacity: 0.4;
    }
    &.line3 {
      opacity: 0.6;
    }
    &.line4 {
      opacity: 0.8;
    }
    &.line5,
    &.line6,
    &.line7,
    &.line8 {
      display: none;
    }
  }
`;

const Small = styled(Lines)`
  width: 5%;

  div {
    &.line1 {
      opacity: 0.25;
    }
    &.line2 {
      opacity: 0.5;
    }
    &.line3 {
      opacity: 0.75;
    }
    &.line4,
    &.line5,
    &.line6,
    &.line7,
    &.line8 {
      display: none;
    }
  }
`;
