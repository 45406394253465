import styled, { css } from 'styled-components';
import { Color } from '../../Foundation';

export type DotProps = {
  active?: boolean;
};

export const Dot = styled.button<DotProps>`
  overflow: hidden;
  padding: 0;
  margin: 0 4px;
  text-indent: -999px;
  border: 4px solid #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ddd;
  outline: none;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${Color.rdRed};
  }

  ${(props) => props.active && dotActiveStyle}
`;

export const dotActiveStyle = css`
  cursor: default;
  background: ${Color.rdRed};
  box-shadow: 0 0 0 2px ${Color.rdRed};
`;
