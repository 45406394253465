import React from 'react';

const KebabMenu = () => (
  <svg viewBox="0 0 32 36" fill="none" width="1em" height="1em">
    <path
      d="M15.586 8.396a1.914 1.914 0 103.828 0 1.914 1.914 0 00-3.828 0zm0 9.57a1.914 1.914 0 103.828 0 1.914 1.914 0 00-3.828 0zm0 9.57a1.914 1.914 0 103.828 0 1.914 1.914 0 00-3.828 0z"
      fill="#000"
    />
  </svg>
);

export default KebabMenu;
