import React from 'react';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import { breakpointsBootstrap } from '../../Layout';
import { CarouselButton } from './CarouselButton';
import { CarouselDot, CarouselDots } from './CarouselDots';

/**
 * CarouselProps inherits from react-slick Settings, which allows you to use all settings from the documentation:
 * Link: https://react-slick.neostack.com/docs/api
 */
export interface CarouselProps extends Settings {
  children?: any;
  dotArrows?: boolean;
  setSliderRef?: (ref: any) => void;
}

/**
 * Component for rendering a carousel with previous/next buttons and navigation dots.
 *
 * It uses [react-slick](https://github.com/akiran/react-slick) underneath, which is a React port of [Slick carousel](http://kenwheeler.github.io/slick/).
 *
 * It comes out-of-the-box with some "sane" default settings (basically just based on the RD website design).
 *
 * However all settings can be overriden by applying props directly to the Carousel component.
 *
 * Please see the API documentation on the website: https://react-slick.neostack.com/docs/api
 */
export const Carousel: React.FC<CarouselProps> = ({
  children,
  dotArrows,
  setSliderRef,
  ...settings
}) => {
  const [internalRef, setInternalRef] = React.useState<any>();
  return (
    <Container>
      <Slider
        ref={(ref) => {
          setInternalRef(ref);
          setSliderRef && setSliderRef(ref);
        }}
        speed={500}
        dots={false}
        arrows={false}
        infinite={false}
        centerMode={false}
        appendDots={(dots) => (
          <CarouselDots dotArrows={dotArrows} sliderRef={internalRef}>
            {dots}
          </CarouselDots>
        )}
        customPaging={(i) => <CarouselDot i={i} />}
        prevArrow={<CarouselButton position="left" />}
        nextArrow={<CarouselButton position="right" />}
        responsive={[
          {
            breakpoint: 9999, // arbitrary high number for default behaviour (wide screens)
            settings: {
              arrows: true,
              centerPadding: '0px',
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: breakpointsBootstrap.xl - 1,
            settings: {
              arrows: true,
              centerPadding: '0px',
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: breakpointsBootstrap.md - 1,
            settings: {
              dots: true,
              arrows: false,
              infinite: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
        {...settings}
      >
        {children}
      </Slider>
    </Container>
  );
};

/**
 * CSS code is a copy of: https://github.com/kenwheeler/slick/blob/master/slick/slick.css
 */
const Container = styled.div`
  /* Slider */
  div[tabindex='-1']:focus {
    outline: none;
  }
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;
