import React from 'react';

const Trash = () => (
  <svg viewBox="0 0 15 16" fill="none" width="1em" height="1em">
    <path
      d="M13.656 2.375h-2.969v-.594A1.781 1.781 0 008.908 0H5.343a1.781 1.781 0 00-1.782 1.781v.594H.595a.594.594 0 000 1.188h.594V14.25a1.188 1.188 0 001.187 1.188h9.5a1.188 1.188 0 001.188-1.188V3.562h.593a.594.594 0 000-1.187zM4.75 1.781a.594.594 0 01.594-.593h3.562a.594.594 0 01.594.593v.594H4.75v-.594zm7.125 12.469h-9.5V3.562h9.5V14.25zM5.937 6.531v4.75a.594.594 0 01-1.187 0v-4.75a.594.594 0 011.188 0zm3.563 0v4.75a.594.594 0 01-1.188 0v-4.75a.594.594 0 011.188 0z"
      fill="#000"
    />
  </svg>
);

export default Trash;
