import MaterialTabs from '@material-ui/core/Tabs';
import styled from 'styled-components';
import { mediaQuery } from '../../Layout';

export const Tabs = styled(MaterialTabs)`
  border-bottom: 3px solid #f4f0f0;
  & .MuiTabs-indicator {
    display: none;
  }
  ${mediaQuery.md} {
    & .MuiSvgIcon-root {
      width: 1.5em;
      height: 1.5em;
    }
  }
`;
