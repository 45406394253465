import React from 'react';

/** @deprecated Use Icons.Action.Information instead.*/
const InfoRed = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <defs>
      <path
        d="M10 0c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0zm0 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm.7 6l-1 6.5c-.2 1.1-.1 1.4.4 1.4s1-.5 1.5-1.2l.5.4c-.8 1.3-1.4 2-2.6 2-1 0-1.5-.4-1.3-1.8l1-6.2H8l.1-.7 2.6-.4zm-.2-4c.6 0 1 .5 1 1s-.5 1-1 1c-.6 0-1-.5-1-1s.4-1 1-1z"
        id="svg-infored-a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="svg-infored-b" fill="#fff">
        <use xlinkHref="#svg-infored-a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#svg-infored-a" />
      <g mask="url(#svg-infored-b)">
        <path fill="#B10B1F" d="M-1-1v22h22V-1z" />
      </g>
    </g>
  </svg>
);

export default InfoRed;
