import React, { useState, createRef } from 'react';
import NumberFormat from 'react-number-format';
import type { NumberFormatProps } from 'react-number-format';
import styled from 'styled-components';
import { Color } from '../../Foundation';
import { Icons } from '../../Icons';
import { Flex } from '../../Layout';
import { Input } from './components';

export interface TableInputProps extends NumberFormatProps {
  /** String of RGB hex value only. Color enum will break drop-shadow on Edit icon. */
  backgroundColor?: string;
  hasEditIcon?: boolean;
}

export function TableInput({
  backgroundColor = '#ffffff',
  hasEditIcon = true,
  defaultValue,
  onBlur,
  onChange,
  onFocus,
  type,
  value,
  ...rest
}: TableInputProps): JSX.Element {
  const inputRef = createRef<HTMLDivElement>();
  const [isFocused, setIsFocused] = useState(false);

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleEditIconClick = () => {
    inputRef.current?.focus();
  };

  return (
    <TableInputWrapper backgroundColor={backgroundColor} isFocused={isFocused}>
      <Flex flex={1} width="100%">
        <NumberFormat
          getInputRef={inputRef}
          aria-label="Number input"
          customInput={Input}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          type="text"
          value={value as string | number | undefined}
          defaultValue={defaultValue as string | number | undefined}
          {...rest}
        />
      </Flex>

      <Flex alignItems="center">
        <EditIconWrapper
          backgroundColor={backgroundColor}
          hasEditIcon={hasEditIcon}
          onClick={handleEditIconClick}
        >
          <Icons.Edit />
        </EditIconWrapper>
      </Flex>
    </TableInputWrapper>
  );
}

const TableInputWrapper = styled.div<{
  isFocused?: boolean;
  backgroundColor: string;
}>`
  display: flex;
  position: relative;
  width: 100%;
  transition: border-color 0.5s ease;

  border-bottom: 1px dashed
    ${({ backgroundColor, isFocused }) =>
      isFocused ? Color.grey100 : backgroundColor};
  font-weight: ${({ isFocused }) => (isFocused ? 600 : 'regular')};

  &:hover {
    border-bottom: 1px dashed
      ${({ isFocused }) => (isFocused ? Color.grey100 : Color.grey50)};
  }
`;

const EditIconWrapper = styled.span<{
  backgroundColor: string;
  hasEditIcon: boolean;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ backgroundColor }) => `0 1px 2px 8px ${backgroundColor}f2`};
  clip-path: inset(0px 0px 0px -10px);
  visibility: ${({ hasEditIcon }) => (hasEditIcon ? 'visible' : 'hidden')};
  g {
    stroke: ${Color.grey100};
  }
`;
