import React from 'react';

/** @deprecated Use Icons.Action.Checkmark instead.*/
const CheckmarkRed = () => (
  <svg viewBox="0 0 22 22" width="1em" height="1em">
    <g fill="none" fillRule="evenodd">
      <circle cx={11} cy={11} r={11} fill="#E2001A" />
      <path
        d="M7 10.455l3.818 3.248L15.366 8"
        stroke="#FBD9DD"
        strokeLinejoin="round"
        strokeWidth={1.75}
      />
    </g>
  </svg>
);

export default CheckmarkRed;
