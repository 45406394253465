import React from 'react';

const Grid = () => (
  <svg viewBox="0 0 25 24" fill="none" width="1em" height="1em">
    <path
      stroke="#424242"
      d="M1.167 13.5h10v10h-10zM14.167 13.5h10v10h-10zM1.167.5h10v10h-10zM14.167.5h10v10h-10z"
    />
  </svg>
);

export default Grid;
