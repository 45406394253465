import { ResponsiveProps } from '../../Components/Space';

export type TextType =
  | 'display'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'h8'
  | 'manchetSmall'
  | 'manchetBig'
  | 'paragraph'
  | 'paragraphSmall'
  | 'list'
  | 'image'
  | 'label'
  | 'labelSmall'
  | 'none';

export enum FontWeight {
  regular = 400,
  medium = 500,
  semiBold = 600,
}

export enum FontStyle {
  Normal = 'normal',
  Italic = 'italic',
}

export type TypographyStylesMap = {
  [type in TextType]: TypographyStyleDefinition;
};

export type TypographyStyleDefinition = {
  renderAs: string;
  fontFamily: string;
  fontWeight: FontWeight;
  fontStyle?: FontStyle;
  color: string;
  textTransform?: string;
  /**
   * Sizes defined per breakpoint. Mobile-first.
   * Array should be: [font-size, line-height, letter-spacing]
   */
  sizes: Partial<ResponsiveProps<Array<string>>>;
};
