import React from 'react';

const DividerHorizontal = () => (
  <svg viewBox="0 0 383 3" preserveAspectRatio="none" width="1em" height="1em">
    <g fill="none" fillRule="evenodd">
      <path fill="#F1E9E9" d="M0 0h383v1H0z" />
      <path fill="#F4F0F0" d="M0 1h383v2H0z" />
    </g>
  </svg>
);

export default DividerHorizontal;
