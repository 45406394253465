import React, { RefObject } from 'react';

export function useStickyHeader(
  isSticky: boolean,
  containerRef: RefObject<HTMLDivElement>,
  wrapperRef: RefObject<HTMLDivElement>,
  originalHeadRowRef: RefObject<HTMLTableRowElement>,
  isFromDialog: boolean
) {
  const [top, setScrollTop] = React.useState(0);
  const [left, setScrollLeft] = React.useState(0);

  const [table, setTable] = React.useState<{ start: number; end: number }>({
    start: 0,
    end: 0,
  });
  const [widths, setWidths] = React.useState<number[]>([]);
  const [containerWidth, setContainerWidth] = React.useState<number>(0);

  const containerReference = containerRef && containerRef.current;
  const wrapperReference = wrapperRef && wrapperRef.current;
  const originalHeadRowReference =
    originalHeadRowRef && originalHeadRowRef.current;

  // Setup scroll position
  React.useEffect(() => {
    if (isSticky && typeof window !== undefined) {
      const dialogElement = document.getElementsByTagName('dialog')[0];
      const intialScrollPosition = isFromDialog
        ? dialogElement && dialogElement.scrollTop
        : window.scrollY;
      const onScroll = () => {
        const scrollingPosition = isFromDialog
          ? dialogElement && dialogElement.scrollTop
          : window.scrollY;
        setScrollTop(scrollingPosition);
      };
      setScrollTop(intialScrollPosition);

      /* 
        This condition is added to handle the table stickyheader inside a modal
      */
      if (isFromDialog) {
        dialogElement.addEventListener('scroll', onScroll);
        return () => dialogElement.removeEventListener('scroll', onScroll);
      } else {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
      }
    }
    return () => null;
  }, [isSticky]);

  // Left scroll effect
  React.useEffect(() => {
    if (isSticky && wrapperReference && containerReference) {
      const onScroll = () => {
        setScrollLeft(-wrapperReference.scrollLeft);
      };
      wrapperReference.addEventListener('scroll', onScroll);
      return () => wrapperReference.removeEventListener('scroll', onScroll);
    }
    return () => null;
  }, [isSticky, containerReference !== null]);

  // Get container size
  React.useEffect(() => {
    if (containerReference && isSticky) {
      const rect = containerReference.getBoundingClientRect();
      const dialogElement = document.getElementsByTagName('dialog')[0];
      const scrollingPosition = isFromDialog
        ? dialogElement && dialogElement.scrollTop
        : window.scrollY;
      const start = scrollingPosition + rect.y;
      const end = rect.height + start;
      setTable({ start, end });
    }
  }, [
    isSticky,
    containerReference !== null,
    containerReference && containerReference.offsetHeight,
  ]);

  // Set top width based on rezize
  React.useEffect(() => {
    if (isSticky && containerReference) {
      const children = Array.from(
        (originalHeadRowReference?.children || []) as HTMLTableElement[]
      );
      const onResize = () => {
        const children = Array.from(
          (originalHeadRowReference?.children || []) as HTMLTableElement[]
        );
        setWidths(children.map((child) => child?.offsetWidth));
        setContainerWidth(containerReference.offsetWidth);
      };
      setWidths(children.map((child) => child?.offsetWidth));
      setContainerWidth(containerReference.offsetWidth);
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
    return () => null;
  }, [
    isSticky,
    containerReference !== null,
    originalHeadRowReference! == null,
  ]);

  return {
    showFixed: isSticky ? table?.start < top && table?.end > top : false,
    left,
    widths,
    containerWidth,
  };
}
