import React from 'react';

/** @deprecated Use Icons.Illustration.Checklist instead.*/
const Checklist = () => (
  <svg viewBox="0 0 48 56" width="1em" height="1em">
    <path
      d="M22.929 22.595h10.048c.318 0 .576-.26.576-.58a.579.579 0 00-.576-.581H22.929a.578.578 0 00-.576.58c0 .321.258.581.576.581m10.048 7.308H22.929a.579.579 0 00-.577.58c0 .321.258.581.577.581h10.048c.318 0 .576-.26.576-.58a.579.579 0 00-.576-.581m0 8.298H22.929a.579.579 0 00-.577.58c0 .321.258.581.577.581h10.048c.318 0 .576-.26.576-.58a.579.579 0 00-.576-.581m-15.73-13.862h-.007a.573.573 0 01-.41-.18l-1.647-1.739a.583.583 0 01.019-.82.57.57 0 01.814.018l1.24 1.31 3.284-3.311v-.091a.579.579 0 00-.576-.581h-4.941a.578.578 0 00-.576.58v4.98c0 .321.258.581.576.581h4.94c.32 0 .577-.26.577-.58V21.26l-2.885 2.908a.573.573 0 01-.408.171m0 8.465h-.007a.574.574 0 01-.41-.18l-1.648-1.745a.583.583 0 01.021-.821.575.575 0 01.815.02l1.24 1.312 3.282-3.309v-.09a.579.579 0 00-.576-.582h-4.941a.579.579 0 00-.576.581v4.98a.582.582 0 00.576.58h4.94c.32 0 .577-.26.577-.581v-3.245l-2.885 2.909a.572.572 0 01-.408.17M15.6 36.87v3.817l3.788-.003V36.87H15.6zm-.576 4.98a.574.574 0 01-.408-.17.586.586 0 01-.169-.411v-4.98c0-.32.258-.58.577-.58h4.94c.319 0 .577.26.577.58v4.976a.579.579 0 01-.577.581l-4.94.004zm21.74 4.56c0 .32-.257.58-.576.58H11.812a.579.579 0 01-.577-.58V11.725c0-.321.258-.581.577-.581H17v2.739c0 .32.258.58.576.58h12.848c.318 0 .576-.26.576-.58v-2.74h5.188c.319 0 .577.26.577.582v34.684zm-18.61-35.266V9.817h11.694v3.485H18.153v-2.158zM24 7.162c.991 0 1.797.624 2.05 1.492h-4.1c.255-.868 1.059-1.492 2.049-1.492zM37.424 9.65H31v-.416c0-.32-.258-.58-.576-.58h-3.188C26.966 7.128 25.644 6 24 6c-1.644 0-2.964 1.129-3.236 2.654h-3.187a.58.58 0 00-.576.581v.416h-6.424a.578.578 0 00-.576.58v37.671c0 .32.258.58.576.58h26.848c.318 0 .576-.26.576-.58v-37.67a.579.579 0 00-.576-.581z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Checklist;
