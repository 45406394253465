import React from 'react';

/** @deprecated Use Icons.Illustration.SetExpectations instead.*/
const Compass = () => (
  <svg viewBox="0 0 48 48" width="1em" height="1em">
    <path
      d="M26.468 26.631l-4.341-4.295 8.962-4.58-4.621 8.875zm6.393-10.63a.587.587 0 00-.681-.106l-10.756 5.497c-.019.01-.032.027-.048.039a.527.527 0 00-.165.163c-.012.018-.028.03-.039.048l-5.563 10.653a.576.576 0 00.106.675.592.592 0 00.682.105l10.773-5.499a.572.572 0 00.242-.255l.01.005 5.545-10.651a.574.574 0 00-.106-.675zm-8.565-3.88l1.174 2.829-2.313.012 1.138-2.841zm-2 4.001h.003l4.045-.02a.581.581 0 00.484-.257.571.571 0 00.054-.54l-2.055-4.95a.584.584 0 00-.54-.358h-.004a.58.58 0 00-.54.365l-1.99 4.968a.571.571 0 00.06.54c.109.158.29.252.483.252zm2.046 20.71l-1.175-2.83 2.312-.01-1.137 2.84zm1.999-4.002h-.003l-4.045.02a.583.583 0 00-.483.258.574.574 0 00-.055.54l2.055 4.95a.585.585 0 00.541.358h.004a.584.584 0 00.54-.365l1.99-4.969a.578.578 0 00-.544-.792zM12.07 24.471l2.858-1.163.012 2.289-2.87-1.126zm4.043 1.976l-.02-4.003a.577.577 0 00-.26-.479.592.592 0 00-.546-.053l-5.001 2.033a.577.577 0 00.007 1.073l5.02 1.97a.595.595 0 00.545-.06.575.575 0 00.255-.48zm17.475-.858l-.012-2.288 2.87 1.125-2.858 1.163zm4.635-1.71l-5.02-1.97a.582.582 0 00-.8.54l.02 4.004a.574.574 0 00.26.478.584.584 0 00.546.055l5-2.034a.578.578 0 00.363-.54.574.574 0 00-.369-.533zM24.32 40.843c-9.105 0-16.512-7.332-16.512-16.342 0-9.012 7.407-16.344 16.512-16.344s16.51 7.333 16.51 16.344c0 9.01-7.407 16.342-16.512 16.342M7.169 10.575c0-1.332 1.095-2.417 2.441-2.417 1.347 0 2.442 1.085 2.442 2.417 0 1.333-1.095 2.416-2.442 2.416-1.346 0-2.44-1.083-2.44-2.416M24.318 7c-4.217 0-8.09 1.473-11.133 3.922.012-.117.036-.229.036-.347.001-1.97-1.621-3.574-3.611-3.574C7.62 7.001 6 8.605 6 10.575c0 1.97 1.62 3.575 3.61 3.575.18 0 .351-.027.525-.053-2.187 2.913-3.497 6.504-3.497 10.404C6.638 34.15 14.568 42 24.318 42S42 34.15 42 24.5 34.069 7 24.319 7"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Compass;
