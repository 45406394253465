import React from 'react';

/** @deprecated Use Icons.Action.Calendar instead.*/
const Calendar = () => (
  <svg viewBox="0 0 48 48" width="1em" height="1em">
    <path
      d="M41.027 36.472c0 .86-.728 1.56-1.621 1.56H8.596c-.895 0-1.623-.7-1.623-1.56V18.677h34.054v17.795zM6 36.315C6 37.795 7.226 39 8.733 39h30.534C40.774 39 42 37.796 42 36.315V13.027a.56.56 0 00-.562-.552h-9.242v-2.92a.558.558 0 00-.562-.553.558.558 0 00-.563.553v2.92H16.93V9.554A.558.558 0 0016.367 9a.558.558 0 00-.562.554v2.92h-9.24a.558.558 0 00-.563.553L6 36.315zm18.486-5.057h7.784v-5.806h-7.784v5.806zm-8.756 0v-5.806h7.784v5.806H15.73zm21.822-.348h-4.439v-5.503h4.44a.552.552 0 00.555-.55.552.552 0 00-.555-.55h-4.44v-3.063a.552.552 0 00-.555-.55.552.552 0 00-.554.55v3.063h-7.451v-3.144a.553.553 0 00-.555-.55.554.554 0 00-.556.55v3.144h-7.526v-3.144a.553.553 0 00-.555-.55.553.553 0 00-.555.55v3.144h-4.36a.552.552 0 00-.554.55c0 .304.248.55.555.55h4.358v5.503h-4.358a.552.552 0 00-.555.55c0 .304.248.55.555.55h4.357l-.001 3.537c0 .304.25.55.555.55a.554.554 0 00.556-.55V32.01h7.529v3.537c0 .304.25.55.556.55a.553.553 0 00.555-.55V32.01h7.45v3.537c0 .304.248.55.554.55a.552.552 0 00.555-.55V32.01h4.44a.552.552 0 00.554-.55.552.552 0 00-.555-.55z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Calendar;
