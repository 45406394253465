import React from 'react';

/** @deprecated Use Icons.Action.Home instead.*/
const HouseSimple = () => (
  <svg viewBox="0 0 38 32" width="1em" height="1em">
    <g stroke="#262626" strokeWidth={2.5} fill="none" fillRule="evenodd">
      <path d="M29.873 10.75V30H7.922V10.75" />
      <path d="M36.795 16L18.547 2 1 16m14.824 3.5h6.146V30h-6.146z" />
    </g>
  </svg>
);

export default HouseSimple;
