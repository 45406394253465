import React from 'react';

/** @deprecated Use Icons.Illustration.HousePrivate instead.*/
const House = () => (
  <svg viewBox="0 0 56 48" width="1em" height="1em">
    <path
      d="M21.775 24.829h-1.693c-.243 0-.44.2-.44.448v1.731c0 .248.197.45.44.45h1.693c.243 0 .44-.202.44-.45v-1.73a.445.445 0 00-.44-.45m0 3.943h-1.693a.445.445 0 00-.44.45v1.73c0 .248.197.449.44.449h1.693c.243 0 .44-.2.44-.449v-1.73a.445.445 0 00-.44-.45m3.858-3.942h-1.694c-.243 0-.439.2-.439.448v1.731c0 .248.196.45.439.45h1.694a.444.444 0 00.438-.45v-1.73a.444.444 0 00-.438-.45m0 3.943h-1.694a.444.444 0 00-.439.45v1.73c0 .248.196.449.439.449h1.694c.242 0 .438-.2.438-.449v-1.73a.444.444 0 00-.438-.45m9.984 6.572v-9.66a.427.427 0 00-.42-.434H29.68a.427.427 0 00-.42.433v9.66H16.429V23.515H39.57v11.829h-3.954zm-5.688 0h4.5v-9.2h-4.5v9.2zM15.143 22.2l4.656-6.571h16.403l4.655 6.571H15.143zM45.58 36c.232 0 .42-.198.42-.443s-.188-.444-.42-.444h-5.16V23.01h1.26c.16 0 .305-.094.376-.245a.467.467 0 00-.04-.465l-5.28-7.413a.416.416 0 00-.336-.177h-3.96v-1.267a.432.432 0 00-.42-.443h-2.34a.432.432 0 00-.42.443v1.267H19.6a.412.412 0 00-.335.177l-5.28 7.413a.464.464 0 00-.04.465c.07.15.216.245.376.245h1.26v12.103H10.42c-.232 0-.42.199-.42.444s.188.443.42.443h35.16zm-11.67-5.586c0 .262-.2.474-.446.474-.245 0-.445-.212-.445-.474 0-.261.2-.473.445-.473.246 0 .445.212.445.473"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default House;
