import React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../Foundation/Color/Color';
import { Col, Container, Hidden, Row } from '../../Layout/Grid';
import { constants } from './constants';
import { LinkItem, OnClearDropdown } from './types';
import { useMenuNavigation } from './useMenuNavigation';

type DesktopNavigationDropdownProps = {
  index: number;
  links: LinkItem[];
  show: boolean;
  animate: boolean;
  onClearNavigationDropdown: OnClearDropdown;
};

export const DesktopNavigationDropdown: React.FC<DesktopNavigationDropdownProps> = ({
  index,
  links,
  show,
  animate,
  onClearNavigationDropdown,
}) => {
  const { handleDropdownKeyDown } = useMenuNavigation(
    onClearNavigationDropdown
  );

  return (
    <Hidden xs sm md>
      <Wrapper
        active={show}
        animate={animate}
        id={`navdropdown${index}`}
        role="menu"
        aria-hidden={!show}
      >
        <Container>
          <Row>
            <Col lg={2.5}></Col>
            {links.map((link, i) => {
              const children = link.children || [];

              return (
                <Col lg={3} xl={2} key={`column${i}`}>
                  <ColumnTitle>{link.name}</ColumnTitle>

                  {children.map((subItem, subItemIndex) => (
                    <div key={`navlink${subItemIndex}`}>
                      <Link
                        href={subItem.url}
                        role="menuitem"
                        tabIndex={show ? 0 : -1}
                        data-tracking-id={subItem.trackingcode}
                        onKeyDown={(event) => {
                          handleDropdownKeyDown(event, index);
                        }}
                      >
                        {subItem.name}
                        {subItem.label && <Label>{subItem.label}</Label>}
                      </Link>
                    </div>
                  ))}
                </Col>
              );
            })}
          </Row>
        </Container>
      </Wrapper>
    </Hidden>
  );
};

const Wrapper = styled.div<{ active?: boolean; animate?: boolean }>`
  position: absolute;
  z-index: 2000;
  top: 110px;
  left: 0;
  right: 0;
  background: #fff;
  padding: 30px 0 35px;
  box-shadow: ${(props) =>
    props.active ? '0 2px 24px 0 rgba(0, 0, 0, 0.11)' : 'none'};
  transform: translateY(${(props) => (props.active ? '0%' : '-100%')});

  ${(props) =>
    props.animate &&
    css`
      transition: all ${constants.NAV_ANIMATION_TIME}ms;
    `}
`;

const ColumnTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 3px 0;
  color: #212121;
  font-size: 16px;
  text-decoration: none;
  transition: color ${constants.LINK_COLOR_ANIMATION_TIME}ms;

  :hover {
    color: #000;
  }
`;

const Label = styled.span`
  display: inline-block;
  margin-left: 6px;
  background: ${Color.rdRed};
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
`;
