import React from 'react';

/** @deprecated Use Icons.Action.Spinner instead.*/
const Spinner = () => (
  <svg
    style={{ margin: 'auto', background: '0 0' }}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    display="block"
    width="1em"
    height="1em"
  >
    <circle
      cx={50}
      cy={50}
      r={32}
      strokeWidth={5}
      stroke="#e2001a"
      strokeDasharray="50.26548245743669 50.26548245743669"
      fill="none"
      strokeLinecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="0.8620689655172413s"
        keyTimes="0;1"
        values="0 50 50;360 50 50"
      />
    </circle>
  </svg>
);

export default Spinner;
