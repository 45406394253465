import React from 'react';

const HomeValue = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <mask
      id="svg-homevalue-a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={2}
      width={19}
      height={20}
    >
      <path
        d="M12.775 2.079l2.838 2.319v-.843c0-.317.21-.527.526-.527h3.153c.315 0 .526.21.526.527v4.321l1.892 1.581c.105.106.21.211.21.422v11.594c0 .316-.21.527-.526.527H3.526C3.21 22 3 21.79 3 21.473V9.879c0-.106.105-.316.21-.422l8.935-7.378a.668.668 0 01.63 0zm-.315 1.054L4.05 10.09v10.856H20.87V10.09l-1.892-1.476c-.105-.105-.21-.21-.21-.422v-4.11h-2.103v1.475c0 .211-.105.317-.315.422-.105 0-.42 0-.526-.105l-3.363-2.74zM15.029 9.5h2.447c.542 0 .981.44.981.98v2.49a.49.49 0 01-.98 0l-.002-1.728-3.443 3.97-3.691.073-2.968 4.017a.489.489 0 01-.685.1.497.497 0 01-.101-.692l3.254-4.406 3.738-.074 3.206-3.694a.496.496 0 01.046-.047l-1.802.001a.495.495 0 010-.99z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#svg-homevalue-a)">
      <path
        d="M12.775 2.079l2.838 2.319v-.843c0-.317.21-.527.526-.527h3.153c.315 0 .526.21.526.527v4.321l1.892 1.581c.105.106.21.211.21.422v11.594c0 .316-.21.527-.526.527H3.526C3.21 22 3 21.79 3 21.473V9.879c0-.106.105-.316.21-.422l8.935-7.378a.668.668 0 01.63 0zm-.315 1.054L4.05 10.09v10.856H20.87V10.09l-1.892-1.476c-.105-.105-.21-.21-.21-.422v-4.11h-2.103v1.475c0 .211-.105.317-.315.422-.105 0-.42 0-.526-.105l-3.363-2.74zM15.029 9.5h2.447c.542 0 .981.44.981.98v2.49a.49.49 0 01-.98 0l-.002-1.728-3.443 3.97-3.691.073-2.968 4.017a.489.489 0 01-.685.1.497.497 0 01-.101-.692l3.254-4.406 3.738-.074 3.206-3.694a.496.496 0 01.046-.047l-1.802.001a.495.495 0 010-.99z"
        fill="#616161"
      />
    </g>
  </svg>
);

export default HomeValue;
