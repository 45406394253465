export type EventHandlerState = {
  mouseDown: boolean;
  mouseDownTimestamp: number;
  mouseUpListenerSet: boolean;
  prevLeft: number;
  mouseDownStartX: number;
};

export enum Constants {
  ellipsisScrollOffset = 50,
}

export type IndexerPosition = {
  width: number;
  left: number;
};

export type TableSelectorProps = {
  isDarkTheme?: boolean;
  darkThemeColor?: string;
  size?: Size;
  children: any;
  selectedIndex?: number;
  scrollable?: boolean;
};

export enum ClassNames {
  tab = 'table-selector__tab',
  selectedTab = 'table-selector__tab--selected',
  ellipsisLeft = 'ellipsis__left',
  ellipsisRight = 'ellipsis__right',
  ellipsisVisible = 'ellipsis--visible',
}

export type Size = 'small' | 'normal';
