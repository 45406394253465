import React from 'react';

const Broke = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M512 972.44q-31.176 0-60.553-4.196-29.377-4.197-56.955-11.392l304.562-400.487-308.159-157.077L669.077 80.337q67.148 23.982 122.305 65.949 55.157 43.166 95.325 99.522 40.169 56.356 62.951 123.503Q972.44 437.658 972.44 512q0 94.726-35.972 178.66-35.972 83.935-98.922 146.886-62.951 62.95-146.886 98.922Q606.726 972.44 512 972.44zM51.56 512q0-94.726 35.972-178.66 35.972-83.935 98.922-146.886 62.951-62.95 146.886-98.922Q417.274 51.56 512 51.56q26.379 0 52.759 2.997 26.379 2.998 50.36 8.993L309.358 414.876l311.757 159.475-278.183 365.715q-64.749-25.181-117.508-68.347-53.958-43.166-92.328-98.923-38.37-55.756-59.953-121.705Q51.56 585.143 51.56 512zM0 512q0 82.735 25.18 157.077 23.982 74.342 68.347 136.693 44.365 62.352 105.517 110.314 61.153 46.764 133.097 74.342 4.796 4.796 8.992 5.996 4.197 1.199 8.993 1.199 38.37 11.99 79.139 19.185Q470.033 1024 512 1024q105.518 0 199.044-40.768 93.527-39.569 163.073-109.115 69.546-69.546 109.115-163.073Q1024 617.518 1024 512q0-105.518-40.768-199.044-39.569-93.527-109.115-163.073-69.546-69.546-163.073-109.115Q617.518 0 512 0 406.482 0 312.956 40.768q-93.527 39.569-163.073 109.115-69.546 69.546-109.115 163.073Q0 406.482 0 512z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Broke;
