import React from 'react';

/** @deprecated Use Icons.Action.SortDown instead.*/
const SortDown = () => (
  <svg viewBox="0 0 10 12" width="1em" height="1em">
    <g fill="none" fillRule="evenodd">
      <path fill="#D50018" d="M.757 7h8.486L5 11.243z" />
      <path fill="#FFF" d="M.757 5h8.486L5 .757z" />
    </g>
  </svg>
);

export default SortDown;
