import styled from 'styled-components';
import { Color } from '../../../Foundation/Color';
import { RddkTheme } from '../../../Foundation/Theme/RddkTheme';

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-family: ${RddkTheme.fonts.sans};
  color: ${Color.grey90};
  border: none;
  outline: none;
  font-size: 15px;
  line-height: 19px;
  font-weight: inherit;
  letter-spacing: -0.21px;
  background-color: transparent;

  position: absolute;
  top: 0;
  right: 0;

  ::-ms-clear {
    display: none;
  }

  &:disabled {
    color: ${Color.grey70};
    cursor: not-allowed;
  }

  ::placeholder {
    font-family: ${RddkTheme.fonts.sans};
    font-size: 15px;
    line-height: 1.4;
    color: ${Color.grey70};
    font-weight: 500;
  }
`;
