import React from 'react';

const Edit = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M767.1 971.179H52.821V258.101h621.843L513.801 418.964q-2.401 2.401-3.602 4.201-1.2 1.801-1.2 4.202l-37.215 98.438q-2.401 6.003-1.2 12.005 1.2 6.002 6.002 10.804 2.401 3.602 6.603 4.802 4.201 1.201 7.803 1.201h4.201q1.801 0 4.202-1.201l98.439-37.214q2.4 0 4.201-1.801 1.801-1.801 4.202-3.001L767.1 349.336v621.843zm249.697-907.554L960.375 8.403Q953.172 0 942.368 0q-10.804 0-18.007 8.403L727.484 205.28H26.41q-10.804 0-18.607 7.803Q0 220.886 0 231.691V997.59q0 12.004 7.803 19.207Q15.606 1024 26.41 1024h767.1q10.804 0 18.607-7.203 7.803-7.203 7.803-19.207V297.716l3.602-3.601 193.275-193.276Q1024 92.436 1024 82.232q0-10.204-7.203-18.607z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Edit;
