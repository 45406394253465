import styled from 'styled-components';
import { mediaQuery } from '../../Layout';

/**
 * @deprecated This component is deprecated / no longer needed.
 */
const Page = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 20px 0 #dedede;
`;

/**
 * @deprecated This component is deprecated.
 * Please use "Container" component from the grid instead.
 */
const Content = styled.div`
  max-width: 1560px;
  padding: 0 60px;
  margin: 0 auto;

  ${mediaQuery.xxlDown} {
    max-width: 1400px;
  }
  ${mediaQuery.lgDown} {
    padding: 0 40px;
  }
  ${mediaQuery.smDown} {
    padding: 0 30px;
  }
`;

/**
 * @deprecated The Wrapper components are being deprecated.
 * Please use "Container" component from the grid instead.
 */
export const Wrapper = {
  Page,
  Content,
};
