import React from 'react';

/** @deprecated Use Icons.Action.BookMeeting instead.*/
const CalendarAddSimple = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <g
      stroke="#B10B1F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <path strokeLinecap="round" d="M12 13.5v6m-3-3h6" />
      <path d="M.75 3.75h22.5v19.5H.75zm0 6h22.5" />
      <path strokeLinecap="round" d="M6.75 6V.75M17.25 6V.75" />
    </g>
  </svg>
);

export default CalendarAddSimple;
