import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { Color } from '../../../Foundation/Color';
import { RddkTheme } from '../../../Foundation/Theme/RddkTheme';

export interface InputProps extends SpaceProps {}

const Input = styled.input<InputProps>`
  ${space};
  box-sizing: border-box;
  width: 100%;
  font-family: ${RddkTheme.fonts.sans};
  color: #000000;
  border: none;
  outline: none;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: -0.21px;
  background-color: transparent;
  padding: 0;

  ::-ms-clear {
    display: none;
  }

  &:disabled {
    color: ${Color.grey70};
    cursor: not-allowed;
  }

  ::placeholder {
    font-family: ${RddkTheme.fonts.sans};
    font-size: 15px;
    line-height: 1.4;
    color: ${Color.grey70};
    font-weight: 500;
  }
`;

Input.displayName = 'Input';

export { Input };
