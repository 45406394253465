import React, { ChangeEvent, FC } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../../Foundation/Typography';
import { Color } from '../../Foundation/Color';

import {
  StyledCheckmark as StyledBaseCheckmark,
  StyledInput as StyledBaseInput,
  StyledLabel as StyledBaseLabel,
} from './RadioButtonBase';

export type RadioButtonProps = {
  label: string;
  id: string;
  name: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  isDarkTheme?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const StyledCheckmark = styled(StyledBaseCheckmark)`
  margin-left: 0px;
  margin-right: 10px;
`;

type StyledInputProps = {
  isDarkTheme: boolean;
};
const StyledInput = styled(StyledBaseInput)<StyledInputProps>`
  &:checked {
    background-color: ${(props) =>
      props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
    & ~ ${StyledCheckmark} {
      border-color: ${(props) =>
        props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
      &:before {
        background-color: ${(props) =>
          props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
      }
    }
  }
`;

type StyledLabelProps = {
  isDarkTheme: boolean;
};
const StyledLabel = styled(StyledBaseLabel)<StyledLabelProps>`
  display: inline-flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: 4px 14px;
  width: auto;
  box-shadow: none;
  ${(props) =>
    props.isDarkTheme &&
    css`
      color: ${Color.rdBrightWhite};
    `};
  &:hover {
    background-color: ${(props) =>
      props.isDarkTheme ? Color.rdLightBlack : Color.rdLightRed};
  }
`;

export const RadioButton: FC<RadioButtonProps> = ({
  label,
  id,
  name,
  value,
  checked,
  disabled,
  isDarkTheme = false,
  onChange,
  ...rest
}) => (
  <StyledLabel isDarkTheme={isDarkTheme} htmlFor={id}>
    <StyledInput
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      isDarkTheme={isDarkTheme}
      onChange={onChange}
      {...rest}
    />
    <Typography type="list" fontWeight={600}>
      {label}
    </Typography>
    <StyledCheckmark />
  </StyledLabel>
);
