import React from 'react';

const Report = () => (
  <svg viewBox="0 0 14 18" fill="none" width="1em" height="1em">
    <path
      d="M2.813 13.5a.563.563 0 01-.563-.563v-2.25a.562.562 0 01.563-.562h1.124a.563.563 0 01.563.563v2.25a.562.562 0 01-.563.562H2.813zm3.374 0a.563.563 0 01-.562-.563v-4.5a.563.563 0 01.563-.562h1.125a.563.563 0 01.562.563v4.5a.562.562 0 01-.563.562H6.188zm3.375 0A.563.563 0 019 12.937v-6.75a.563.563 0 01.563-.562h1.124a.562.562 0 01.563.563v6.75a.562.562 0 01-.563.562H9.563z"
      fill="#000"
    />
    <path
      d="M2.25 0A2.25 2.25 0 000 2.25v13.5A2.25 2.25 0 002.25 18h9a2.25 2.25 0 002.25-2.25V2.25A2.25 2.25 0 0011.25 0h-9zm0 1.125h9a1.125 1.125 0 011.125 1.125v13.5a1.125 1.125 0 01-1.125 1.125h-9a1.125 1.125 0 01-1.125-1.125V2.25A1.125 1.125 0 012.25 1.125z"
      fill="#000"
    />
  </svg>
);

export default Report;
