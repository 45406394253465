import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../Foundation/Color/Color';
import { Icons } from '../../../Icons';

const ConfirmIconWrapper = styled.span`
  width: 16px;
  height: 16px;
  path {
    fill: ${Color.greenHaze};
  }
  circle {
    stroke: ${Color.greenHaze};
  }
`;

export const ConfirmIcon: React.FC = () => (
  <ConfirmIconWrapper>
    <Icons.Confirm />
  </ConfirmIconWrapper>
);
