import React from 'react';

const Send = () => (
  <svg viewBox="0 0 16 16" fill="none" width="1em" height="1em">
    <path
      d="M15.56.145a.5.5 0 01.11.54L9.85 15.232a.75.75 0 01-1.329.124l-3.178-4.995L.348 7.183a.75.75 0 01.124-1.33L15.02.036a.5.5 0 01.54.11zM6.34 10.07l2.761 4.338 4.733-11.832-7.494 7.494zm6.787-8.2L1.296 6.6l4.34 2.76 7.492-7.493z"
      fill="#000"
    />
  </svg>
);

export default Send;
