/**
 * 0 - 374 = Mobile Small
 * 375 - 767 = Mobile Large
 * 768 - 1023 = Tablet
 * 1024 - 1439 = Desktop
 * 1440 - infinite = Desktop Large
 */

/**
 * @deprecated These breakpoints will soon be removed.
 */
export const breakpoints = {
  mobileSmall: 0,
  mobileLarge: 375,
  tablet: 768,
  desktop: 1024,
  desktopLarge: 1440,
};

/**
 * Bootstrap breakpoints
 * https://getbootstrap.com/docs/5.0/layout/breakpoints/
 */
export const breakpointsBootstrap = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
