import React from 'react';
import styled from 'styled-components';
import { RddkTheme } from '../../Foundation';
import { Icons } from '../../Icons';
import { Hidden } from '../../Layout';
import { buttonBaseStyle, buttonPrimaryStyle } from '../Button/buttonStyles';

const Background = styled.span`
  ${buttonBaseStyle}
  ${buttonPrimaryStyle}
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: skew(-12deg);

  &::before {
    transform: skew(0);
  }
`;

const Text = styled.span`
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  padding: 13px 45px 11px 24px;
  font-family: ${RddkTheme.fonts.sans};
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  color: #fafafa;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 14px;

    g {
      fill: #fff;
    }
  }
`;

const Link = styled.a`
  display: block;
  position: fixed;
  z-index: 500;
  right: -20px;
  bottom: 0;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus {
    ${Background}::before {
      width: 125%;
    }
  }
`;

export type ButtonBookMeetingProps = React.HTMLProps<HTMLAnchorElement>;

/**
 * Sticky button for "Book meeting" that shows in the bottom right of the screen.
 * Acts a s a simple link.
 * Only visible on desktop and above.
 */
export const ButtonBookMeeting: React.FC<ButtonBookMeetingProps> = ({
  children,
  ref,
  as,
  ...rest
}) => {
  return (
    <Hidden xs>
      <Link {...rest}>
        <Background />
        <Text>
          <Icons.CalendarAdd />
          {children}
        </Text>
      </Link>
    </Hidden>
  );
};
