import React from 'react';

/** @deprecated Use Icons.Action.Doc instead.*/
const File = () => (
  <svg viewBox="0 0 12 17" width="1em" height="1em">
    <path
      fillRule="evenodd"
      d="M7.078 0H1.045C.468 0 0 .487 0 1.088v14.825C0 16.513.468 17 1.045 17h9.91c.577 0 1.045-.487 1.045-1.087V5.104L7.078 0zm-.083 1H1.871C1.39 1 1 1.43 1 1.96v13.08c0 .53.39.959.87.959h8.259c.481 0 .871-.43.871-.96V5.005L6.995 1zM6 1h.995L11 5.007v.998H7c-.552 0-1-.444-1-1V1z"
    />
  </svg>
);

export default File;
