import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../Foundation/Color';
import { Typography } from '../../Foundation/Typography';
import { Icons } from '../../Icons';

import {
  StyledCheckmark as StyledBaseCheckmark,
  StyledInput as StyledBaseInput,
  StyledLabel as StyledBaseLabel,
} from './CheckBoxBase';

export type CheckBoxProps = {
  label?: string;
  id?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  isDarkTheme?: boolean;
  onChange: (checked: boolean) => void;
};

export const CheckBox: FC<CheckBoxProps> = ({
  label,
  id,
  name,
  checked,
  disabled = false,
  isDarkTheme = false,
  onChange,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <StyledLabel isDarkTheme={isDarkTheme} disabled={disabled} htmlFor={id}>
      <StyledInput
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        isDarkTheme={isDarkTheme}
        onChange={handleChange}
        {...rest}
      />
      <Typography type="label" fontWeight={600}>
        {label}
      </Typography>
      {checked ? (
        <StyledCheckmark
          checked={checked}
          hasLabel={label !== undefined}
          isDarkTheme={isDarkTheme}
        >
          <Icons.Action.Check />
        </StyledCheckmark>
      ) : (
        <StyledCheckmark
          isDarkTheme={isDarkTheme}
          hasLabel={label !== undefined}
          checked={checked}
        />
      )}
    </StyledLabel>
  );
};

type StyledCheckmarkProps = {
  checked: boolean;
  isDarkTheme: boolean;
  hasLabel: boolean;
};

const StyledCheckmark = styled(StyledBaseCheckmark)<StyledCheckmarkProps>`
  margin-left: 0px;
  margin-right: ${(props) => (props.hasLabel ? '10px' : '0px')};
  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
      color: ${Color.rdBrightWhite};
      border: 2px solid ${props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
    `}
`;

type StyledDarkThemeProp = {
  isDarkTheme: boolean;
  disabled?: boolean;
};

const StyledInput = styled(StyledBaseInput)<StyledDarkThemeProp>`
  &:checked {
    background-color: ${(props) =>
      props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
    & ~ ${StyledCheckmark} {
      border-color: ${(props) =>
        props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
      &:before {
        background-color: ${(props) =>
          props.isDarkTheme ? Color.rdDarkRed : Color.rdRed};
      }
    }
  }
`;

const StyledLabel = styled(StyledBaseLabel)<StyledDarkThemeProp>`
  display: inline-flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: 4px 14px;
  width: auto;
  box-shadow: none;
  ${(props) =>
    props.disabled &&
    css`
      [type='label'] {
        color: ${Color.grey40};
      }
    `};
  ${(props) =>
    props.isDarkTheme &&
    css`
      color: ${Color.rdBrightWhite};
    `};
  &:hover {
    background-color: ${(props) =>
      props.isDarkTheme ? Color.rdLightBlack : Color.rdLightRed};
  }
`;
