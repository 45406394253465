import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { RddkTheme } from '../../Foundation';
import { mediaQuery } from '../../Layout';

const StyledSummaryCardGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery.sm} {
    margin: ${`-${RddkTheme.space[2]}px`};
  }
  > div {
    flex: calc(100% - ${`${RddkTheme.space[4]}px`});
    margin: 0;
    ${mediaQuery.sm} {
      flex: calc(50% - ${`${RddkTheme.space[4]}px`});
      margin: ${`${RddkTheme.space[2]}px`};
    }
  }
`;

const SummaryCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${`-${RddkTheme.space[2]}px`} 0;
  > div {
    margin: ${`${RddkTheme.space[2]}px`} 0;
  }
`;

type Props = {
  children: ReactNode[];
};

export const SummaryCardGroup: FC<Props> = ({ children }) => (
  <StyledSummaryCardGroup>
    <SummaryCardColumn>
      {children.map((item, i) => !(i % 2) && item)}
    </SummaryCardColumn>
    <SummaryCardColumn>
      {children.map((item, i) => !!(i % 2) && item)}
    </SummaryCardColumn>
  </StyledSummaryCardGroup>
);
