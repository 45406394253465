import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

export interface Props extends ColorProps, SpaceProps, LayoutProps {
  size?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  fill?: string;
}

const IconWrapper = styled.span<Props>`
  ${color};
  ${space};
  ${layout};

  display: inline-flex;

  svg,
  path {
    ${(props) => {
      if (props.width) {
        return `width:${props.width};height:${props.height || 'auto'};`;
      }
      // Start by checking for standard size
      let size = props.size || '20px';
      if (!size) {
        // Else allow custom sizes
        size = `${props.size}px`;
      }
      return `width:${size};height:${size};`;
    }}

    ${(props) => {
      if (props.fill) {
        return `fill: ${props.fill};`;
      }
      return null;
    }}
  }
`;

export { IconWrapper };
