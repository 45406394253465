import React from 'react';

const Areal = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2.069l2.468 2.016v-.733c0-.275.183-.458.457-.458h2.742c.274 0 .457.183.457.458V7.11l1.645 1.375c.092.091.183.183.183.366v12.69c0 .276-.183.459-.457.459H4.457C4.183 22 4 21.817 4 21.542V8.852c0-.093.091-.276.183-.367l7.769-6.416a.581.581 0 01.548 0zm-.274.916l-7.312 6.05-.001.791h.391v1h-.391v5.087h.391v1h-.391l.001 4.17h14.624l-.001-4.17h-.32v-1h.32v-5.087h-.32v-1h.32l.001-.792-1.645-1.283c-.092-.091-.183-.183-.183-.366V3.81h-1.828v1.283c0 .184-.091.275-.274.367-.092 0-.366 0-.457-.092l-2.925-2.383zM7.804 15.913v1H6.5v-1h1.304zm5 0v1H11.5v-1h1.304zm2.5 0v1H14v-1h1.304zm2.609 0v1h-1.304v-1h1.304zm-7.609 0v1H9v-1h1.304zm-2.5-6.087v1H6.5v-1h1.304zm5 0v1H11.5v-1h1.304zm2.5 0v1H14v-1h1.304zm2.609 0v1h-1.304v-1h1.304zm-7.609 0v1H9v-1h1.304z"
      fill="#000"
    />
  </svg>
);

export default Areal;
