import React from 'react';

const SimpleCase = () => (
  <svg viewBox="0 0 17 10" fill="none" width="1em" height="1em">
    <path
      d="M5.291 1a.559.559 0 00-.23.05.527.527 0 00-.184.138L1.637 5h4.738c.14 0 .276.053.376.146.1.094.155.221.155.354 0 .398.168.78.467 1.06.299.282.704.44 1.127.44.423 0 .828-.158 1.127-.44.299-.28.467-.662.467-1.06 0-.133.056-.26.155-.354A.549.549 0 0110.625 5h4.739l-3.24-3.812a.527.527 0 00-.185-.138.56.56 0 00-.23-.05H5.29zM4.048.563c.15-.175.338-.317.553-.415.216-.097.451-.148.69-.148h6.418c.239 0 .474.05.69.148.215.098.404.24.553.415l3.931 4.625a.492.492 0 01.113.374l-.414 3.124a1.475 1.475 0 01-.527.939 1.653 1.653 0 01-1.056.375H2.002c-.39 0-.764-.133-1.056-.375a1.475 1.475 0 01-.527-.939L.004 5.562a.472.472 0 01.113-.374L4.048.563z"
      fill="#4D4D4D"
    />
  </svg>
);

export default SimpleCase;
