import React from 'react';

/** @deprecated Use Icons.Illustration.Clock instead.*/
const Clock = () => (
  <svg viewBox="0 0 22 22" width="1em" height="1em">
    <g
      transform="translate(1 1)"
      stroke="#D50018"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    >
      <path d="M9.5 4v6.5l5 2.5" />
      <circle cx={10} cy={10} r={10} />
    </g>
  </svg>
);

export default Clock;
