import React from 'react';

/** @deprecated Use Icons.Action.Alert instead.*/
const ErrorRed = () => (
  <svg viewBox="0 0 26 26" width="1em" height="1em">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle cx={12} cy={17.25} r={1.25} fill="#E4172F" />
      <circle cx={12} cy={12} r={12} stroke="#E4172F" strokeWidth={2} />
      <path stroke="#E4172F" strokeWidth={2} d="M12 6.215v7.86" />
    </g>
  </svg>
);

export default ErrorRed;
