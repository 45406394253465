import { RddkTheme } from '../../Foundation/Theme';
import { Color } from '../Color';
import { FontStyle, FontWeight, TypographyStylesMap } from './types';

/**
 * This is the Typography Styles Map that describes how all the different
 * typography styles should render, look and behave for different breakpoints.
 * Note: The "sizes" are defined as such: [font-size, line-height, letter-spacing]
 */
export const TYPOGRAPHY_STYLES: TypographyStylesMap = {
  display: {
    renderAs: 'h1',
    fontFamily: RddkTheme.fonts.serif,
    fontWeight: FontWeight.medium,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['41px', '39px', '-1.4px'],
      sm: ['46px', '43px', '-1.5px'],
      md: ['70px', '66px', '-2px'],
      xl: ['93px', '90px', '-2.33px'],
    },
  },
  h1: {
    renderAs: 'h1',
    fontFamily: RddkTheme.fonts.serif,
    fontWeight: FontWeight.medium,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['41px', '39px', '-1.4px'],
      sm: ['46px', '43px', '-1.5px'],
      md: ['46px', '43px', '-1.5px'],
      xl: ['88px', '85px', '-2.2px'],
    },
  },
  h2: {
    renderAs: 'h2',
    fontFamily: RddkTheme.fonts.serif,
    fontWeight: FontWeight.medium,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['30px', '30px', '-0.7px'],
      sm: ['32px', '32px', '-0.75px'],
      md: ['44px', '46px', '-0.9px'],
      xl: ['54px', '52px', '-1.13px'],
    },
  },
  h3: {
    renderAs: 'h3',
    fontFamily: RddkTheme.fonts.serif,
    fontWeight: FontWeight.medium,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['24px', '24px', '-0.45px'],
      sm: ['26px', '27px', '-0.5px'],
      md: ['32px', '38px', '-0.4px'],
      xl: ['44px', '43px', '-0.6px'],
    },
  },
  h4: {
    renderAs: 'h4',
    fontFamily: RddkTheme.fonts.serif,
    fontWeight: FontWeight.medium,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['24px', '24px', '-0.45px'],
      sm: ['30px', '27px', '-0.4px'],
      md: ['28px', '38px', '-0.4px'],
      xl: ['38px', '43px', '-0.5px'],
    },
  },
  h5: {
    renderAs: 'h5',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.semiBold,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['21px', '24px', '-0.35px'],
      sm: ['23px', '26px', '-0.4px'],
      md: ['24px', '28px', '-0.3px'],
      xl: ['32px', '40px', '-0.4px'],
    },
  },
  h6: {
    renderAs: 'h6',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.semiBold,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['17px', '20px', '-0.25px'],
      sm: ['18px', '21px', '-0.3px'],
      md: ['23px', '25px', '-0.3px'],
      xl: ['24px', '26px', '-0.3px'],
    },
  },
  h7: {
    renderAs: 'h6',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.semiBold,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['14px', '16px', '-0.1px'],
      sm: ['15px', '18px', '-0.2px'],
      md: ['15px', '18px', '-0.3px'],
      xl: ['18px', '32px', '-0.15px'],
    },
  },
  h8: {
    renderAs: 'h6',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.semiBold,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['16px', '18px', '-0.15px'],
      sm: ['16px', '18px', '-0.15px'],
      md: ['16px', '18px', '-0.15px'],
      xl: ['16px', '18px', '-0.15px'],
    },
  },
  manchetSmall: {
    renderAs: 'p',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    color: Color.rdBlackTint2,
    sizes: {
      xs: ['15px', '18px', '0.15px'],
      sm: ['16px', '20px', '0.2px'],
      md: ['16px', '20px', '-0.2px'],
      xl: ['18px', '25px', 'normal'],
    },
  },
  manchetBig: {
    renderAs: 'p',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    color: Color.rdBlackTint2,
    sizes: {
      xs: ['20px', '26px', 'normal'],
      sm: ['20px', '26px', 'normal'],
      md: ['20px', '26px', 'normal'],
      xl: ['20px', '26px', 'normal'],
    },
  },
  paragraph: {
    renderAs: 'p',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    color: Color.rdBlackTint2,
    sizes: {
      xs: ['13px', '20px', 'normal'],
      sm: ['14px', '21px', 'normal'],
      md: ['14px', '18px', 'normal'],
      xl: ['20px', '30px', 'normal'],
    },
  },
  paragraphSmall: {
    renderAs: 'p',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    color: '#424242',
    sizes: {
      xs: ['12px', '18px', 'normal'],
      sm: ['12px', '18px', 'normal'],
      md: ['12px', '18px', 'normal'],
      xl: ['16px', '24px', 'normal'],
    },
  },
  list: {
    renderAs: 'p',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    color: Color.rdPureBlack,
    sizes: {
      xs: ['18px', '22px', 'normal'],
      sm: ['18px', '22px', 'normal'],
      md: ['18px', '22px', 'normal'],
      xl: ['18px', '22px', 'normal'],
    },
  },
  image: {
    renderAs: 'p',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    fontStyle: FontStyle.Italic,
    color: Color.rdBlackTint2,
    sizes: {
      xs: ['11px', '13px', 'normal'],
      sm: ['12px', '14px', 'normal'],
      md: ['12px', '14px', 'normal'],
      xl: ['14px', '16px', 'normal'],
    },
  },
  label: {
    renderAs: 'h6',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.semiBold,
    color: Color.rdBlackTint2,
    textTransform: 'uppercase',
    sizes: {
      xs: ['11px', '14px', '1.1px'],
      sm: ['12px', '15px', '1.2px'],
      md: ['12px', '15px', '1.2px'],
      xl: ['14px', '17px', '1.8px'],
    },
  },
  labelSmall: {
    renderAs: 'h6',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.semiBold,
    color: Color.rdBlackTint2,
    textTransform: 'uppercase',
    sizes: {
      xs: ['11px', '14px', '1.1px'],
      sm: ['11px', '14px', '1.1px'],
      md: ['11px', '14px', '1.1px'],
      xl: ['12px', '15px', '0.8px'],
    },
  },
  none: {
    renderAs: 'div',
    fontFamily: RddkTheme.fonts.sans,
    fontWeight: FontWeight.regular,
    color: Color.rdPureBlack,
    sizes: {},
  },
};
