import { SpaceSize } from '../../Components';
import { breakpoints, breakpointsBootstrap } from '../../Layout';
import { Color } from '../Color';

interface RddkThemeInterface {
  fontSize: string;
  fonts: {
    serif: string;
    sans: string;
  };
  colors: typeof Color;
  space: SpaceSize[];
  breakpoints: string[];
}

export const RddkTheme: RddkThemeInterface = {
  fontSize: '16px',
  colors: Color,
  breakpoints: Object.values(breakpointsBootstrap)
    .slice(1)
    .map((value) => `${value}px`),
  space: [0, 4, 8, 12, 16, 24, 36, 48, 60, 80, 90, 120, 128],
  fonts: {
    serif: '"IBM Plex Serif", serif',
    sans: '"IBM Plex Sans", sans-serif',
  },
};

// Set up breakpoint aliases bypassing type checking as this is the way to set them up.
// For more info please refer to: https://styled-system.com/responsive-styles/#using-objects

const Breakpoints = RddkTheme.breakpoints as any;
Breakpoints.mobileLarge = breakpoints.mobileLarge + 'px';
Breakpoints.tablet = breakpoints.tablet + 'px';
Breakpoints.desktop = breakpoints.desktop + 'px';
Breakpoints.desktopLarge = breakpoints.desktopLarge + 'px';

// New Bootstrap breakpoints:
Breakpoints.xs = '0px';
Breakpoints.sm = '576px';
Breakpoints.md = '768px';
Breakpoints.lg = '992px';
Breakpoints.xl = '1200px';
Breakpoints.xxl = '1400px';
