import React from 'react';

const Completed = () => (
  <svg viewBox="0 0 1024 872.906" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M378.36 872.906q-9.99 0-19.355-3.746-9.366-3.746-16.858-11.239L14.985 522.018Q0 505.785 0 484.556q0-21.228 14.985-34.963 14.984-14.985 36.212-14.985t36.213 14.985l287.204 294.696L931.539 21.284q12.487-17.482 33.715-19.979t37.462 9.99q17.482 13.736 19.979 34.339 2.497 20.604-9.99 38.086L419.567 852.926q-7.492 8.741-16.857 14.361-9.366 5.619-20.604 5.619h-3.746z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Completed;
