import { css } from 'styled-components';
import { Color } from '../../../Foundation';
import { mediaQuery } from '../../../Layout';

/**
 * Base styling for buttons
 */
export const dayCardBaseStyle = css`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border: none;
  background: none;
  outline: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  min-height: 135px;
  min-width: 65px;
  max-width: 95px;
  margin-bottom: 8px;
  margin-right: 12px;
  flex: 1 0 100%;
  box-shadow: 0 3px 6px 0 ${Color.shadowIdle};
  > * {
    flex: 1 0 100%;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 6px;
    bottom: -6px;
    left: 0;
    transition: transform 0.2s ease-in-out;
    background-color: ${Color.rdRed};
  }
  &:hover,
  &:focus {
    &::before {
      transform: translateY(-6px);
    }
  }
  ${mediaQuery.smDown} {
    max-width: 64px;
    margin-right: 8px;
    min-height: 100px;
  }
`;

export const dayCardActiveStyle = css`
  background-color: ${Color.rdRed};
`;

export const dayCardDisabledStyle = css`
  cursor: not-allowed;
  opacity: 0.25;
  @supports (filter: opacity(1%)) {
    opacity: 1;
    filter: opacity(25%);
    &::before {
      content: none;
    }
  }
`;
