import React from 'react';

const Doc = () => (
  <svg viewBox="0 0 723.156 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M426.549 0l296.607 307.906v651.123q0 26.836-18.361 45.904Q686.433 1024 659.597 1024H63.559q-26.836 0-45.198-19.067Q0 985.865 0 959.029V64.971q0-26.836 18.361-45.903Q36.723 0 63.559 0h362.99zm-5.65 60.734H112.993q-22.598 0-37.429 16.949-14.83 16.949-14.83 40.96V906.77q0 24.011 14.83 40.96 14.831 16.949 37.429 16.949h497.17q22.599 0 37.429-16.949 14.83-16.949 14.83-40.96V302.257L420.899 60.734zm-59.321 0v241.523q0 24.011 17.655 41.666 17.655 17.655 43.079 17.655h240.11v-59.321L420.899 60.734h-59.321z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Doc;
