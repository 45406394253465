import React from 'react';

const Reload = () => (
  <svg viewBox="0 0 17 16" fill="none" width="1em" height="1em">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.343 7.75c0 3.765 2.844 6.33 6.038 6.86a.689.689 0 01.435.265.632.632 0 01.112.481.63.63 0 01-.274.417.686.686 0 01-.502.108C3.401 15.258 0 12.222 0 7.75c0-1.902.9-3.396 1.952-4.531.754-.814 1.616-1.476 2.31-1.982H2.153a.658.658 0 01-.455-.181.606.606 0 01-.189-.438c0-.164.068-.32.189-.437A.658.658 0 012.153 0H6.02c.171 0 .335.065.456.181.12.116.189.273.189.437V4.33a.606.606 0 01-.19.438.658.658 0 01-.455.18.658.658 0 01-.455-.18.606.606 0 01-.189-.438V2.046l-.001.002c-.737.532-1.637 1.184-2.417 2.027-.92.994-1.614 2.198-1.614 3.675zm14.314.5c0-3.725-2.783-6.275-5.937-6.844a.689.689 0 01-.438-.266.631.631 0 01-.11-.488.63.63 0 01.286-.416.687.687 0 01.51-.096C13.67.808 17 3.826 17 8.25c0 1.902-.9 3.395-1.952 4.531-.754.814-1.616 1.476-2.31 1.982h2.109c.17 0 .334.065.455.181.121.116.189.273.189.438a.606.606 0 01-.189.437.659.659 0 01-.455.181H10.98a.658.658 0 01-.456-.181.606.606 0 01-.189-.438v-3.71c0-.164.068-.322.19-.438a.658.658 0 01.455-.18c.17 0 .334.064.455.18.121.116.189.274.189.438v2.282h.003c.735-.534 1.636-1.185 2.415-2.029.92-.993 1.614-2.195 1.614-3.674z"
      fill="#000"
    />
  </svg>
);

export default Reload;
