import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { BoxShadow } from '../../Foundation/BoxShadow';
import { Color } from '../../Foundation/Color';

export type CheckBoxProps = {
  label: string;
  id: string;
  name: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const StyledCheckmark = styled.span`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid ${Color.rdGrey};
  background-color: hsl(0, 0%, 100%);
  transition: 0.1s;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  &:checked {
    border: 2px solid ${Color.rdDarkRed};
  }
  &:before {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  background-color: ${Color.rdBrightWhite};
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: ${Color.rdBrightWhite};
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  &:checked {
    background-color: ${Color.rdRed};
    & ~ ${StyledCheckmark} {
      border-color: ${Color.rdRed};
      &:before {
        background-color: ${Color.rdRed};
      }
    }
  }
  &:not([disabled]) {
    & ~ ${StyledBg}, & ~ ${StyledCheckmark}, & ~ p {
      cursor: pointer;
    }
  }
  &:not([disabled]):not(:checked):focus-visible {
    & ~ ${StyledCheckmark} {
      border-color: ${Color.rdGrey};
      outline-width: 1px;
      outline-offset: 1px;
      outline-style: solid;
      outline-color: ${Color.scarlet};
    }
  }
`;

export const StyledLabel = styled.label`
  position: relative;
  border-radius: 5px;
  box-shadow: ${BoxShadow.grey};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 2;
  &:hover {
    ${StyledInput}:not([disabled]) {
      & ~ ${StyledBg} {
        border-color: ${Color.scarlet};
        background-color: ${Color.rdLightRed};
      }
      & ~ ${StyledCheckmark} {
        border-color: ${Color.scarlet};
      }
    }
  }
`;
