import React from 'react';

/** @deprecated Use Icons.Action.Graph instead.*/
const Graph = () => (
  <svg viewBox="0 0 12 14" width="1em" height="1em">
    <g transform="translate(.5 2)" fill="#000" fillRule="evenodd">
      <rect width={1.5} height={7} x={6} y={3} rx={0.5} />
      <rect width={1.5} height={7} x={3} y={3} rx={0.5} />
      <rect width={1.5} height={5} y={5} rx={0.5} />
      <rect width={1.5} height={10} x={9} rx={0.5} />
    </g>
  </svg>
);

export default Graph;
