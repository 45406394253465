import React from 'react';

const Slider = () => (
  <svg viewBox="0 0 6 8" width="1em" height="1em">
    <g fill="#ABABAB" fillRule="evenodd">
      <rect width={2} height={8} rx={1} />
      <rect x={4} width={2} height={8} rx={1} />
    </g>
  </svg>
);

export default Slider;
