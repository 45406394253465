import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../../Foundation';

export const ScrollIndicator: React.FC = () => {
  const [percent, setPercent] = React.useState(0);

  const updatePercent = React.useCallback(() => {
    const html = document.documentElement,
      body = document.body,
      top = 'scrollTop',
      height = 'scrollHeight';

    const percent =
      ((html[top] || body[top]) /
        ((html[height] || body[height]) - html.clientHeight)) *
      100;

    setPercent(percent);
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', updatePercent);
    return () => {
      window.removeEventListener('scroll', updatePercent);
    };
  }, [updatePercent]);

  return <Bar style={{ width: `${percent}%` }} />;
};

const Bar = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: ${Color.rdRedHover};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  transition: width 0.1s;
`;
