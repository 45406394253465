import React from 'react';

/** @deprecated Use Icons.Action.Download instead.*/
const Download = () => (
  <svg viewBox="0 0 12 14" width="1em" height="1em">
    <path
      d="M9.733 6.008L6.386 9.354 3.04 6.008m3.346 2.584L6.5 1.285M2 12.299h8.773"
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Download;
