import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../Foundation/Color';
import { Typography } from '../../../Foundation';
import { Icons, IconWrapper } from '../../../Icons';
import { SearchResultType } from './types';

type SearchResultsProps = {
  results: SearchResultType[] | undefined;
  handleResultClick?: (result: SearchResultType) => void;
};
export const SearchResults: React.FC<SearchResultsProps> = ({
  results = [],
  handleResultClick,
}) => {
  const onResultClick = (result: SearchResultType) => {
    if (typeof handleResultClick === 'function') {
      handleResultClick(result);
    }
  };

  return (
    <SearchResultsWrapper>
      {results.map((result, i) => (
        <SearchResult key={`searchResult${i}`}>
          {result.thumbnailUrl && (
            <SearchResultImage src={result.thumbnailUrl} alt="Image" />
          )}
          <ResultTextWrapper>
            {result.title && (
              <ResultLink
                onClick={() => onResultClick(result)}
                href={result.link}
              >
                {result.fileFormat?.search(/^pdf/i) === 0 && (
                  <IconWrapper title="Pdf" width={'20px'}>
                    <Icons.Pdf />
                  </IconWrapper>
                )}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: result.title,
                  }}
                  type="h8"
                />
              </ResultLink>
            )}
            <ResultDescription
              dangerouslySetInnerHTML={{
                __html: result.description ?? '',
              }}
              type="none"
            />
          </ResultTextWrapper>
        </SearchResult>
      ))}
    </SearchResultsWrapper>
  );
};
const ResultTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ResultDescription = styled(Typography)`
  color: ${Color.grey80};
  line-height: 24px;
  letter-spacing: normal;
  margin-top: 6px;
`;
const SearchResultsWrapper = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  max-width: 771px;
`;
const SearchResult = styled.div`
  width: 100%;
  min-height: 100px;
  max-height: 500px;
  border-bottom: 1px dashed ${Color.grey30};
  padding: 15px 0px 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  &:last-child {
    border-bottom: none;
  }
`;
const ResultLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  gap: 5px;
`;
const SearchResultImage = styled.img`
  margin-right: 25px;
  max-height: 90px;
`;
