import React from 'react';

const DownExpand = () => (
  <svg viewBox="0 0 1024 529.661" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M513.086 529.661q-10.002 0-18.753-3.126-8.752-3.125-16.253-10.627L16.75 89.584Q1.748 74.582.497 53.328-.753 32.074 14.25 17.072 28.002.819 49.256.194 70.51-.431 86.762 14.571L513.086 408.39 938.16 14.571Q953.162-.431 974.416.194q21.254.625 36.256 16.878 13.753 15.002 13.128 36.256-.626 21.254-16.878 36.256l-458.83 426.324q-7.501 7.502-16.878 10.627-9.376 3.126-18.128 3.126z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default DownExpand;
