import React from 'react';

/** @deprecated Use Icons.Action.Doc instead.*/
const Document = () => (
  <svg viewBox="0 0 16 22" width="1em" height="1em">
    <path
      d="M9.326 0l6.486 6.605v13.988c0 .777-.617 1.407-1.377 1.407H1.377C.617 22 0 21.37 0 20.593V1.408C0 .63.616 0 1.377 0h7.949zm-.109 1.294H2.465c-.634 0-1.147.556-1.147 1.242v16.929c0 .685.513 1.24 1.147 1.24h10.882c.634 0 1.147-.555 1.147-1.24V6.475l-5.277-5.18zm-1.311 0h1.31l5.278 5.185v1.292h-5.27a1.304 1.304 0 01-1.318-1.295V1.294z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Document;
