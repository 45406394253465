import React from 'react';

const DocSearch = () => (
  <svg viewBox="0 0 27 27" fill="none" width="1em" height="1em">
    <path
      d="M6.287 5.204v6.86-.026 10.5V5.203zm2.709 10.292h3.895c.107-.208.223-.398.347-.571.123-.174.255-.344.395-.513H8.996v1.084zm0 4.333h3.127a3.891 3.891 0 01-.076-.542 5.207 5.207 0 01-.004-.541H8.996v1.083zM5.204 23.62V4.12h10.292l4.875 4.875v3.271a2.864 2.864 0 00-.535-.13c-.181-.027-.364-.05-.549-.072V9.537h-4.333V5.204H6.287v17.333h6.923c.134.208.285.4.453.578.167.177.343.346.526.506H5.204zm13.546-1.833c.852 0 1.572-.295 2.158-.884.586-.589.88-1.31.88-2.163 0-.852-.295-1.571-.884-2.158-.589-.586-1.31-.88-2.163-.88-.852 0-1.571.295-2.158.885-.586.588-.88 1.309-.88 2.162 0 .852.295 1.572.885 2.158.588.586 1.309.88 2.162.88zm5.52 3.228l-3.008-3.007c-.35.28-.74.494-1.17.64a4.115 4.115 0 01-1.346.222c-1.146 0-2.12-.4-2.922-1.203-.802-.801-1.203-1.776-1.203-2.922 0-1.147.401-2.12 1.203-2.922s1.775-1.203 2.922-1.203c1.146 0 2.12.401 2.921 1.203.802.801 1.203 1.775 1.203 2.922 0 .47-.073.918-.22 1.346-.147.428-.36.819-.641 1.17l3.007 3.009-.745.745z"
      fill="#616161"
    />
  </svg>
);

export default DocSearch;
