import React from 'react';

const SimpleHouse = () => (
  <svg viewBox="0 0 11 12" fill="none" width="1em" height="1em">
    <path
      d="M4.23 11.572V8.567c0-.21.212-.424.424-.424h1.692c.212 0 .423.214.423.429v3c0 .113.045.222.124.303a.42.42 0 00.3.125h3.384a.42.42 0 00.3-.126c.078-.08.123-.189.123-.303v-6a.433.433 0 00-.123-.303L9.73 4.108V1.287a.431.431 0 00-.124-.303.42.42 0 00-.3-.125h-.845a.42.42 0 00-.3.125.431.431 0 00-.124.303v1.109L5.8.125a.423.423 0 00-.462-.092.423.423 0 00-.138.093L.124 5.268A.429.429 0 000 5.572v6c0 .113.045.222.124.303a.42.42 0 00.3.125h3.384a.42.42 0 00.299-.126c.08-.08.124-.189.124-.303z"
      fill="#4D4D4D"
    />
  </svg>
);

export default SimpleHouse;
