import React from 'react';

const SimpleCalendar = () => (
  <svg viewBox="0 0 13 13" fill="none" width="1em" height="1em">
    <path
      d="M3.25.406a.406.406 0 10-.813 0v.406h-.812A1.625 1.625 0 000 2.438v.813h13v-.813A1.625 1.625 0 0011.375.813h-.813V.406a.406.406 0 10-.812 0v.406h-6.5V.406zM13 11.375V4.062H0v7.313A1.625 1.625 0 001.625 13h9.75A1.625 1.625 0 0013 11.375zM8.819 7.194L6.38 9.63a.406.406 0 01-.575 0L4.587 8.413a.407.407 0 01.576-.576l.93.932 2.15-2.15a.407.407 0 01.576.575z"
      fill="#4D4D4D"
    />
  </svg>
);

export default SimpleCalendar;
