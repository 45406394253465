import React from 'react';

const Adjust = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M18 0a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h16zm0 1H2a1 1 0 00-.993.883L1 2v16a1 1 0 00.883.993L2 19h16a1 1 0 00.993-.883L19 18V2a1 1 0 00-.883-.993L18 1zM8.75 11.25a2.5 2.5 0 012.421 1.876l5.079-.001v1.25h-5.079a2.501 2.501 0 01-4.842 0H3.75v-1.25h2.579A2.501 2.501 0 018.75 11.25zm0 1.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm5-8.75a2.5 2.5 0 11-2.421 3.125H3.75v-1.25h7.579A2.501 2.501 0 0113.75 3.75zm0 1.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        id="svg-adjust-a"
      />
    </defs>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <mask id="svg-adjust-b" fill="#fff">
        <use xlinkHref="#svg-adjust-a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#svg-adjust-a" />
      <g mask="url(#svg-adjust-b)" fill="currentColor">
        <path d="M-1-1v22h22V-1z" />
      </g>
    </g>
  </svg>
);

export default Adjust;
