import React from 'react';

const Calendar = () => (
  <svg viewBox="0 0 1024 1021.602" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M51.56 971.241V358.52h922.079v612.721zm947.26-868.122H818.96V51.56q0-21.583-14.988-36.572Q788.984 0 768.6 0h-52.759q-20.384 0-35.373 14.988Q665.48 29.977 665.48 51.56v51.559H358.52V51.56q0-21.583-14.988-36.572Q328.543 0 308.159 0H255.4q-20.384 0-35.372 14.988Q205.04 29.977 205.04 51.56v51.559H26.379q-10.791 0-18.585 7.794Q0 118.707 0 128.3v868.122q0 10.791 7.794 17.985 7.794 7.195 18.585 7.195H998.82q10.791 0 17.986-7.195 7.194-7.194 7.194-17.985V128.3q0-9.593-7.194-17.387-7.195-7.794-17.986-7.794z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Calendar;
