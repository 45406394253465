import React from 'react';

const ClockAlt = () => (
  <svg viewBox="0 0 15 14" fill="none" width="1em" height="1em">
    <path
      d="M7.016 0a7.095 7.095 0 00-3.898 1.164 6.931 6.931 0 00-2.584 3.1 6.806 6.806 0 00-.4 3.99c.272 1.34.94 2.57 1.92 3.536a7.05 7.05 0 003.593 1.89 7.12 7.12 0 004.054-.393 6.993 6.993 0 003.148-2.544 6.83 6.83 0 001.183-3.837 6.86 6.86 0 00-2.058-4.88A7.081 7.081 0 007.016 0zm0 13a6.26 6.26 0 01-3.44-1.027 6.116 6.116 0 01-2.28-2.735 6.005 6.005 0 01-.352-3.52 6.065 6.065 0 011.695-3.12A6.221 6.221 0 015.809.93a6.282 6.282 0 013.576.346 6.17 6.17 0 012.778 2.245 6.026 6.026 0 011.043 3.385 6.053 6.053 0 01-1.815 4.307A6.248 6.248 0 017.016 13zm4.264-6.094a.403.403 0 01-.12.288.416.416 0 01-.292.119H7.016a.416.416 0 01-.292-.12.403.403 0 01-.12-.287V3.115c0-.108.043-.211.12-.288a.416.416 0 01.584 0c.077.077.12.18.12.288V6.5h3.44c.109 0 .214.043.291.119.078.076.121.18.121.287z"
      fill="#000"
    />
  </svg>
);

export default ClockAlt;
