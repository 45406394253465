import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../Icons';
import { Color } from '../../Foundation/Color';

import { Container, mediaQuery } from '../../Layout';
import { LinkItem } from './types';

const HEIGHT_PX = 40;

type TopStripProps = {
  areaLinks: LinkItem[];
  backLink?: string;
  backText?: string;
  feedbackContent?: any;
};

export const TopStrip: React.FC<TopStripProps> = ({
  areaLinks,
  backLink,
  backText,
  feedbackContent,
}) => {
  const showBackLink = !!backLink && !!backText;
  const showFeedbackLink = !!feedbackContent;

  return (
    <>
      <Background>
        <Container>
          <Row>
            <Left>
              {areaLinks.map((link, i) => {
                return (
                  <AreaLink
                    key={`link${i}`}
                    href={link.url}
                    active={link.isCurrent}
                    data-tracking-id={link.trackingcode}
                  >
                    {link.name}
                  </AreaLink>
                );
              })}
            </Left>

            <Right>
              {showBackLink && (
                <a href={backLink}>
                  <Icons.ArrowLeft />
                  {backText}
                </a>
              )}

              {showBackLink && showFeedbackLink && <Divider />}

              {showFeedbackLink && feedbackContent}
            </Right>
          </Row>
        </Container>
      </Background>

      <EdgeOverlay />
    </>
  );
};

const Background = styled.div`
  position: relative;
  z-index: 5000;
  background: #000;
  overflow-x: scroll;

  ${mediaQuery.sm} {
    overflow-x: auto;
  }
`;

const EdgeOverlay = styled.div`
  display: block;
  position: absolute;
  z-index: 6000;
  top: 0;
  right: 0;
  height: ${HEIGHT_PX}px;
  width: 30px;
  pointer-events: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );

  ${mediaQuery.sm} {
    display: none;
  }
`;

const Row = styled.div`
  height: ${HEIGHT_PX}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  white-space: nowrap;
  min-width: 210px;

  ${mediaQuery.md} {
    min-width: 250px;
  }
`;

const AreaLink = styled.a<{ active?: boolean }>`
  display: inline-block;
  padding: 10px 8px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  color: ${Color.white};
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: opacity 0.3s;

  :first-child {
    padding-left: 0;
  }
  :hover,
  :focus {
    opacity: 1;
  }

  ${mediaQuery.md} {
    padding: 10px 10px;
  }
`;

const Right = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${Color.white};

  ${mediaQuery.smDown} {
    padding-right: 30px;
  }

  // Link styling is inside here because
  // {feedbackContent} can be anything - also links
  a {
    display: inline-block;
    padding: 10px 0;
    color: ${Color.white};
    text-decoration: none;

    svg {
      color: ${Color.white};
      width: 6px;
      height: 12px;
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
  }
`;

const Divider = styled.span`
  display: inline-block;
  width: 1px;
  height: 16px;
  margin: 0 10px;
  background: #757575;
`;
