import React from 'react';

const Rate = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <path
      d="M11.774 7.113L6.06 16.889c-.15.3 0 .602.3.602h11.28c.301 0 .451-.301.301-.602l-5.565-9.776a.457.457 0 00-.602 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Rate;
