import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Color } from '../../Foundation/Color';

export interface AnimatedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: string;
}

export const AnimatedButton = styled.button<AnimatedButtonProps>`
  width: ${({ size }) => size || '56px'};
  height: ${({ size }) => size || '56px'};
  outline: none;
  padding: 0;
  border: none;
  background: #fff;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;

  &::before {
    transition: all 0.5s ease-out;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -18%;
    bottom: 0;
    width: 0;
    background-color: ${Color.rdRed};
    transform: skewX(-20deg);
  }

  svg {
    z-index: 1;
    path {
      transition: all 0.4s ease-out;
    }
  }

  &:hover,
  &:focus {
    &::before {
      width: 125%;
    }

    svg path {
      fill: ${Color.rdBrightWhite};
    }
  }
`;
