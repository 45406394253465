import React from 'react';

const Remove = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M717.04 489.218H306.96q-9.592 0-17.386 7.194-7.794 7.195-7.794 17.986 0 10.792 7.794 18.586 7.794 7.794 17.386 7.794h410.08q10.791 0 17.986-7.794 7.194-7.794 7.194-18.586 0-10.791-7.194-17.986-7.195-7.194-17.986-7.194zM512 972.44q-94.726 0-178.66-35.972-83.935-35.972-146.886-98.922-62.95-62.951-98.922-146.886Q51.56 606.726 51.56 512t35.972-178.66q35.972-83.935 98.922-146.886 62.951-62.95 146.886-98.922Q417.274 51.56 512 51.56t178.66 35.972q83.935 35.972 146.886 98.922 62.95 62.951 98.922 146.886Q972.44 417.274 972.44 512t-35.972 178.66q-35.972 83.935-98.922 146.886-62.951 62.95-146.886 98.922Q606.726 972.44 512 972.44zM512 0Q406.482 0 312.956 40.768q-93.527 39.569-163.073 109.115-69.546 69.546-109.115 163.073Q0 406.482 0 512q0 105.518 40.768 199.044 39.569 93.527 109.115 163.073 69.546 69.546 163.073 109.115Q406.482 1024 512 1024q105.518 0 199.044-40.768 93.527-39.569 163.073-109.115 69.546-69.546 109.115-163.073Q1024 617.518 1024 512q0-105.518-40.768-199.044-39.569-93.527-109.115-163.073-69.546-69.546-163.073-109.115Q617.518 0 512 0z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Remove;
