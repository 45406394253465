import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Color } from '../../Foundation';
import { Step } from './Step';

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.vertical {
    flex-direction: column;
    height: 100%;
  }
`;

const ConnectorContainer = styled.div`
  width: 100%;
  height: 100%;

  &.vertical {
    display: flex;
    justify-content: center;
  }
`;

const Connector = styled.div`
  border-top: 3px solid ${Color.grey60};

  &.vertical {
    border-left: 3px solid ${Color.grey60};
    height: 100%;
  }
`;

export type StepperProps = {
  /**
   * The index of the active step.
   * @default 0
   */
  activeStep?: number;
  /**
   * Wether the stepper should be vertical or horizontal.
   * @default false
   */
  vertical?: boolean;
};

export const Stepper = (props: PropsWithChildren<StepperProps>) => {
  const activeStep = { activeStep: props.activeStep ?? 0 };
  const isVertical = props.vertical ?? false;

  let children: JSX.Element[] = [...(props.children as any)];

  return (
    <StyledDiv className={clsx({ vertical: isVertical })}>
      {children.map((child, index) => (
        <React.Fragment key={`step${index}`}>
          <Step active={index === activeStep.activeStep} props={[child.props]}>
            {child}
          </Step>
          {index < children.length - 1 && (
            <ConnectorContainer
              key={'connector' + index}
              className={clsx({ vertical: isVertical })}
            >
              <Connector className={clsx({ vertical: isVertical })}></Connector>
            </ConnectorContainer>
          )}
        </React.Fragment>
      ))}
    </StyledDiv>
  );
};
