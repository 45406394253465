import React from 'react';
import { TextType, Typography } from '../../Foundation/Typography';
import { Box } from '../../Layout/FlexBox';

export type TextAccordionProps = {
  title: string;
  titleType?: TextType;
  children: any;
};

export const TextAccordion: React.FC<TextAccordionProps> = ({
  title,
  titleType = 'h2',
  children,
}) => {
  return (
    <>
      {title && (
        <Box textAlign="center" marginBottom={{ _: 5, md: 7 }}>
          <Typography type={titleType}>{title}</Typography>
        </Box>
      )}
      {children}
    </>
  );
};
