import React from 'react';

/** @deprecated */
const Refresh = () => (
  <svg viewBox="0 0 22 20" width="1em" height="1em">
    <path
      d="M9.991 0c2.53.014 4.962.958 6.834 2.643l.252.234 2.908 2.782V2h2v7h-7V7l3.508-.001-2.8-2.679A8.296 8.296 0 009.986 2a8 8 0 107.466 10.91l.09-.243 1.886.666A10 10 0 119.99 0z"
      fill="#35A478"
      fillRule="nonzero"
    />
  </svg>
);

export default Refresh;
