import React, {
  FC,
  ReactElement,
  useEffect,
  useRef,
  createRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Icons } from '../../Icons';
import * as handlers from './eventHandlers';
import * as styles from './tableSelectorStyles';
import {
  ClassNames,
  EventHandlerState,
  IndexerPosition,
  TableSelectorProps,
} from './types';

const StyledButton = styled.button`
  ${styles.buttonStyles}
`;

const TableSelectorWrapper = styled.div`
  ${styles.tableSelectorWrapperStyles}
`;

const TabsWrapper = styled.div`
  ${styles.tabsWrapperStyles}
`;

const EllipsisContainer = styled.div`
  ${styles.ellipsisContainerStyles}
`;

const LeftEllipsis = styled.button`
  ${styles.leftEllipsisStyles}
`;
const RightEllipsis = styled.button`
  ${styles.rightEllipsisStyles}
`;

export const TableSelector: FC<TableSelectorProps> = ({
  children,
  isDarkTheme = false,
  darkThemeColor = '#1d1f21',
  size = 'normal',
  scrollable = false,
  selectedIndex: propIndex = 0,
  ...rest
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setselectedIndex] = useState(propIndex);
  const [indexerPosition, setIndexerPosition] = useState<IndexerPosition>({
    left: 0,
    width: 0,
  });
  const prevIndexerPosition: React.MutableRefObject<IndexerPosition> = useRef({
    left: -1,
    width: -1,
  });

  const tabRefs: React.RefObject<any>[] = [];

  const childrenAsArray = Array.isArray(children) ? children : Array(children);

  useEffect(() => {
    const updateIndexerPosition = () => {
      setIndexerPosition({
        left: tabRefs[selectedIndex].current.offsetLeft,
        width: tabRefs[selectedIndex].current.offsetWidth,
      });
    };
    if (tabRefs.length > selectedIndex) {
      tabRefs[selectedIndex].current.classList.add(ClassNames.selectedTab);
      if (prevIndexerPosition.current === indexerPosition) return;
      const newIndexerPostion = {
        left: tabRefs[selectedIndex].current.offsetLeft,
        width: tabRefs[selectedIndex].current.offsetWidth,
      };
      prevIndexerPosition.current = newIndexerPostion;
      setIndexerPosition(newIndexerPostion);

      handlers.setEllipsisStyle(wrapperRef);
      window.addEventListener('resize', updateIndexerPosition);
    }
    return () => window.removeEventListener('resize', updateIndexerPosition);
  }, [selectedIndex, indexerPosition, tabRefs]);

  useEffect(() => {
    handlers.handleTabClick(propIndex, tabRefs, wrapperRef, setIndexerPosition);
    setselectedIndex(propIndex);
  }, [propIndex]);

  const eventHandlerState: EventHandlerState = {
    mouseDown: false,
    mouseDownTimestamp: 0,
    mouseUpListenerSet: false,
    prevLeft: 0,
    mouseDownStartX: 0,
  };
  const handleLeftEllipsisClick = () => {
    handlers.leftEllipsisClick(wrapperRef);
  };

  const handleRightEllipsisClick = () => {
    handlers.rightEllipsisClick(wrapperRef);
  };

  const handleSelectorMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    handlers.selectorMouseDown(eventHandlerState, e);
  };

  const handleSelectorMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    handlers.selectorMouseMove(eventHandlerState, e, wrapperRef);
  };

  const handleSetEllipsisStyle = () => {
    handlers.setEllipsisStyle(wrapperRef);
  };

  return (
    <EllipsisContainer {...rest}>
      <LeftEllipsis
        isDarkTheme={isDarkTheme}
        darkThemeColor={darkThemeColor}
        onClick={handleLeftEllipsisClick}
        className={ClassNames.ellipsisLeft}
      >
        <Icons.ArrowLeft />
      </LeftEllipsis>
      <RightEllipsis
        isDarkTheme={isDarkTheme}
        darkThemeColor={darkThemeColor}
        onClick={handleRightEllipsisClick}
        className={ClassNames.ellipsisRight}
      >
        <Icons.ArrowRight />
      </RightEllipsis>
      <TableSelectorWrapper
        ref={wrapperRef}
        scrollable={scrollable}
        size={size}
        onMouseDown={handleSelectorMouseDown}
        onTouchStart={handleSelectorMouseDown}
        onMouseMove={handleSelectorMouseMove}
        onTouchMove={handleSelectorMouseMove}
        onScroll={handleSetEllipsisStyle}
      >
        <TabsWrapper
          isDarkTheme={isDarkTheme}
          darkThemColor={darkThemeColor}
          indexerPosition={indexerPosition}
        >
          {childrenAsArray
            //filter to remove null , undefined
            .filter((item) => item)
            .map((child: ReactElement, index: number) => {
              const tabRef = createRef<HTMLButtonElement>();
              const { props } = child;
              tabRefs.push(tabRef);
              const handleTabClick = () => {
                handlers.handleTabClick(
                  index,
                  tabRefs,
                  wrapperRef,
                  setIndexerPosition
                );
                setselectedIndex(index);
                props.onClick && props.onClick();
              };
              return (
                <StyledButton
                  ref={tabRef}
                  className={ClassNames.tab}
                  onClick={handleTabClick}
                  isDarkTheme={isDarkTheme}
                  key={index}
                >
                  {props.children}
                </StyledButton>
              );
            })}
        </TabsWrapper>
      </TableSelectorWrapper>
    </EllipsisContainer>
  );
};
