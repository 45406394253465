import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { BoxShadow, Color, Typography } from '../../Foundation';
import { Icons } from '../../Icons';

export type StickyCtaProps = {
  bookMeetingText?: string;
  bookMeetingLink?: string;
  calculateText?: string;
  calculateLink?: string;
  bookMeetingTrackingId?: string;
  calculatorTrackingId?: string;
  hideStickyCalculator?: boolean;
  hideStickybookMeeting?: boolean;
};

export const StickyCta: React.FC<StickyCtaProps> = ({
  bookMeetingText,
  bookMeetingLink,
  calculateText,
  calculateLink,
  bookMeetingTrackingId,
  calculatorTrackingId,
  hideStickyCalculator,
  hideStickybookMeeting,
}) => (
  <Container>
    {!!bookMeetingLink && !!bookMeetingText && !hideStickybookMeeting && (
      <Link data-tracking-id={bookMeetingTrackingId} href={bookMeetingLink}>
        <Icon>
          <Icons.CalendarAddSimple />
        </Icon>
        <Typography type="labelSmall" color={Color.grey90}>
          {bookMeetingText}
        </Typography>
      </Link>
    )}
    {!!calculateLink && !!calculateText && !hideStickyCalculator && (
      <Link data-tracking-id={calculatorTrackingId} href={calculateLink}>
        <Icon>
          <Icons.CalculatorRed />
        </Icon>
        <Typography type="labelSmall" color={Color.grey90}>
          {calculateText}
        </Typography>
      </Link>
    )}
  </Container>
);

const fadeIn = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const Container = styled.div`
  overflow: hidden;
  position: fixed;
  z-index: 500;
  right: 25px;
  bottom: 25px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: solid 1px ${Color.grey30};
  box-shadow: ${BoxShadow.small};
  background-color: ${Color.grey0};
  animation: ${fadeIn} 1s ease;
  animation-iteration-count: 1;
`;

const Link = styled.a`
  width: 112px;
  height: 64px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-left: solid 1px ${Color.grey30};
  transition: background 0.3s;

  :hover,
  :focus,
  :active {
    background: ${Color.white};
  }

  :first-child {
    border-left: 0;
  }
`;

const Icon = styled.span`
  display: block;
  margin-bottom: 3px;

  svg {
    width: 45px;
    height: 24px;
  }
`;
