import React from 'react';

/** @deprecated Use Icons.Illustration.ReviewPerformance instead.*/
const Analysis = () => (
  <svg viewBox="0 0 56 48" width="1em" height="1em">
    <path
      d="M39.511 19.336a.478.478 0 00-.737.153l-3.006 5.978a.498.498 0 00.075.559c1.953 2.14 3.028 4.797 3.028 7.483 0 .271.216.491.482.491h6.165c.266 0 .482-.22.482-.491 0-5.426-2.426-10.724-6.489-14.173m-3.006-2.158a.466.466 0 00-.636.155l-6.246 9.831a.515.515 0 00.143.702l1.19.78a.465.465 0 00.383.058.487.487 0 00.296-.258l5.058-10.612a.514.514 0 00-.188-.656m-8.138 4.153c-6.301 0-11.847 5.372-12.126 11.613H11.03c.254-9.356 7.772-16.888 16.976-16.888 1.148 0 2.378.147 3.595.43l-3.1 4.846h-.133m4.23-5.4a.486.486 0 00-.323-.3c-1.493-.413-3.034-.632-4.46-.632C17.993 15 10 23.301 10 33.506c0 .272.215.494.48.494h6.127a.487.487 0 00.478-.494c0-6.1 5.394-11.435 11.46-11.208a.46.46 0 00.42-.225l3.586-5.696a.506.506 0 00.046-.445m-2.333 14.152c-.71-1.352-2.489-1.8-3.864-.875-.186.126-.354.3-.473.489-.59.931-.66 2.02-.191 2.912A2.562 2.562 0 0028 34c.544 0 1.098-.179 1.6-.516a1.68 1.68 0 00.473-.488c.59-.93.66-2.02.191-2.912"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Analysis;
