import React from 'react';

const Graph = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M2.854 7.393H.3a.29.29 0 00-.212.088.29.29 0 00-.088.213V19.7c0 .084.03.155.088.213A.29.29 0 00.3 20h2.554a.29.29 0 00.213-.088.29.29 0 00.087-.213V7.694a.29.29 0 00-.087-.213.29.29 0 00-.213-.088zm-.3 12.005H.6V8.02h1.952v11.378zM6.833 1.88H4.256a.29.29 0 00-.213.087.29.29 0 00-.088.213V19.7c0 .083.03.154.088.212a.29.29 0 00.213.088h2.578a.29.29 0 00.213-.088.29.29 0 00.087-.213V2.18a.29.29 0 00-.087-.213.29.29 0 00-.213-.087zm3.955 3.734H8.211a.29.29 0 00-.213.088.29.29 0 00-.088.213v13.784c0 .084.03.155.088.213a.29.29 0 00.213.088h2.578a.29.29 0 00.213-.088.29.29 0 00.088-.213V5.915a.29.29 0 00-.088-.213.29.29 0 00-.213-.088zm-.3 13.784H8.51V6.216h1.978v13.182zm4.255-15.664h-2.578a.29.29 0 00-.213.088.29.29 0 00-.087.213V19.7c0 .084.029.155.087.213a.29.29 0 00.213.088h2.578a.29.29 0 00.213-.088.29.29 0 00.088-.213V4.035a.29.29 0 00-.088-.213.29.29 0 00-.213-.088zm-.3 15.664h-1.978V4.336h1.978v15.062zM18.7 0H16.12a.29.29 0 00-.213.088.29.29 0 00-.087.213v19.398c0 .084.029.155.087.213a.29.29 0 00.213.088H18.7a.29.29 0 00.212-.088A.29.29 0 0019 19.7V.301a.29.29 0 00-.088-.213A.29.29 0 0018.7 0z"
        id="svg-graph-a"
      />
    </defs>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <mask id="svg-graph-b" fill="#fff">
        <use xlinkHref="#svg-graph-a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#svg-graph-a" />
      <g mask="url(#svg-graph-b)" fill="currentColor">
        <path d="M0 0v20h20V0z" />
      </g>
    </g>
  </svg>
);

export default Graph;
