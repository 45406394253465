import React from 'react';

const CalendarAlt = () => (
  <svg viewBox="0 0 14 15" fill="none" width="1em" height="1em">
    <path
      d="M9.917 8.75a.564.564 0 00.412-.183.648.648 0 00.171-.442.648.648 0 00-.17-.442.564.564 0 00-.413-.183.564.564 0 00-.413.183.648.648 0 00-.17.442c0 .166.06.325.17.442.11.117.258.183.413.183zm0 2.5a.564.564 0 00.412-.183.648.648 0 00.171-.442.648.648 0 00-.17-.442.564.564 0 00-.413-.183.564.564 0 00-.413.183.648.648 0 00-.17.442c0 .166.06.325.17.442.11.117.258.183.413.183zM7.583 8.125a.648.648 0 01-.17.442A.564.564 0 017 8.75a.564.564 0 01-.412-.183.648.648 0 01-.171-.442c0-.166.061-.325.17-.442A.564.564 0 017 7.5c.155 0 .303.066.412.183.11.117.171.276.171.442zm0 2.5a.648.648 0 01-.17.442.564.564 0 01-.413.183.564.564 0 01-.412-.183.648.648 0 01-.171-.442c0-.166.061-.325.17-.442A.564.564 0 017 10c.155 0 .303.066.412.183.11.117.171.276.171.442zm-3.5-1.875a.564.564 0 00.413-.183.648.648 0 00.17-.442.648.648 0 00-.17-.442.564.564 0 00-.413-.183.564.564 0 00-.412.183.648.648 0 00-.171.442c0 .166.061.325.17.442.11.117.259.183.413.183zm0 2.5a.564.564 0 00.413-.183.648.648 0 00.17-.442.648.648 0 00-.17-.442.564.564 0 00-.413-.183.564.564 0 00-.412.183.648.648 0 00-.171.442c0 .166.061.325.17.442.11.117.259.183.413.183z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.083 1.094c.116 0 .228.05.31.137a.486.486 0 01.128.331v.477c.386-.008.811-.008 1.28-.008h2.398c.469 0 .894 0 1.28.008v-.477c0-.124.046-.243.128-.331a.423.423 0 01.31-.137c.116 0 .227.05.309.137a.486.486 0 01.128.331v.517c.152.013.295.028.431.048.684.099 1.238.306 1.674.774.437.468.63 1.06.723 1.793.089.713.089 1.622.089 2.771v1.32c0 1.149 0 2.059-.09 2.77-.092.733-.285 1.326-.722 1.794-.436.468-.99.675-1.674.774-.665.096-1.514.096-2.586.096H5.801c-1.072 0-1.921 0-2.586-.096-.683-.099-1.237-.306-1.674-.774-.436-.468-.63-1.06-.722-1.793-.09-.713-.09-1.622-.09-2.771v-1.32c0-1.149 0-2.059.09-2.77.092-.733.286-1.326.722-1.794.437-.468.99-.675 1.674-.774.136-.02.28-.035.431-.048v-.517c0-.124.046-.243.128-.33a.423.423 0 01.31-.138zm-.752 1.962c-.586.085-.925.243-1.171.508-.247.264-.395.627-.474 1.255a7.28 7.28 0 00-.034.337h10.696c-.01-.119-.02-.23-.034-.337-.079-.628-.227-.99-.473-1.255-.247-.265-.586-.423-1.172-.508-.6-.086-1.39-.087-2.502-.087H5.833c-1.112 0-1.902.001-2.502.087zM1.604 7.5c0-.534 0-.998.008-1.406h10.776c.008.408.008.872.008 1.406v1.25c0 1.192-.001 2.039-.082 2.681-.079.628-.227.99-.473 1.255-.247.265-.586.423-1.172.508-.6.086-1.39.087-2.502.087H5.833c-1.112 0-1.902-.001-2.502-.087-.586-.085-.925-.243-1.171-.508-.247-.264-.395-.627-.474-1.255-.08-.642-.082-1.49-.082-2.681V7.5z"
      fill="#000"
    />
  </svg>
);

export default CalendarAlt;
