import React from 'react';

const Minus = () => (
  <svg viewBox="0 0 986.471 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M609.396 1024H377.075q-8.935 0-15.19-6.255-6.255-6.255-6.255-15.19V772.021q0-8.936 6.255-15.19 6.255-6.255 15.19-6.255h232.321q8.935 0 15.19 6.255 6.255 6.254 6.255 15.19v230.534q0 8.935-6.255 15.19-6.255 6.255-15.19 6.255zM398.52 981.11h189.431V793.466H398.52V981.11zm566.506-360.991H21.445q-8.935 0-15.19-6.255Q0 607.609 0 598.674V427.113q0-8.935 6.255-15.19 6.255-6.255 15.19-6.255h943.581q8.935 0 15.19 6.255 6.255 6.255 6.255 15.19v171.561q0 8.935-6.255 15.19-6.255 6.255-15.19 6.255zM42.89 577.229h900.691V448.558H42.89v128.671zm566.506-303.805H377.075q-8.935 0-15.19-6.255-6.255-6.255-6.255-15.19V21.445q0-8.935 6.255-15.19Q368.14 0 377.075 0h232.321q8.935 0 15.19 6.255 6.255 6.255 6.255 15.19v230.534q0 8.935-6.255 15.19-6.255 6.255-15.19 6.255zm-210.876-42.89h189.431V42.89H398.52v187.644z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Minus;
