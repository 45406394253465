import React from 'react';

/** @deprecated Use Icons.Action.Message instead.*/
const Eta = () => (
  <svg viewBox="0 0 16 18" width="1em" height="1em">
    <path
      d="M12.48 17.42v-1.6H8.16c-.947 0-1.8-.15-2.56-.45-.76-.3-1.41-.723-1.95-1.27s-.957-1.2-1.25-1.96c-.293-.76-.44-1.6-.44-2.52v-.88c0-.92.147-1.767.44-2.54.293-.773.713-1.44 1.26-2s1.203-1 1.97-1.32c.767-.32 1.623-.48 2.57-.48.853 0 1.64.13 2.36.39s1.343.63 1.87 1.11c.527.48.937 1.067 1.23 1.76.293.693.44 1.473.44 2.34v.66c0 1.053-.147 1.847-.44 2.38-.293.533-.693.8-1.2.8-.307 0-.54-.087-.7-.26-.16-.173-.24-.453-.24-.84V5.16H9.56v1.36h-.1a2.46 2.46 0 00-.75-1.09c-.353-.3-.81-.45-1.37-.45-.453 0-.873.09-1.26.27-.387.18-.723.443-1.01.79-.287.347-.513.787-.68 1.32-.167.533-.25 1.147-.25 1.84 0 .693.083 1.303.25 1.83.167.527.393.963.68 1.31.287.347.623.61 1.01.79.387.18.807.27 1.26.27.64 0 1.147-.16 1.52-.48.373-.32.647-.76.82-1.32h.1c.053.56.287 1.007.7 1.34.413.333.967.5 1.66.5.587 0 1.107-.12 1.56-.36.453-.24.837-.583 1.15-1.03.313-.447.55-.98.71-1.6.16-.62.24-1.31.24-2.07 0-1.213-.19-2.29-.57-3.23-.38-.94-.91-1.73-1.59-2.37a6.848 6.848 0 00-2.41-1.46C10.303.987 9.293.82 8.2.82c-1.227 0-2.33.207-3.31.62-.98.413-1.813.99-2.5 1.73S1.173 4.793.8 5.82.24 7.973.24 9.2c0 1.227.18 2.343.54 3.35.36 1.007.88 1.87 1.56 2.59.68.72 1.51 1.28 2.49 1.68.98.4 2.09.6 3.33.6h4.32zM8 11.74c-.56 0-.99-.167-1.29-.5-.3-.333-.45-.78-.45-1.34V8.48c0-.547.15-.99.45-1.33.3-.34.73-.51 1.29-.51.453 0 .827.123 1.12.37.293.247.44.557.44.93v2.36c0 .387-.15.723-.45 1.01-.3.287-.67.43-1.11.43z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Eta;
