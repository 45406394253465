import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { FileUploadTexts } from './types';

type Props = FileUploadTexts & {
  disabled: boolean;
  handleSubmit: () => void;
};

export const SubmitButton: React.FC<Props> = ({
  disabled,
  handleSubmit,
  textSubmit,
}) => {
  return (
    <Container>
      <Button onClick={handleSubmit} disabled={disabled}>
        {textSubmit}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;
