import styled from 'styled-components';
import { RddkTheme } from '../../Foundation';
import { mediaQuery } from '../../Layout';

export const RadioButtonBoxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${`-${RddkTheme.space[2]}px`};
  > label {
    flex: calc(100% - ${`${RddkTheme.space[4]}px`});
    margin: ${`${RddkTheme.space[2]}px`};
    ${mediaQuery.sm} {
      flex: calc(50% - ${`${RddkTheme.space[4]}px`});
    }
  }
`;
