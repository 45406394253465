import React from 'react';

const Update = () => (
  <svg viewBox="0 0 1024 856.715" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M936.07 367.696l46.747-46.747q7.792-7.792 17.253-7.792 9.46 0 17.252 7.792 6.678 6.678 6.678 16.695 0 10.018-6.678 16.696L912.696 457.853 809.183 354.34q-6.679-6.678-6.679-16.696 0-10.017 6.679-16.695 6.678-7.792 16.695-7.792 10.018 0 16.696 7.792l44.522 43.408q-13.357-75.687-48.974-133.565-35.618-58.991-86.818-99.617-51.2-40.626-112.417-61.774-62.33-21.148-128-22.261-58.991 0-112.417 16.696-53.427 16.695-99.061 48.417-45.635 31.722-82.366 78.47-37.843 46.747-62.33 104.626-4.452 10.017-13.356 13.356-8.905 3.339-17.809 0-10.018-4.452-13.357-13.356-3.339-8.905 0-17.809 28.939-65.67 70.122-117.983 41.183-51.2 92.939-86.817 51.757-35.617 111.861-54.539Q445.217-.721 510.887.392q75.687 0 145.809 25.6 71.234 24.487 128.556 71.235 57.322 46.748 97.391 115.756 40.07 67.896 53.427 154.713zM89.043 490.131l-47.86 47.861q-7.792 6.678-17.253 6.678-9.46 0-17.252-6.678Q0 531.314 0 521.296q0-10.017 6.678-16.695l104.626-103.513 103.513 103.513q6.679 6.678 6.679 16.695 0 10.018-6.679 16.696-6.678 6.678-16.695 6.678-10.018 0-16.696-6.678l-43.409-43.409q13.357 75.687 50.087 133.566 35.618 57.878 86.261 97.947 50.644 40.07 111.861 61.218 62.331 21.148 128 22.261 57.878 0 111.304-16.696 53.427-16.696 99.618-48.417 46.191-31.722 82.922-78.47 36.73-45.635 62.33-104.626 3.339-10.017 12.8-13.356 9.461-3.34 18.365 0 8.905 4.452 12.244 13.356 3.339 8.904 0 17.809-27.826 65.669-69.009 117.982-42.296 51.2-94.052 86.818-51.757 35.617-111.861 54.539-60.104 18.922-125.774 17.809-74.574 0-145.809-25.6-70.121-24.487-127.443-71.235-57.322-46.748-97.391-114.644-40.07-67.895-53.427-154.713z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Update;
