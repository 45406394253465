import * as React from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Typography } from '../../Foundation/Typography/Typography';
import { Input } from './Input';
import { Overlay } from './Overlay';
import { Preview } from './Preview';
import { SubmitButton } from './SubmitButton';
import {
  FileUploadProps,
  FileWithMeta,
  FILE_STATUS,
  IUploadParams,
} from './types';

const DROP_AREA_CLASSNAME = 'dragging';

/**
 * FileUpload is a component that handles file uploading.
 *
 * It has support for drag'n'drop with fullscreen dropzone, multiple uploads, cancelling and removing
 * uploads, progress indicator, min/max number of files, min/max file sizes, and submitting the uploaded files to a form.
 *
 * It is built on the library [react-dropzone](https://github.com/react-dropzone/react-dropzone/).
 */
export const FileUpload: React.FC<FileUploadProps> = ({
  multiple,
  disabled,
  accept,
  minSize,
  maxSize,
  maxFiles = Number.MAX_SAFE_INTEGER,
  noDrag,
  fileList,
  hideSubmitButton,
  clearOnSubmit,
  formDataFileKey = 'file',
  validate,
  getUploadParams,
  onCancel,
  onUploadError,
  onUploadSuccess,
  onRemove,
  onSubmit,
  textBeforeInput = '',
  textInputBox = '',
  textInputButton = '',
  textAfterInput = '',
  textBeforeFiles = '',
  textSubmit = '',
  textOverlay = '',
  errorTexts,
  fullscreenOnWindowDragEnter = false,
}) => {
  const [files, setFiles] = React.useState<FileWithMeta[]>([]);

  const fullscreenDropAreaRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (fileList) {
      setFiles(fileList);
    }
  }, [fileList]);

  const onWindowDragEnter = () => {
    if (!fullscreenDropAreaRef.current) return;
    fullscreenDropAreaRef.current.classList.add(DROP_AREA_CLASSNAME);
  };

  const onWindowDragStopped = () => {
    if (!fullscreenDropAreaRef.current) return;
    fullscreenDropAreaRef.current.classList.remove(DROP_AREA_CLASSNAME);
  };

  React.useEffect(() => {
    if (!fullscreenOnWindowDragEnter) return;
    window.addEventListener('dragenter', onWindowDragEnter);
    window.addEventListener('dragend', onWindowDragStopped);
    window.addEventListener('dragleave', onWindowDragStopped);
    window.addEventListener('drop', onWindowDragStopped);
    return () => {
      window.removeEventListener('dragenter', onWindowDragEnter);
      window.removeEventListener('dragend', onWindowDragStopped);
      window.removeEventListener('dragleave', onWindowDragStopped);
      window.removeEventListener('drop', onWindowDragStopped);
    };
  }, [fullscreenOnWindowDragEnter]);

  const isInputDisabled = disabled || files.length >= maxFiles;
  const isSubmitDisabled =
    disabled ||
    files.some((f) => f.status === FILE_STATUS.UPLOADING) ||
    !files.some((f) => f.status === FILE_STATUS.DONE);

  /**
   * A little helper function to update a file in the "files" state array.
   * @param id The ID of the file
   * @param data The data/properties you want to update on the file
   */
  const updateFileById = React.useCallback(
    (id: string, data: Partial<FileWithMeta>) => {
      setFiles((current) =>
        current.map((f) => {
          if (f.id === id) {
            return {
              ...f,
              ...data,
            };
          }
          return f;
        })
      );
    },
    []
  );

  /**
   * Function to handle all uploading the file.
   * @param file The file to upload
   */
  const uploadFile = React.useCallback(
    async (file: FileWithMeta) => {
      // Check for correct status
      if (file.status !== FILE_STATUS.UPLOADING) {
        return;
      }
      // Check for getUploadParams is present and working
      if (!getUploadParams) {
        return;
      }
      let params: IUploadParams | null = null;
      try {
        params = await getUploadParams(file);
      } catch (e) {
        console.error('FileUpload > uploadFile > getUploadParams', e.stack);
      }
      if (params === null) {
        return;
      }
      const { url, method = 'POST', body, fields = {}, headers = {} } = params;

      if (!url) {
        updateFileById(file.id, {
          status: FILE_STATUS.ERROR,
          error: 'invalid-upload-params',
        });
        return;
      }

      // Init the XMLHttpRequest
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      xhr.open(method, url, true);

      // Apply fields and headers
      for (const field of Object.keys(fields)) {
        formData.append(field, fields[field]);
      }
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      for (const header of Object.keys(headers)) {
        xhr.setRequestHeader(header, headers[header]);
      }

      // Progress indicator
      xhr.upload.addEventListener('progress', (e) => {
        updateFileById(file.id, {
          percent: (e.loaded * 100.0) / e.total || 100,
        });
      });

      // Listen for ready state
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      xhr.addEventListener('readystatechange', async () => {
        if (xhr.readyState !== 2 && xhr.readyState !== 4) return;

        // On cancel/abort:
        if (
          xhr.readyState === 4 &&
          xhr.status === 0 &&
          file.status !== FILE_STATUS.CANCELLED
        ) {
          updateFileById(file.id, {
            status: FILE_STATUS.CANCELLED,
            error: 'upload-cancelled',
          });

          if (typeof onCancel === 'function') {
            try {
              await onCancel(file);
            } catch {
              // noop
            }
          }
        }

        // On error:
        if (xhr.status >= 400 && file.status !== FILE_STATUS.ERROR) {
          updateFileById(file.id, {
            status: FILE_STATUS.ERROR,
            error: 'upload-failed',
          });

          if (typeof onUploadError === 'function') {
            try {
              await onUploadError(file);
            } catch (e) {
              console.error('FileUpload > uploadFile > readystatechange', e);
            }
          }
        }

        // On success:
        if (xhr.readyState === 4 && xhr.status > 0 && xhr.status < 400) {
          updateFileById(file.id, {
            status: FILE_STATUS.DONE,
            percent: 100,
            response: xhr.response,
          });

          if (typeof onUploadSuccess === 'function') {
            try {
              const code = await onUploadSuccess(file, xhr.response);
              if (typeof code === 'string' && code !== '') {
                updateFileById(file.id, {
                  status: FILE_STATUS.ERROR,
                  error: code,
                });
              }
            } catch {
              // noop
            }
          }
        }
      });

      // Add file to formdata
      formData.append(formDataFileKey, file.file);
      // Future: if timeout should be supported:
      // xhr.timeout = props.timeout;

      // Send/upload the file
      xhr.send(body || formData);

      // Update the file with xhr object
      updateFileById(file.id, {
        xhr,
      });
    },
    [
      formDataFileKey,
      getUploadParams,
      updateFileById,
      onCancel,
      onUploadError,
      onUploadSuccess,
    ]
  );

  /**
   * Callback for handling a single file and preparing for upload
   * @param file The file to upload
   * @param id The ID for the file
   */
  const handleFile = React.useCallback(
    (file: File, id: string, error?: FileError) => {
      const { name, size, type, lastModified } = file;

      const uploadedDate = new Date().toISOString();
      const lastModifiedDate =
        lastModified && new Date(lastModified).toISOString();

      const fileWithMeta = {
        id,
        file,
        name,
        size,
        type,
        uploadedDate,
        lastModifiedDate,
        status: error ? FILE_STATUS.ERROR : FILE_STATUS.UPLOADING,
        error: error ? error.code : undefined,
        percent: 0,
      } as FileWithMeta;

      setFiles((current) => [...current, fileWithMeta]);
      uploadFile(fileWithMeta);
    },
    [uploadFile]
  );

  /**
   * Callback for handling the "onDropAccepted" event
   */
  const onDropAccepted = React.useCallback(
    (acceptedFiles: File[]) => {
      [...acceptedFiles].forEach((f, i) => {
        handleFile(f, `${new Date().getTime()}-${i}`);
      });
    },
    [handleFile]
  );

  const onDropRejected = React.useCallback(
    (rejectedFiles: FileRejection[]) => {
      [...rejectedFiles].forEach((item, i) => {
        handleFile(item.file, `${new Date().getTime()}-${i}`, item.errors[0]);
      });
    },
    [handleFile]
  );

  /**
   * Callback for handling cancelling of a file upload
   * @param id The ID of the file
   */
  const handleCancel = React.useCallback(
    (id: string) => {
      const file = files.find((f) => f.id === id);
      if (!file || file.status !== FILE_STATUS.UPLOADING) {
        return;
      }
      if (file.xhr) {
        // This triggers the XHR "readystatechange" callback (cancel event) in the "uploadFile" function:
        file.xhr.abort();
      }
    },
    [files]
  );

  /**
   * Callback for handling removing of a file
   * @param id
   */
  const handleRemove = React.useCallback(
    async (id: string) => {
      const file = files.find((f) => f.id === id);
      if (!file) {
        return;
      }

      try {
        const isRemoved =
          typeof onRemove === 'function' ? await onRemove(file) : true;
        if (isRemoved) {
          setFiles((current) => current.filter((f) => f.id !== id));
        }
      } catch {
        // noop
      }
    },
    [files, onRemove]
  );

  /**
   * Callback for handling submitting the files
   */
  const handleSubmit = React.useCallback(async () => {
    if (typeof onSubmit === 'function') {
      try {
        await onSubmit(files.filter((f) => f.status === FILE_STATUS.DONE));
      } catch {
        // noop
      }
    }
    if (clearOnSubmit) {
      setFiles([]);
    }
  }, [files, onSubmit, clearOnSubmit]);

  /**
   * Callback for validator (undefined if not validate function is passed)
   */
  const validator = validate
    ? (f: File) => {
        const code = validate(f);
        return code === null ? null : { code, message: '' };
      }
    : undefined;

  /**
   * Init the react-dropzone hook
   */
  const {
    open: openDialog,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag,
    onDropAccepted,
    onDropRejected,
    validator,
    disabled: isInputDisabled,
    accept,
    minSize,
    maxSize,
    multiple,
    maxFiles: maxFiles - files.length,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <FullscreenDropArea
        isDragActive={isDragActive}
        ref={fullscreenDropAreaRef}
      />

      {textBeforeInput && (
        <TextBeforeInput>
          <Typography type="label" as="span">
            {textBeforeInput}
          </Typography>
        </TextBeforeInput>
      )}

      <Input
        disabled={isInputDisabled}
        openDialog={openDialog}
        textInputBox={textInputBox}
        textInputButton={textInputButton}
        textAfterInput={textAfterInput}
      />

      {isDragActive && <Overlay textOverlay={textOverlay} />}

      {files.length > 0 && (
        <>
          <UploadedFilesPreview>
            {textBeforeFiles && (
              <Typography type="labelSmall" as="h3">
                {textBeforeFiles}
              </Typography>
            )}
            {files.map((file, i) => (
              <Preview
                key={file.name + i}
                file={file}
                handleCancel={handleCancel}
                handleRemove={handleRemove}
                errorTexts={errorTexts}
              />
            ))}
          </UploadedFilesPreview>

          {!hideSubmitButton && (
            <SubmitButton
              disabled={isSubmitDisabled}
              handleSubmit={handleSubmit}
              textSubmit={textSubmit}
            />
          )}
        </>
      )}
    </div>
  );
};

const highZ = 100000;

type FullscreenDropAreaProps = {
  isDragActive: boolean;
};
const FullscreenDropArea = styled.div<FullscreenDropAreaProps>`
  position: fixed;
  z-index: ${({ isDragActive }) => (isDragActive ? highZ : '-1')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.${DROP_AREA_CLASSNAME} {
    z-index: ${highZ};
  }
`;

const TextBeforeInput = styled.div`
  margin-bottom: 16px;
`;

const UploadedFilesPreview = styled.div`
  margin-top: 20px;
`;
