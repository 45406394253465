import React from 'react';

const Check = () => (
  <svg viewBox="0 0 24 24" aria-hidden="true" width="1em" height="1em">
    <path
      d="M17.782 6.918l.753.659a.5.5 0 01.047.705L10.9 17.061a.5.5 0 01-.73.024l-4.232-4.231a.5.5 0 010-.708l.707-.707a.5.5 0 01.708 0l3.097 3.097 6.626-7.57a.5.5 0 01.705-.048z"
      fill="currentColor"
    />
  </svg>
);

export default Check;
