import React from 'react';

const ReviewPerformance = () => (
  <svg viewBox="0 0 1024 529.854" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M649.647 25.341q-1.152-2.304-3.456-4.608-2.303-2.303-5.759-3.455-32.252-9.215-65.08-13.247Q542.524 0 512.576 0 406.605 0 313.305 40.315 220.004 80.63 150.317 150.893T40.315 315.609Q0 408.909 0 516.031q0 5.76 4.031 9.791 4.032 4.032 9.791 4.032h176.234q5.76 0 9.791-4.032 4.032-4.031 4.032-9.791 0-63.352 26.492-122.096 27.645-57.593 72.567-100.788 44.923-43.194 104.819-67.383 59.897-25.341 125.552-23.037 3.456 1.152 6.336-.576 2.879-1.728 5.183-5.184L648.495 38.011q1.152-2.303 1.728-5.759.576-3.456-.576-6.911zM521.791 175.082q-66.808 0-127.856 26.493-62.2 26.492-109.427 71.415-47.226 44.922-76.022 103.667-28.796 59.896-32.252 125.552H27.645q2.303-99.059 41.466-185.449 39.163-86.389 104.819-150.893T327.127 64.504q86.389-36.859 185.449-36.859 24.189 0 50.106 2.879 25.916 2.88 52.409 8.639l-88.693 135.919H521.791zM746.403 57.593q-4.608-3.456-9.791-1.728-5.183 1.728-8.639 5.183l-179.69 270.687q-2.303 2.303-2.879 5.183-.576 2.88.576 6.335 0 2.304 1.728 4.608 1.727 2.303 4.031 3.455l34.556 21.885q1.152 1.152 3.455 1.728 2.304.576 3.456.576 1.152 0 2.304-.576 1.151-.576 2.303-.576 2.304-1.152 4.608-2.879 2.303-1.728 3.455-4.032L752.162 74.871q2.304-4.608.576-9.791-1.728-5.183-6.335-7.487zM564.409 427.339q-13.822-26.493-44.922-33.98-31.1-7.487-59.896 10.942-3.456 2.304-6.912 5.76-3.455 3.455-5.759 6.911-11.518 18.43-12.67 38.587-1.152 20.158 8.063 37.435 9.215 17.278 25.341 27.069t35.707 9.791q11.519 0 22.461-3.456 10.943-3.455 21.31-10.367 3.455-2.303 6.911-5.759 3.455-3.455 5.759-6.911 11.519-18.43 12.67-38.587 1.152-20.158-8.063-37.435zm274.142-308.698q-2.304-2.304-5.183-2.879-2.88-.576-6.335.575-3.456 0-5.76 1.728-2.303 1.728-3.455 5.184l-86.389 167.019q-1.152 4.607-.576 8.639.576 4.031 2.879 7.487 41.467 44.922 63.928 99.059 22.461 54.138 22.461 110.578 0 5.76 4.032 9.791 4.031 4.032 9.791 4.032h176.234q5.759 0 9.791-4.032 4.031-4.031 4.031-9.791 0-57.592-12.67-112.881-12.671-55.289-36.284-106.547-23.613-51.258-58.169-96.18-34.555-44.922-78.326-81.782z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default ReviewPerformance;
