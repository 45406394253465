import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../../Icons';

type SearchFieldButtonProps = {
  onClick: Function;
};
export const SearchFieldButton: React.FC<SearchFieldButtonProps> = ({
  onClick,
}) => {
  const onButtonClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (onClick) onClick(e);
    },
    [onClick]
  );
  return (
    <ButtonContainer onClick={onButtonClick}>
      <Icons.Action.Search />
    </ButtonContainer>
  );
};
const ButtonContainer = styled.a`
  cursor: pointer;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
`;
