import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { BoxShadow } from '../../Foundation/BoxShadow';
import { Color } from '../../Foundation/Color';
import { Gradient } from '../../Foundation/Gradient';
import { RddkTheme } from '../../Foundation/Theme';
import { Typography } from '../../Foundation/Typography';
import { IconWrapper } from '../../Icons';
import { Flex } from '../../Layout/FlexBox';
import { EditButton } from '../Button';

export type SummaryCardProps = {
  title: string;
  icon: ReactNode;
  onEditClick: () => void;
  data: string[];
  dataWithImg?: {
    label: string;
    subLabel: string;
    imgSrc: string;
  };
};

export const SummaryCard: FC<SummaryCardProps> = ({
  title,
  icon,
  onEditClick,
  data,
  dataWithImg,
}) => {
  return (
    <StyledCardWrapper>
      <StyledCardHeader>
        <StyledCardHeaderItem>
          <IconWrapper>{icon}</IconWrapper>
        </StyledCardHeaderItem>
        <StyledCardHeaderItem>
          <Typography type="h7">{title}</Typography>
        </StyledCardHeaderItem>
        <StyledCardHeaderItem>
          <EditButton onClick={onEditClick} />
        </StyledCardHeaderItem>
      </StyledCardHeader>
      <StyledCardContent>
        {data.map((item) => (
          <Typography
            key={item}
            type="none"
            display="block"
            pb="2"
            color={Color.rdBlackTint2}
          >
            {item}
          </Typography>
        ))}
        {dataWithImg && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mx={`${`-${RddkTheme.space[2]}px`}`}
            pt={4}
          >
            <StyledTextWrapper>
              <Typography type="none" fontWeight={600} pb="2">
                {dataWithImg.label}
              </Typography>
              <Typography type="none" pb="2" color={Color.rdBlackTint2}>
                {dataWithImg.subLabel}
              </Typography>
            </StyledTextWrapper>
            <StyledImgWrapper>
              <StyledImg src={dataWithImg.imgSrc} alt={dataWithImg.subLabel} />
            </StyledImgWrapper>
          </Flex>
        )}
      </StyledCardContent>
    </StyledCardWrapper>
  );
};

const StyledCardWrapper = styled.div`
  box-shadow: ${BoxShadow.grey};
  border-radius: 5px;
  background-color: ${Color.rdBrightWhite};
`;

const StyledCardContent = styled.div`
  padding: ${`${RddkTheme.space[4]}px`} ${`${RddkTheme.space[5]}px`};
`;

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${`${RddkTheme.space[4]}px`} ${`${RddkTheme.space[5]}px`};
  margin: 0 ${`-${RddkTheme.space[2]}px`} ${`${RddkTheme.space[2]}px`};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${`${RddkTheme.space[2]}px`};
    width: calc(100% - ${`${RddkTheme.space[4]}px`});
    height: 3px;
    background: ${Gradient.darkGrey};
  }
  > div:last-child {
    margin-left: auto;
  }
`;

const StyledCardHeaderItem = styled.div`
  display: flex;
  padding: 0 ${`${RddkTheme.space[2]}px`};
`;

const StyledTextWrapper = styled.div`
  padding: 0 ${`${RddkTheme.space[2]}px`};
  display: flex;
  flex-direction: column;
`;

const StyledImgWrapper = styled.div`
  padding: 0 ${`${RddkTheme.space[2]}px`};
`;

const StyledImg = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  object-fit: cover;
`;
