import styled from 'styled-components';
import { Color, RddkTheme } from '../../Foundation';

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0 0.2rem;
  border-collapse: separate;
  font-family: ${RddkTheme.fonts.sans};
`;

export const SortableHeader = styled.th<{ $width?: string }>`
  cursor: pointer;
  width: ${(props) => props.$width};
`;

export const NonSortableHeader = styled.th<{ $width?: string }>`
  width: ${(props) => props.$width};
`;

export const StyledRow = styled.tr<{ $cursorPointer?: boolean }>`
  background-color: #fafafa;
  height: 3rem;
  cursor: ${(props) => (props.$cursorPointer ? 'pointer' : 'default')};

  td:first-child {
    border-radius: 4px 0 0 4px;
    padding-left: 0.5rem;
  }

  td:last-child {
    border-radius: 0 4px 4px 0;
    padding-right: 0.5rem;
  }
`;

export const LoadingRow = styled.tr`
  td {
    height: 3rem;
  }
`;

export const HeaderCell = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: ${Color.grey60};
  user-select: none;
`;

export const IconWrapper = styled.span`
  display: flex;

  &.flipped {
    transform: rotate(180deg);
  }
`;
