import React from 'react';

const SignAgreement = () => (
  <svg viewBox="0 0 1024 766.166" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M181.322 400.377h277.749q5.24 0 8.909-3.669 3.668-3.668 3.668-8.909 0-5.24-3.668-8.909-3.669-3.668-8.909-3.668H181.322q-5.24 0-8.908 3.668-3.669 3.669-3.669 8.909 0 5.241 3.669 8.909 3.668 3.669 8.908 3.669zm277.749 39.828H181.322q-5.24 0-8.908 3.668-3.669 3.668-3.669 8.909 0 5.241 3.669 8.909 3.668 3.668 8.908 3.668h277.749q5.24 0 8.909-3.668 3.668-3.668 3.668-8.909 0-5.241-3.668-8.909-3.669-3.668-8.909-3.668zm0-128.917H181.322q-5.24 0-8.908 3.668-3.669 3.668-3.669 8.909 0 5.24 3.669 8.909 3.668 3.668 8.908 3.668h277.749q5.24 0 8.909-3.668 3.668-3.669 3.668-8.909 0-5.241-3.668-8.909-3.669-3.668-8.909-3.668zm539.774 371.029H619.431q0 12.578-8.909 21.486-8.909 8.909-21.486 8.909H422.387q-12.577 0-21.486-8.909-8.909-8.908-8.909-21.486h1.048H12.577q-5.24 0-8.909 3.669Q0 689.654 0 694.895v33.539q0 15.722 11.005 26.727t26.727 11.005h935.959q15.721 0 26.727-11.005 11.005-11.005 11.005-26.727v-33.539q0-5.241-3.669-8.909-3.668-3.669-8.909-3.669zm22.011-558.64L900.323 3.144Q897.179 0 891.939 0q-5.241 0-9.433 3.144v1.048l-81.753 81.753H140.446q-15.721 0-26.726 11.005-11.006 11.005-11.006 26.727v467.455q0 15.722 11.006 26.727 11.005 11.005 26.726 11.005h730.53q15.722 0 26.727-11.005t11.005-26.727V252.594l112.148-111.1 2.096-4.192q1.048-2.096 1.048-5.241 0-2.096-1.048-4.716t-2.096-3.668zM891.939 30.395l41.924 41.924-366.837 366.838-41.925-41.925L891.939 30.395zM596.373 504.139h-33.54l-42.972-42.972v-33.54l76.512 76.512zm287.181 86.993q0 5.241-3.669 8.909-3.668 3.668-8.909 3.668h-730.53q-5.24 0-8.909-3.668-3.668-3.668-3.668-8.909V123.677q0-5.241 3.668-8.909 3.669-3.669 8.909-3.669h634.105l-31.444 32.492-45.068 44.02-24.107 25.155-107.955 106.906-26.202 25.155h1.048l-42.972 42.972q0 1.048-1.049 2.097-1.048 1.048-1.048 2.096-1.048 1.048-1.048 2.096v122.628q0 5.241 3.669 8.909 3.668 3.669 8.909 3.669h119.484q1.048 0 2.62-.524t2.62-.524q1.048 0 2.096-1.048l2.097-2.097 247.353-247.353v313.384zm-256.786-92.233l-41.925-41.925L951.681 90.137l41.924 41.924-366.837 366.838z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default SignAgreement;
