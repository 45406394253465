import React from 'react';

/** @deprecated Use Icons.Action.Sort instead.*/
const Middle = () => (
  <svg viewBox="0 0 14 12" width="1em" height="1em">
    <g fill="#000" fillRule="evenodd">
      <rect width={2} height={12} x={8} rx={0.5} />
      <rect width={2} height={6} x={4} y={6} rx={0.5} />
      <rect width={2} height={8} y={4} rx={0.5} />
      <rect width={2} height={10} x={12} y={2} rx={0.5} />
    </g>
  </svg>
);

export default Middle;
