import React from 'react';

const Search = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M51.62 397.355q0-72.028 27.611-134.452 26.41-62.425 73.829-109.843 47.418-47.419 109.843-73.829Q325.327 51.62 397.355 51.62q72.028 0 134.453 27.611 62.424 26.41 109.843 73.829 47.418 47.418 73.828 109.843 27.611 62.424 27.611 134.452 0 72.028-27.611 134.453-26.41 62.424-73.828 109.843-47.419 47.418-109.843 73.828-62.425 27.611-134.453 27.611t-134.452-27.611q-62.425-26.41-109.843-73.828-47.419-47.419-73.829-109.843Q51.62 469.383 51.62 397.355zm965.177 489.792l-282.11-280.91q27.611-45.618 43.817-98.439 16.206-52.82 16.206-110.443 0-81.632-31.212-154.86-31.212-72.028-85.233-126.05-54.021-54.021-126.049-85.233Q478.987 0 397.355 0q-81.632 0-154.86 31.212-72.028 31.212-126.05 85.233-54.021 54.022-85.233 126.05Q0 315.723 0 397.355q0 81.632 31.212 154.861 31.212 72.028 85.233 126.049 54.022 54.021 126.05 85.233 73.228 31.212 154.86 31.212 56.422 0 109.243-15.606t97.238-43.217l282.11 280.91q7.203 7.203 18.007 7.203 10.804 0 18.007-7.203l94.837-93.636q7.203-7.203 7.203-18.007 0-10.805-7.203-18.007z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Search;
