import React from 'react';

const LotSize = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <mask
      id="svg-lotsize-a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <path
        d="M3 22a1 1 0 01-.993-.883L2 21V3a1 1 0 01.883-.993L3 2h18a1 1 0 01.993.883L22 3v18a1 1 0 01-.883.993L21 22H3zM21 3L3 2.999V21h18V3zm-6.5 2H18a1 1 0 01.993.883L19 6v3.5a.5.5 0 01-1 0l-.001-2.793L6.706 18H9.5a.5.5 0 010 1H6a1 1 0 01-.993-.883L5 18v-3.5a.5.5 0 011 0v2.792L17.292 6H14.5a.5.5 0 010-1z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#svg-lotsize-a)">
      <path
        d="M3 22a1 1 0 01-.993-.883L2 21V3a1 1 0 01.883-.993L3 2h18a1 1 0 01.993.883L22 3v18a1 1 0 01-.883.993L21 22H3zM21 3L3 2.999V21h18V3zm-6.5 2H18a1 1 0 01.993.883L19 6v3.5a.5.5 0 01-1 0l-.001-2.793L6.706 18H9.5a.5.5 0 010 1H6a1 1 0 01-.993-.883L5 18v-3.5a.5.5 0 011 0v2.792L17.292 6H14.5a.5.5 0 010-1z"
        fill="#000"
      />
    </g>
  </svg>
);

export default LotSize;
