import React from 'react';

const HealthReports = () => (
  <svg viewBox="0 0 770.243 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M754.864 96.12h-80.741V15.379q0-6.408-4.486-10.893Q665.152 0 658.744 0H143.539l-1.281 1.282h-1.282q-2.563 0-4.485 1.281-1.923 1.282-3.204 2.563L5.126 133.287q-2.563 1.281-3.204 3.204-.64 1.922-.64 4.485-1.282 0-1.282.641V912.501q0 6.408 4.486 10.893 4.485 4.486 10.893 4.486H96.12v80.741q0 6.408 4.486 10.894 4.485 4.485 10.893 4.485h643.365q6.408 0 10.893-4.485 4.486-4.486 4.486-10.894V111.499q0-6.408-4.486-10.893-4.485-4.486-10.893-4.486zm-15.379 897.122H126.879V927.88h531.865q6.408 0 10.893-4.486 4.486-4.485 4.486-10.893V126.879h65.362v866.363zM30.758 160.2h114.063q6.408 0 10.894-4.485 4.485-4.486 4.485-10.894V30.758h483.164v866.364H30.758V160.2zm114.063 126.879H528.02q6.408 0 10.894-4.486 4.485-4.485 4.485-10.893t-4.485-10.894q-4.486-4.486-10.894-4.486H144.821q-6.408 0-10.894 4.486-4.485 4.486-4.485 10.894t4.485 10.893q4.486 4.486 10.894 4.486zm0 140.976H528.02q6.408 0 10.894-4.485 4.485-4.486 4.485-10.894t-4.485-10.894q-4.486-4.485-10.894-4.485H144.821q-6.408 0-10.894 4.485-4.485 4.486-4.485 10.894t4.485 10.894q4.486 4.485 10.894 4.485zm0 139.695H528.02q6.408 0 10.894-4.486 4.485-4.485 4.485-10.893t-4.485-10.894q-4.486-4.486-10.894-4.486H144.821q-6.408 0-10.894 4.486-4.485 4.486-4.485 10.894t4.485 10.893q4.486 4.486 10.894 4.486zm0 140.976h206.338q6.408 0 10.894-4.486 4.485-4.485 4.485-10.893t-4.485-10.894q-4.486-4.485-10.894-4.485H144.821q-6.408 0-10.894 4.485-4.485 4.486-4.485 10.894t4.485 10.893q4.486 4.486 10.894 4.486z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default HealthReports;
