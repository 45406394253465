import React from 'react';

const House = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <path
      d="M21.375 9.465l-1.924-1.596V3.544a.502.502 0 00-.143-.373.513.513 0 00-.377-.141h-3.2a.559.559 0 00-.39.141.48.48 0 00-.155.373v.85l-2.86-2.317a.724.724 0 00-.651 0l-9.05 7.388a.785.785 0 00-.143.206.495.495 0 00-.065.205v11.61c0 .154.047.278.143.372a.513.513 0 00.377.142h18.126a.513.513 0 00.377-.142.502.502 0 00.143-.373V9.876a.41.41 0 00-.065-.244 1.326 1.326 0 00-.143-.167zm-.858 11.48h-5.851v-6.847a.546.546 0 00-.143-.386c-.096-.103-.23-.155-.403-.155H9.88c-.173 0-.307.052-.403.155a.547.547 0 00-.143.386v6.847H3.483V10.082l8.53-6.95 3.407 2.729c.052.051.139.082.26.09.121.009.208.013.26.013a.741.741 0 00.234-.167.36.36 0 00.078-.245V4.085h2.133v4.118a.41.41 0 00.065.245c.043.06.09.116.143.167l1.924 1.467v10.863z"
      fill="#000"
    />
  </svg>
);

export default House;
