import React from 'react';
import {
  Container,
  RelativeRow,
  StyledTable,
  StyledThead,
  Wrapper,
} from './components';
import { TableCell } from './TableCell';
import { DynamicTableProps, State } from './types';
import { getNextSortDirection, getSortedRows, reducer } from './utils';
import { useStickyHeader } from './useStickyHeader';

/**
 * Component for rendering a table.
 * Data for columns and rows are passed as an array of arrays containing objects to the "data" property.
 */
export const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  striped,
  bordered,
  firstRowIsHead,
  firstColumnIsSticky,
  tableHeaderIsSticky,
  className,
  isFromDialog,
}) => {
  // Check if table has table header row
  const theadRow = firstRowIsHead && data.length > 0 && data[0];
  // Count number of head rows to skip
  const skipHeadRows = theadRow ? 1 : 0;

  // Memoize the initial state for the reducer
  const initialState: State = React.useMemo(
    () => ({
      sortColumnIndex: -1,
      sortDirection: null,
      sortedRows: data.map((_, i) => i),
    }),
    [data]
  );
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /**
   * Callback for clicking a sortable header
   */
  const onSortByColumn = React.useCallback(
    (columnIndex: number) => () => {
      const nextDirection =
        columnIndex === state.sortColumnIndex
          ? getNextSortDirection(state.sortDirection)
          : getNextSortDirection(null);

      if (nextDirection === null) {
        dispatch({
          type: 'reset',
          payload: { state: initialState },
        });
      } else if (columnIndex < data.length) {
        dispatch({
          type: 'set_sorting',
          payload: {
            sortColumnIndex: columnIndex,
            sortDirection: nextDirection,
            sortedRows: getSortedRows(
              data,
              skipHeadRows,
              columnIndex,
              nextDirection
            ),
          },
        });
      }
    },
    [initialState, state, data, skipHeadRows]
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const originalHeadRowRef = React.useRef<HTMLTableRowElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { showFixed, left, widths, containerWidth } = useStickyHeader(
    tableHeaderIsSticky || false,
    containerRef,
    wrapperRef,
    originalHeadRowRef,
    isFromDialog || false
  );
  const fixedTableHead = React.useMemo(
    () =>
      theadRow &&
      showFixed && (
        <StyledThead
          isHeaderSticky={tableHeaderIsSticky}
          width={containerWidth}
        >
          <RelativeRow left={left}>
            {theadRow.map((cell, i) => {
              const onClick = cell.sortable ? onSortByColumn(i) : undefined;
              const isSorting = state.sortColumnIndex === i;
              return (
                <TableCell
                  key={`cell_0_${i}`}
                  tag="th"
                  cell={{ ...cell, width: widths[i] }}
                  rowIndex={0}
                  columnIndex={i}
                  onClick={onClick}
                  isSorting={isSorting}
                  sortDirection={state.sortDirection}
                />
              );
            })}
          </RelativeRow>
        </StyledThead>
      ),
    [showFixed, widths, left]
  );

  const fixedTableHeadSticky = React.useMemo(
    () =>
      theadRow &&
      showFixed && (
        <StyledThead
          isHeaderSticky={tableHeaderIsSticky}
          width={containerWidth}
        >
          <RelativeRow>
            {theadRow.map((cell, i) => {
              const onClick = cell.sortable ? onSortByColumn(i) : undefined;
              const isSorting = state.sortColumnIndex === i;
              return (
                <TableCell
                  key={`cell_0_${i}`}
                  tag="th"
                  cell={{ ...cell, width: widths[i] }}
                  rowIndex={0}
                  columnIndex={i}
                  onClick={onClick}
                  isSorting={isSorting}
                  sortDirection={state.sortDirection}
                />
              );
            })}
          </RelativeRow>
        </StyledThead>
      ),
    [showFixed, widths, left]
  );

  /**
   * Render the table content and memoize it
   */
  const tableContent = React.useMemo(
    () => (
      <>
        {theadRow && (
          <StyledThead>
            <tr ref={originalHeadRowRef}>
              {theadRow.map((cell, i) => {
                const onClick = cell.sortable ? onSortByColumn(i) : undefined;
                const isSorting = state.sortColumnIndex === i;
                return (
                  <TableCell
                    key={`cell_0_${i}`}
                    tag="th"
                    cell={cell}
                    rowIndex={0}
                    columnIndex={i}
                    onClick={onClick}
                    isSorting={isSorting}
                    sortDirection={state.sortDirection}
                  />
                );
              })}
            </tr>
          </StyledThead>
        )}

        <tbody>
          {state.sortedRows.slice(skipHeadRows).map((rowKey, rowIndex) => {
            const row = data[rowKey];
            if (!row) {
              return null;
            }

            const _rowIndex = rowIndex + skipHeadRows;
            const rowClass = rowIndex % 2 ? 'even' : 'odd';
            return (
              <tr key={`row_${_rowIndex}`} className={rowClass}>
                {row.map((cell, i) => {
                  return (
                    <TableCell
                      key={`cell_${_rowIndex}_${i}`}
                      tag="td"
                      cell={cell}
                      rowIndex={_rowIndex}
                      columnIndex={i}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    ),
    [data, state, theadRow, skipHeadRows, onSortByColumn, tableHeaderIsSticky]
  );

  return (
    <Container bordered={bordered} className={className} ref={containerRef}>
      <Wrapper ref={wrapperRef}>
        <StyledTable striped={striped}>
          {fixedTableHead}
          {tableContent}
        </StyledTable>
      </Wrapper>

      {firstColumnIsSticky && (
        <StyledTable striped={striped} clone>
          {fixedTableHeadSticky}
          {tableContent}
        </StyledTable>
      )}
    </Container>
  );
};
