import React from 'react';
import styled from 'styled-components';
import { Container, Row } from '../../Layout/Grid';

type BarProps = {
  children?: any;
  onClearFocus: () => void;
  style?: any;
};

export const Bar: React.FC<BarProps> = ({ children, onClearFocus, style }) => {
  return (
    <Background onClick={onClearFocus}>
      <Container>
        <Row style={style}>{children}</Row>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: relative;
  z-index: 5000;
  height: 70px;
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
`;

// const Row = styled.div`
//   /* Clearfix */
//   ::after {
//     content: '';
//     clear: both;
//     display: table;
//   }
// `;
