import React from 'react';

const Question = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M10 0c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0zm0 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm.5 13v2H8.3v-2h2.2zm-.6-9.5c2.5 0 4.1.5 4.1 3.1 0 1.8-.5 2.7-2.4 3.4-1.2.5-1.4.6-1.5 1.3v.6H8.5v-.6c-.1-1.3.3-1.9 2.3-2.6 1.2-.5 1.4-.9 1.4-1.8 0-1.5-.5-1.7-2.2-1.7-1.8 0-2.1.3-2.2 1v.5H6c0-.3-.1-.7-.1-1 0-1.2.8-2.2 4-2.2z"
        id="svg-question-a"
      />
    </defs>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <mask id="svg-question-b" fill="#fff">
        <use xlinkHref="#svg-question-a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#svg-question-a" />
      <g mask="url(#svg-question-b)">
        <path fill="currentColor" d="M-2-2v24h24V-2z" />
      </g>
    </g>
  </svg>
);

export default Question;
