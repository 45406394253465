import React from 'react';

const Home = () => (
  <svg viewBox="0 0 971.284 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M960.741 382.188l-97.524-81.709V79.073q0-11.861-7.248-19.109-7.249-7.248-19.11-7.248h-162.1q-11.861 0-19.768 7.248-7.908 7.248-7.908 19.109v43.491L502.116 3.954Q494.208 0 485.642 0q-8.566 0-16.474 3.954L10.543 382.188q-3.954 3.953-7.248 10.543Q0 399.32 0 403.274v594.368q0 11.861 7.248 19.109 7.249 7.249 19.11 7.249h918.568q11.861 0 19.11-7.249 7.248-7.248 7.248-19.109V403.274q0-7.907-3.295-12.52-3.294-4.613-7.248-8.566zm-43.49 587.778H620.726V619.408q0-11.861-7.249-19.769-7.248-7.907-20.427-7.907H378.234q-13.179 0-20.427 7.907-7.249 7.908-7.249 19.769v350.558H54.033V413.817l432.268-355.83 172.643 139.696q3.954 3.954 13.179 4.613 9.225.659 13.179.659 7.907-3.954 11.861-8.566 3.954-4.613 3.954-12.52v-75.12h108.067v210.862q0 7.908 3.294 12.52 3.295 4.613 7.249 8.566l97.524 75.12v556.149z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Home;
