import React from 'react';

/** @deprecated Use Icons.Illustration.FinanceCenter instead.*/
const HouseUpgrade = () => (
  <svg viewBox="0 0 48 48" width="1em" height="1em">
    <path
      d="M36.256 34.327h2.77v-2.704h-2.77v2.704zm3.129.7h-3.488a.353.353 0 01-.358-.35v-3.404c0-.194.16-.35.358-.35h3.488c.198 0 .359.156.359.35v3.404c0 .194-.161.35-.36.35zM15.436 24.564c.199 0 .36.156.36.35v1.352c0 .194-.161.35-.36.35h-1.385a.354.354 0 01-.359-.35v-1.353c0-.194.161-.35.36-.35h1.384zm0 3.204h-1.385a.355.355 0 00-.359.35v1.353c0 .193.161.35.36.35h1.384c.199 0 .36-.157.36-.35v-1.353a.355.355 0 00-.36-.35m3.333-3.204h-1.385a.354.354 0 00-.359.35v1.352c0 .194.161.35.36.35h1.384c.199 0 .36-.156.36-.35v-1.353a.355.355 0 00-.36-.35m0 3.204h-1.385a.355.355 0 00-.359.35v1.353c0 .193.161.35.36.35h1.384c.199 0 .36-.157.36-.35v-1.353a.355.355 0 00-.36-.35m3.334-3.204h-1.385a.355.355 0 00-.36.35v1.352c0 .194.162.35.36.35h1.385a.354.354 0 00.359-.35v-1.352c0-.194-.16-.35-.36-.35m0 3.204h-1.384a.356.356 0 00-.36.35v1.353c0 .193.162.35.36.35h1.385a.355.355 0 00.359-.35v-1.353c0-.193-.16-.35-.36-.35m3.283-3.204H24a.355.355 0 00-.36.35v1.352c0 .194.161.35.36.35h1.385a.354.354 0 00.359-.35v-1.352a.355.355 0 00-.36-.35m3.334 0h-1.385a.354.354 0 00-.359.35v1.352c0 .194.16.35.36.35h1.384c.198 0 .36-.156.36-.35v-1.352a.355.355 0 00-.36-.35m-18.305-2.855l3.867-5.006h14.207l3.869 5.006H10.413zm12.51 10.966v-.7H11.487v-9.566h19.795v5.608H32V22.41h1.077a.36.36 0 00.321-.194.343.343 0 00-.035-.367l-4.41-5.709a.36.36 0 00-.286-.14H14.103a.361.361 0 00-.287.14l-4.41 5.708a.344.344 0 00-.035.367c.061.12.185.194.321.194h1.078v9.564H9.282c-.594 0-1.077.472-1.077 1.052v1.301H6.359a.356.356 0 00-.359.35c0 .194.16.352.359.352h16.564v-.702h-14v-1.301c0-.194.161-.35.36-.35h13.64zm1.436 3.856h16.923v-7.11H24.36v7.11zm17.282.7H24a.354.354 0 01-.359-.35v-7.81c0-.194.16-.351.359-.351h17.641c.198 0 .359.157.359.35v7.811c0 .194-.16.35-.359.35zm-8-6.308h-7.385a.355.355 0 00-.359.35v3.405c0 .194.16.35.36.35h7.384a.354.354 0 00.359-.35v-3.405c0-.193-.16-.35-.359-.35"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default HouseUpgrade;
