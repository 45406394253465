import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Icons } from '../../Icons';
import { Container, mediaQuery, Visible } from '../../Layout';
import { Button } from '../Button/Button';
import { LinkItem } from './types';

type MobileMenuProps = {
  menuLinks: LinkItem[];
  loginLinks: LinkItem[];
  ctaLink?: string;
  ctaText?: string;
  showMobileMenu: boolean;
  showOverlay: boolean;
  onLoginFocus: () => void;
  onParentFocus: (index: number) => void;
  hasTopStrip?: boolean;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
  menuLinks,
  loginLinks,
  ctaLink,
  ctaText,
  showMobileMenu,
  showOverlay,
  onLoginFocus,
  onParentFocus,
  hasTopStrip,
}) => {
  /**
   * Callback for handling click events on the menu items.
   * It will call the "onParentFocus" callback with the index provided.
   * It will also prevent default events to happen.
   */
  const onLinkClickHandler = useCallback(
    (index: number) => (e: React.SyntheticEvent) => {
      e.preventDefault();
      onParentFocus(index);
    },
    [onParentFocus]
  );

  /**
   * Callback for handling click events on the login menu item.
   * It will call the "onLoginFocus" callback.
   * It will also prevent default events to happen.
   */
  const onLoginClickHandler = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      onLoginFocus();
    },
    [onLoginFocus]
  );

  const showCtaButton = !!ctaLink && !!ctaText;

  return (
    <Visible xs sm md>
      <Wrapper active={showMobileMenu} hasTopStrip={hasTopStrip}>
        <Content dimmed={showOverlay}>
          <Container>
            {menuLinks.map((link, i) => {
              return (
                <LinkButton key={`link${i}`} onClick={onLinkClickHandler(i)}>
                  <Title>{link.name}</Title>
                  <ArrowIcon>
                    <Icons.ArrowRight />
                  </ArrowIcon>
                </LinkButton>
              );
            })}

            <LoginLink>
              {loginLinks.map((link, i) => {
                return (
                  <LinkButton key={`link${i}`} onClick={onLoginClickHandler}>
                    <span>
                      <UserIcon>
                        <Icons.Customer />
                      </UserIcon>
                      <Title>{link.name}</Title>
                    </span>
                    <ArrowIcon>
                      <Icons.ArrowRight />
                    </ArrowIcon>
                  </LinkButton>
                );
              })}
            </LoginLink>
          </Container>

          {showCtaButton && (
            <Bottom>
              <Container>
                <StyledButton variant="primary" href={ctaLink}>
                  {ctaText}
                </StyledButton>
              </Container>
            </Bottom>
          )}
        </Content>
      </Wrapper>
    </Visible>
  );
};

const Wrapper = styled.div<{ active?: boolean; hasTopStrip?: boolean }>`
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  min-height: 520px;
  background: #fff;
  padding-top: ${(props) => (props.hasTopStrip ? '125px' : '60px')};
  box-shadow: ${(props) =>
    props.active ? '0 2px 24px 0 rgba(0, 0, 0, 0.11)' : 'none'};
  transform: translateY(${(props) => (props.active ? '0%' : '-100%')});
  transition: transform 0.6s;
`;

const Content = styled.div<{ dimmed?: boolean }>`
  position: relative;
  height: 100%;
  left: ${(props) => (props.dimmed ? '-15px' : '0')};
  opacity: ${(props) => (props.dimmed ? '0.25' : '1')};
  transition: all 0.5s;
`;

const LinkButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  margin: 0;
  border: 0;
  border-radius: 0;
  width: 100%;
  appearance: none;
  background: transparent;
  outline: none;
`;

const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  text-decoration: none;

  ${mediaQuery.sm} {
    font-size: 27px;
  }
`;

const UserIcon = styled.span`
  svg {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
`;

const ArrowIcon = styled.span`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 4px;
  }
`;

const LoginLink = styled.div`
  margin-top: 20px;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 30px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  justify-content: center;
`;
