import React from 'react';

const Profile = () => (
  <svg viewBox="0 0 818.95 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M161.289 247.56q0-51.262 20.005-96.273 18.755-45.011 52.513-78.769 33.758-33.759 78.769-53.763Q357.587 0 410.1 0q51.263 0 96.274 18.755 45.011 20.004 78.769 53.763 33.758 33.758 53.763 78.769 18.755 45.011 18.755 96.273 0 52.513-18.755 97.524-20.005 45.011-53.763 78.77-33.758 33.758-78.769 52.512-45.011 20.005-96.274 20.005-52.513 0-97.524-20.005-45.011-18.754-78.769-52.512-33.758-33.759-52.513-78.77-20.005-45.011-20.005-97.524zM410.1 445.109q81.27 0 138.784-57.514 57.514-57.514 57.514-140.035 0-81.269-57.514-138.783Q491.37 51.263 410.1 51.263q-82.52 0-140.034 57.514-57.514 57.514-57.514 138.783 0 82.521 57.514 140.035 57.514 57.514 140.034 57.514zm408.85 256.312v296.323q0 11.252-7.502 18.754-7.502 7.502-17.504 7.502H691.419 25.006q-10.002 0-17.504-7.502Q0 1008.996 0 997.744V701.421q0-86.271 60.015-138.784 60.014-52.512 95.023-71.267 15.004-7.502 29.382-13.753 14.379-6.252 29.382-11.253 38.76 33.758 89.397 51.262 50.637 17.505 106.901 17.505 55.014 0 105.026-17.505 50.012-17.504 90.022-51.262 15.003 5.001 29.382 11.253 14.378 6.251 29.382 13.753 35.009 18.755 95.023 71.267 60.015 52.513 60.015 138.784zm-51.263 15.004q0-63.766-41.26-116.278-41.26-52.513-112.527-77.519-33.758 25.006-95.023 41.885-61.265 16.879-108.777 16.879-48.762 0-110.027-16.879t-95.023-41.885q-58.764 16.254-106.276 73.142-47.511 56.889-47.511 120.655v237.558q0 7.502 5.001 13.128 5.001 5.627 15.003 5.627h676.416q8.752 0 14.378-5.627 5.626-5.626 5.626-13.128z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Profile;
