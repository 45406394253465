import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from '../../Foundation/Hooks/useWindowSize';
import { Backdrop } from './Backdrop';
import { Bar } from './Bar';
import { constants } from './constants';
import { DesktopLoginDropdown } from './DesktopLoginDropdown';
import { DesktopLoginTopLevel } from './DesktopLoginTopLevel';
import { DesktopNavigationDropdown } from './DesktopNavigationDropdown';
import { DesktopNavigationTopLevel } from './DesktopNavigationTopLevel';
import { Logo } from './Logo';
import { MobileHamburgerButton } from './MobileHamburgerButton';
import { MobileMenu } from './MobileMenu';
import { MobileMenuOverlay } from './MobileMenuOverlay';
import { TopStrip } from './TopStrip';
import { LinkItem } from './types';
import type {
  SearchResultsType,
  SearchResultType,
  SearchLabels,
  LoadMoreResultsHandler,
} from './Search/types';
import { SearchModal } from './Search/SearchModal';
import { SearchIcon } from './Search/SearchIcon';
import styled from 'styled-components';
import { mediaQuery } from '../../Layout';

export type HeaderProps = {
  /**
   * Array of links that should be displayed in the top bar
   */
  areaLinks?: LinkItem[];
  /**
   * Array of links that should be displayed in the main navigation
   */
  menuLinks?: LinkItem[];
  /**
   * Array of links that should be displayed for the login dropdown.
   * Note that only one top-level link is supported, and links in the dropdown
   * should be placed as children links.
   */
  loginLinks?: LinkItem[];
  /**
   * Override the RD logo with a custom image path.
   * If provided it the image path will be inserted into a <img> tag.
   */
  logoImage?: string;
  /**
   * The link target when clicking on the logo.
   */
  logoLink?: string;
  /**
   * The link target when clicking on the "Back link" in the top bar.
   * If not provided, then the link will not show.
   */
  backLink?: string;
  /**
   * The link text when clicking the "Back link" in the top bar.
   * If not provided, then the link will not show.
   */
  backText?: string;
  /**
   * Any content element (ReactNode, string etc) can be displayed.
   * It should be an <a> tag with link to feedback page.
   */
  feedbackContent?: any;
  /**
   * The link target for the CTA button in the bottom of the mobile menu.
   * If not provided, then the link will not show.
   */
  mobileCtaLink?: string;
  /**
   * The link text for the CTA button in the bottom of the mobile menu.
   * If not provided, then the link will not show.
   */
  mobileCtaText?: string;
  /**
   *to enable or disable topstrip in the header.
   * If not provided, then the top strip is always shown.
   */
  hasTopStrip?: boolean;
  /**
   * to enable or disable search button in the header.
   * If not provided, the search button wil not be shown.
   */
  hasSearchButton?: boolean;
  /**
   * Callback function that is called when a the search icon in the header is clicked. Passws whether search modal going to show or hide
   */
  handleSearchButtonClick?: (showing: boolean) => void;
  /**
   * Callback function that is called when the search result is clicked. Passes a search result.
   */
  handleResultClick?: (result: SearchResultType) => void;
  /**
   * Callback function that is called when a search is initiated. I.e. hitting enter or clicking search icon on search field.
   */
  handleSearch?: (query: string) => void;
  /**
   * Callback function that is called when a pagination button is clicked.
   * Takes two arguments.
   */
  handleLoadMoreResults?: LoadMoreResultsHandler | undefined;
  /**
   * Object to for search results for the search overlay
   */
  searchResults?: SearchResultsType;
  /**
   * Determines how many results to load per page
   */
  resultsPerPage?: number;
  /**
   * Determines how many pages to show in the pagination component
   */
  pagesToShow?: number;
  /**
   * A component that will be shown on the search overlay above of the search results
   */
  searchOverlayComponent?: React.FC;
  /**
   * Holds various texts related to the search overlay
   */
  searchLabels?: SearchLabels;
  /**
   * Use "show more button" type of pagination
   */
  loadMoreStylePagination?: boolean;
  /**
   * Pass a string to prefill input field on search modal
   */
  searchQuery?: string;
};

export const Header: React.FC<HeaderProps> = ({
  areaLinks = [],
  menuLinks = [],
  loginLinks = [],
  logoImage,
  logoLink,
  backLink,
  backText,
  feedbackContent,
  mobileCtaLink,
  mobileCtaText,
  hasTopStrip = true,
  hasSearchButton = false,
  handleSearchButtonClick,
  handleResultClick,
  handleSearch,
  handleLoadMoreResults,
  searchResults,
  resultsPerPage,
  pagesToShow,
  searchOverlayComponent,
  searchLabels,
  loadMoreStylePagination,
  searchQuery,
}) => {
  const [, height] = useWindowSize();
  const [activeParentIndex, setActiveParentIndex] = useState(0);
  const [showNavigationItems, setShowNavigationItems] = useState(false);
  const [animateNavigationItems, setAnimateNavigationItems] = useState(true);
  const [showLoginItems, setShowLoginItems] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const navContainerRef = React.useRef<HTMLElement>(null);
  const loginRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const animate = !showNavigationItems;
      setAnimateNavigationItems(animate);
    }, constants.NAV_ANIMATION_TIME);
  }, [showNavigationItems]);

  /**
   * Callback for when focusing on a parent menu item
   */
  const onParentFocus = useCallback((index: number) => {
    setActiveParentIndex(index);
    setShowNavigationItems(true);
    setShowLoginItems(false);
  }, []);

  /**
   * Callback for when focusing on the login/My RD menu item
   */
  const onLoginFocus = useCallback(() => {
    setAnimateNavigationItems(true);
    setShowNavigationItems(false);
    setShowLoginItems(true);
  }, []);

  /**
   * Callback for when clearing the focus (hiding the dropdowns)
   */
  const onClearFocus = useCallback(() => {
    setAnimateNavigationItems(true);
    setShowNavigationItems(false);
    setShowLoginItems(false);
  }, []);

  /**
   * Callback for hiding the login dropdown
   */
  const onClearLoginDropdown = useCallback((loginIndex) => {
    if (loginIndex !== undefined) {
      setShowLoginItems(false);
      const menuButton = loginRef?.current?.childNodes[
        loginIndex
      ] as HTMLButtonElement;
      if (menuButton) menuButton.focus();
    } else {
      setShowLoginItems(false);
    }
  }, []);

  /**
   * Callback for hiding the Navigation Menu dropdown
   */
  const onClearNavigationDropdown = useCallback((menuIndex) => {
    if (menuIndex !== undefined) {
      setShowNavigationItems(false);
      const menuButton = navContainerRef?.current?.childNodes[
        menuIndex
      ] as HTMLButtonElement;
      if (menuButton) menuButton.focus();
    } else {
      setShowNavigationItems(false);
    }
  }, []);

  /**
   * Callback for toggling the visibility of the mobile menu
   */
  const onMobileMenuToggle = useCallback(() => {
    setShowMobileMenu(!showMobileMenu);
    if (window && window.scrollTo) {
      window.scrollTo(0, 0);
    }
    // Disable scrolling when mobile menu is visible by setting "overflow-y: hidden"
    const body = document && document.getElementsByTagName('body')[0];
    if (body) {
      body.style.overflowY = showMobileMenu ? 'auto' : 'hidden';
    }
  }, [showMobileMenu]);

  /**
   * Callback for hiding  of the mobile menu
   */
  const onClearMobileMenu = useCallback(() => {
    setShowMobileMenu(false);

    // enable scrolling when mobile menu is hidden by setting "overflow-y: auto"
    const body = document && document.getElementsByTagName('body')[0];
    if (body) {
      body.style.overflowY = 'auto';
    }
  }, []);

  /**
   * Memoization of the active menu sub links
   */
  const subLinksToShow = useMemo((): LinkItem[] => {
    const parent = menuLinks[activeParentIndex];
    return (parent && parent.children) || [];
  }, [menuLinks, activeParentIndex]);

  /**
   * Side-effect for whenever the window height changes.
   * We set the window.innerHeight as a custom CSS variable and use it
   * for our fullscreen "modal" elements.
   */
  useEffect(() => {
    const vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height]);

  const onSearchLinkClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const showing = !showSearchModal;
      setShowSearchModal(showing);
      if (typeof handleSearchButtonClick === 'function') {
        handleSearchButtonClick(showing);
      }
    },
    [setShowSearchModal, handleSearchButtonClick, showSearchModal]
  );

  return (
    <header>
      {hasTopStrip && (
        <TopStrip
          areaLinks={areaLinks}
          backLink={backLink}
          backText={backText}
          feedbackContent={feedbackContent}
        />
      )}

      <Bar style={{ justifyContent: 'start' }} onClearFocus={onClearFocus}>
        <Logo image={logoImage} link={logoLink} />

        <DesktopNavigationTopLevel
          navigationRef={navContainerRef}
          loginRef={loginRef}
          links={menuLinks}
          activeParentIndex={activeParentIndex}
          showNavigationItems={showNavigationItems}
          onParentFocus={onParentFocus}
        />
        <LastCol>
          {hasSearchButton && <SearchIcon onClick={onSearchLinkClick} />}
          <DesktopLoginTopLevel
            navigationRef={navContainerRef}
            loginRef={loginRef}
            links={loginLinks}
            showLoginItems={showLoginItems}
            onLoginFocus={onLoginFocus}
          />
        </LastCol>
        {(loginLinks?.length > 0 || menuLinks?.length > 0) && (
          <MobileHamburgerButton
            showMobileMenu={showMobileMenu}
            onMobileMenuToggle={onMobileMenuToggle}
          />
        )}
      </Bar>

      <div>
        {menuLinks.map((link, i) => (
          <DesktopNavigationDropdown
            key={`navdropdown${i}`}
            index={i}
            links={link.children || []}
            show={showNavigationItems && activeParentIndex === i}
            animate={animateNavigationItems}
            onClearNavigationDropdown={onClearNavigationDropdown}
          />
        ))}
      </div>

      {loginLinks.map((link, i) => (
        <DesktopLoginDropdown
          key={`logindropdown${i}`}
          index={i}
          links={link.children || []}
          showLoginItems={showLoginItems}
          onClearFocus={onClearFocus}
          onClearLoginDropdown={onClearLoginDropdown}
        />
      ))}

      <MobileMenu
        menuLinks={menuLinks}
        loginLinks={loginLinks}
        ctaLink={mobileCtaLink}
        ctaText={mobileCtaText}
        showMobileMenu={showMobileMenu}
        showOverlay={showNavigationItems || showLoginItems}
        onLoginFocus={onLoginFocus}
        onParentFocus={onParentFocus}
        hasTopStrip={hasTopStrip}
      />

      <MobileMenuOverlay
        links={subLinksToShow}
        show={showMobileMenu && showNavigationItems}
        onToggleMobileMenu={onClearMobileMenu}
        onClearFocus={onClearFocus}
        hasTopStrip={hasTopStrip}
      />

      <MobileMenuOverlay
        links={loginLinks}
        show={showMobileMenu && showLoginItems}
        onToggleMobileMenu={onClearMobileMenu}
        onClearFocus={onClearFocus}
        hasTopStrip={hasTopStrip}
      />

      <Backdrop
        active={showNavigationItems || showLoginItems || showMobileMenu}
        onClick={onClearFocus}
      />

      <SearchModal
        handleClose={() => setShowSearchModal(false)}
        open={showSearchModal}
        handleSearch={handleSearch}
        handleLoadMoreResults={handleLoadMoreResults}
        handleResultClick={handleResultClick}
        searchResults={searchResults}
        CustomComponent={searchOverlayComponent}
        searchLabels={searchLabels}
        resultsPerPage={resultsPerPage}
        pagesToShow={pagesToShow}
        loadMoreStylePagination={loadMoreStylePagination}
        searchQuery={searchQuery}
      />
    </header>
  );
};

const LastCol = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  ${mediaQuery.mdDown} {
    margin-right: 10px;
  }

  > div:first-child {
    display: flex;
    margin-right: 15px;
  }
`;
