import styled, { css } from 'styled-components';
import { mediaQuery } from '../../Layout';

const shadowStyle = css`
  content: '';
  width: 35%;
  max-width: 440px;
  height: 20px;
  box-shadow: 2px 7px 30px #000;
  position: absolute;
  bottom: 20px;
  z-index: -1;
`;

const mobileSmallLeftStyle = css`
  box-shadow: 14px 4px 20px #000;
  bottom: 10px;
  left: 4%;
`;

const mobileSmallRightStyle = css`
  box-shadow: -14px 4px 20px #000;
  bottom: 10px;
  right: 4%;
`;

export type BottomShadowProps = {
  forceMobileStyle?: boolean;
};

/**
 * BottomShadow is for addding a nice shadow effect to any component/HTML element.
 * Just wrap your component with BottomShadow component and you're done.
 */
export const BottomShadow = styled.div<BottomShadowProps>`
  position: relative;

  ::before {
    ${shadowStyle}
    transform: rotate(-5deg);
    left: 3.7%;

    ${(props) => props.forceMobileStyle && mobileSmallLeftStyle}

    ${mediaQuery.smDown} {
      ${mobileSmallLeftStyle}
    }
  }

  ::after {
    ${shadowStyle}
    transform: rotate(5deg);
    right: 3.7%;

    ${(props) => props.forceMobileStyle && mobileSmallRightStyle}

    ${mediaQuery.smDown} {
      ${mobileSmallRightStyle}
    }
  }
`;
