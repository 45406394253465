import React from 'react';

const DocumentPDF = () => (
  <svg viewBox="0 0 15 20" fill="none" width="1em" height="1em">
    <path
      d="M14.8 5.265c.128.127.2.299.2.48V18.82a.68.68 0 01-.682.679H.682A.68.68 0 010 18.821V1.18A.68.68 0 01.682.5H9.73c.18 0 .356.072.483.2L14.8 5.264zm-1.372.79l-4.01-3.99v3.99h4.01zm-3.345 6.601a6.785 6.785 0 00-1.058.063c-.518-.318-.866-.755-1.114-1.396l.023-.093.026-.11c.092-.384.141-.665.156-.948.01-.213-.001-.41-.04-.593-.07-.394-.35-.624-.703-.639-.329-.013-.632.17-.709.454-.126.458-.052 1.061.215 2.09-.34.807-.79 1.753-1.091 2.28a5.905 5.905 0 00-.98.603c-.347.275-.563.558-.623.855a.644.644 0 00.114.465.64.64 0 00.487.291c.515.04 1.147-.488 1.845-1.68l.235-.08.253-.084c.16-.054.277-.093.392-.13a8.686 8.686 0 011.219-.322c.596.318 1.285.526 1.75.526.382 0 .641-.197.735-.508.082-.274.017-.59-.16-.766-.182-.178-.517-.263-.972-.278zM4.799 15.38v-.007l.003-.008a1.16 1.16 0 01.119-.228 2.89 2.89 0 01.372-.442c.084-.084.17-.166.273-.257l.196-.175.238-.22-.173.274c-.263.416-.5.716-.704.911-.074.072-.14.125-.193.16a.346.346 0 01-.056.03.082.082 0 01-.024.006.045.045 0 01-.05-.044zm2.683-4.628l-.048.085-.03-.093a3.481 3.481 0 01-.128-.806c-.015-.323.01-.516.113-.516.144 0 .21.23.215.574.004.303-.044.618-.122.756zm-.123 1.24l.032-.086.045.08c.249.45.572.826.927 1.088l.077.056-.093.02c-.348.071-.672.179-1.116.357.047-.019-.46.188-.589.237l-.111.042.06-.103c.262-.456.505-1.004.767-1.692zm3.358 1.617c-.168.065-.528.006-1.163-.263l-.16-.069.174-.012c.496-.037.848-.01 1.053.065.087.032.145.072.171.117a.098.098 0 01-.029.134.143.143 0 01-.046.027z"
      fill="#000"
    />
  </svg>
);

export default DocumentPDF;
