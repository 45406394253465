import React from 'react';

/** @deprecated Use Icons.Action.Search instead.*/
const Search = () => (
  <svg viewBox="0 0 15 20" width="1em" height="1em">
    <g transform="translate(1 2)" fill="none" fillRule="evenodd">
      <ellipse
        cx={6.429}
        cy={5.714}
        stroke="#000"
        strokeWidth={2.5}
        rx={5.571}
        ry={5.714}
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M3.284 10.281l2.077.219-2.333 6.218-2.18.001z"
      />
    </g>
  </svg>
);

export default Search;
