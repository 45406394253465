import React from 'react';

const Logout = () => (
  <svg viewBox="0 0 920.881 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M767.401 895.701q0 32.374-22.782 54.557-22.783 22.183-53.958 22.183H128.3q-15.588 0-29.977-5.996-14.389-5.995-25.18-16.786-10.792-10.792-16.187-24.581-5.396-13.79-5.396-29.377v-486.82h715.841v486.82zM715.841 0q-85.133 0-145.087 59.953-59.953 59.953-59.953 145.087l-1.199 153.48H26.379q-10.791 0-18.585 7.194Q0 372.909 0 383.7v512.001q0 25.18 9.593 48.562 9.592 23.381 27.578 42.567 17.986 17.985 41.368 27.578Q101.92 1024 128.3 1024h562.361q52.759 0 89.93-37.77 37.171-37.771 37.171-90.529l1.199-512.001q0-4.796-2.399-9.592-2.398-4.796-5.995-8.394-3.597-3.597-7.794-5.395-4.197-1.799-10.192-1.799l-178.66-1.199v-153.48q0-41.967 29.976-71.344 29.977-29.378 71.944-29.378t72.543 29.977q30.577 29.977 30.577 71.944 0 21.583 14.988 36.571 14.988 14.989 35.372 14.989 21.583 0 36.572-14.989 14.988-14.988 14.988-36.571 0-83.935-59.953-144.487Q800.975 0 715.841 0z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Logout;
