import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../Icons';

type CustomIconWrapperProps = {
  rotate?: number;
  height?: number;
  marginRight?: number;
};

const CustomIconWrapper = styled.span<CustomIconWrapperProps>`
  svg {
    ${(props) => props.rotate && `transform: rotate(${props.rotate}deg)`};
    ${(props) => props.height && `height: ${props.height}rem`}
  }
  ${(props) => props.marginRight && `margin-right: ${props.marginRight}rem`}
`;

export function ChironDown() {
  return (
    <CustomIconWrapper rotate={90} height={0.7} marginRight={0.3}>
      <Icons.Chiron />
    </CustomIconWrapper>
  );
}

export function ChironUp() {
  return (
    <CustomIconWrapper rotate={-90} height={0.7} marginRight={0.3}>
      <Icons.Chiron />
    </CustomIconWrapper>
  );
}

export function SortIcon() {
  return (
    <CustomIconWrapper height={0.7}>
      <Icons.Sort />
    </CustomIconWrapper>
  );
}

export function SortDownIcon() {
  return (
    <CustomIconWrapper height={0.7}>
      <Icons.SortDown />
    </CustomIconWrapper>
  );
}

export function SortUpIcon() {
  return (
    <CustomIconWrapper height={0.7}>
      <Icons.SortUp />
    </CustomIconWrapper>
  );
}
