import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  buttonBaseStyle,
  buttonPrimaryStyle,
  buttonSecondaryStyle,
  buttonCustomStyle,
} from './buttonStyles';

export type ButtonVariant = 'primary' | 'secondary' | 'custom';

export type ButtonProps = {
  /** The variant of the button */
  variant?: ButtonVariant;
  /** If the button is disabled */
  disabled?: boolean;
  /** (Optional) If href is provided then the component renders as an A tag */
  href?: string;
  /** (Optional) onClick event for button */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Children */
  children?: React.ReactNode;
};

export const StyledButton = styled.button<ButtonProps>`
  ${buttonBaseStyle}
  ${(props) => props.variant === 'primary' && buttonPrimaryStyle}
  ${(props) => props.variant === 'secondary' && buttonSecondaryStyle}
  ${(props) => props.variant === 'custom' && buttonCustomStyle}
`;

export const Button: React.FC<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, variant = 'primary', ...rest }) => {
  return (
    <StyledButton as={!!rest.href ? 'a' : 'button'} variant={variant} {...rest}>
      {children}
    </StyledButton>
  );
};
