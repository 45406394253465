import * as React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../Foundation/Color/Color';
import { Effects } from '../../Foundation/Effects/Effects';
import { Icons } from '../../Icons';
import { IconWrapper } from '../../Icons/IconWrapper';
import { Box } from '../../Layout/FlexBox';
import { Col, Container, Row } from '../../Layout/Grid';

export type BetaBannerProps = {
  backLinkText: string;
  backLinkHref: string;
  feedbackText?: string;
  feedbackLinkText?: string;
  feedbackLinkHref?: string;
  rawContent?: string;
};

/**
 * Component for showing a banner in the top of the website.
 */
export const BetaBanner: React.FC<BetaBannerProps> = (props) => (
  <Banner>
    <Container>
      <Row justify="between">
        <Col md={6}>
          <LinkContainer>
            <IconWrapper size="14px" fill={Color.white}>
              <Icons.ArrowLeft />
            </IconWrapper>
            <Link href={props.backLinkHref}>{props.backLinkText}</Link>
          </LinkContainer>
        </Col>
        {(props.feedbackLinkText || props.rawContent) && (
          <Col md="content">
            <Box marginTop={{ xs: 5, md: 0 }}>
              {props.feedbackLinkText &&
                props.feedbackLinkHref &&
                props.feedbackText && (
                  <div>
                    <DimmedText>{props.feedbackText}</DimmedText>{' '}
                    <Link href={props.feedbackLinkHref}>
                      {props.feedbackLinkText}
                    </Link>
                  </div>
                )}
              {props.rawContent && <RawContent>{props.rawContent}</RawContent>}
            </Box>
          </Col>
        )}
      </Row>
    </Container>
  </Banner>
);

const linkCss = css`
  position: relative;
  display: inline-block;
  font-weight: bold;
  color: #fff;
  text-decoration: none;

  ${Effects.Underline(Color.greyish, Color.white)}

  ::before,
  ::after {
    height: 2px;
  }

  :hover {
    ${Effects.UnderlineHover}
  }
`;

const Banner = styled.div`
  position: relative;
  z-index: 999;
  padding: 20px 0;
  background: #000;
  color: #fff;
  font-size: 15px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    margin-left: 6px;
  }
`;

const DimmedText = styled.span`
  opacity: 0.7;
`;

const RawContent = styled.span`
  color: #a4a4a4;
  a {
    ${linkCss}
  }
`;

const Link = styled.a`
  ${linkCss}
`;
