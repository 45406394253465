import styled from 'styled-components';
import { Color } from '../..';
import {
  linkAnimatedUnderlineStyle,
  linkAnimatedUnderlineStyleHover,
} from './linkStyles';

export interface LinkAnimatedUnderlineProps {
  textColor?: Color;
  underlineColorPrimary?: Color;
  underlineColorSecondary?: Color;
}

export const LinkAnimatedUnderline = styled.a<LinkAnimatedUnderlineProps>`
  ${({ textColor, underlineColorPrimary, underlineColorSecondary }) =>
    linkAnimatedUnderlineStyle(
      textColor,
      underlineColorPrimary,
      underlineColorSecondary
    )}
  ${linkAnimatedUnderlineStyleHover}
`;
