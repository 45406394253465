import React from 'react';

/** @deprecated Use Icons.Action.Edit instead.*/
const Edit = () => (
  <svg viewBox="0 0 18 18" width="1em" height="1em">
    <g stroke="#AFAFAF" fill="none" fillRule="evenodd">
      <path d="M5.632 12.368l.421-2.526L14.895 1h.842L17 2.263v.842l-8.842 8.842zm7.579-10.105l2.526 2.526" />
      <path d="M13.211 11.526v5.053H1.421V4.789h5.053" />
    </g>
  </svg>
);

export default Edit;
