import React from 'react';

const MapPin = () => (
  <svg viewBox="0 0 19 24" fill="none" width="1em" height="1em">
    <path
      d="M9.333 5.518A3.98 3.98 0 005.66 7.97a3.968 3.968 0 00.862 4.329 3.979 3.979 0 006.789-2.81 3.97 3.97 0 00-1.165-2.808 3.979 3.979 0 00-2.812-1.163zm0 6.69A2.723 2.723 0 016.82 10.53a2.715 2.715 0 011.983-3.706 2.724 2.724 0 012.794 1.156 2.716 2.716 0 01-2.263 4.228zM9.333.5a9.016 9.016 0 00-6.36 2.636A8.995 8.995 0 00.332 9.49c0 3.231 1.501 6.663 4.34 9.924a26.392 26.392 0 004.3 3.973.629.629 0 00.72 0 26.393 26.393 0 004.3-3.973c2.84-3.261 4.34-6.69 4.34-9.924a8.996 8.996 0 00-2.639-6.354A9.016 9.016 0 009.334.5zm0 21.587C7.763 20.877 1.59 15.722 1.59 9.49c0-2.052.816-4.02 2.268-5.47a7.749 7.749 0 0110.952 0 7.731 7.731 0 012.268 5.47c0 6.232-6.174 11.387-7.744 12.597z"
      fill="#424242"
    />
  </svg>
);

export default MapPin;
