import styled from 'styled-components';
import { mediaQuery } from '../../Layout';

/**
 * PopoutBox is just a simply box for displaying some content in a big, white box that has a slight box-shadow.
 */
export const PopoutBox = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 30px 40px;

  ${mediaQuery.sm} {
    padding: 50px 60px;
  }

  ${mediaQuery.lg} {
    padding: 70px 80px;
  }
`;
