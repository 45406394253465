import React from 'react';

const EditAlt = () => (
  <svg viewBox="0 0 19 18" fill="none" width="1em" height="1em">
    <path
      d="M12 3.172l3 3m-5 11h8m-16-4l-1 4 4-1L16.586 4.586a2 2 0 000-2.828l-.172-.172a2 2 0 00-2.828 0L2 13.172z"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditAlt;
