import React from 'react';

const CloseLight = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <circle id="svg-closelight-a" cx={12} cy={12} r={12} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="svg-closelight-b" fill="#fff">
        <use xlinkHref="#svg-closelight-a" />
      </mask>
      <use fill="#000" filter="url(#filter-3)" xlinkHref="#svg-closelight-a" />
      <g mask="url(#svg-closelight-b)" fill="#FFF">
        <path d="M0 0h24v24H0z" />
      </g>
      <g fill="#151515" fillRule="nonzero">
        <path d="M15.268 8l.684.689L8.685 16 8 15.311z" />
        <path d="M8.732 8l-.684.689L15.315 16l.685-.689z" />
      </g>
    </g>
  </svg>
);

export default CloseLight;
