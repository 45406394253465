import React from 'react';

/** @deprecated Use Icons.Action.Graph instead.*/
const Value = () => (
  <svg viewBox="0 0 48 48" width="1em" height="1em">
    <path
      d="M33.93 6h-4.858a.586.586 0 00-.572.599V41.4c0 .33.256.599.572.599h4.857a.586.586 0 00.571-.599V6.6c0-.33-.256-.599-.57-.599M11.428 18.549c.315 0 .57.267.57.599V41.4c0 .33-.255.599-.57.599H6.57C6.256 42 6 41.732 6 41.401V19.148c0-.332.256-.6.571-.6zm-.572 1.197H7.142v21.056h3.715V19.746zm8.071-1.197c.317 0 .572.267.572.599V41.4c0 .33-.255.599-.572.599h-4.856a.586.586 0 01-.571-.599V19.148c0-.332.256-.6.57-.6zm-.57 1.197h-3.716v21.056h3.715V19.746zm8.071-1.197c.316 0 .571.267.571.599V41.4c0 .33-.255.599-.57.599h-4.858A.584.584 0 0121 41.4V19.148c0-.332.254-.6.57-.6zm-.571 1.197h-3.715v21.056h3.715V19.746zM41.428 6h-4.857c-.315 0-.57.268-.57.599V41.4c0 .33.255.599.57.599h4.858A.586.586 0 0042 41.4V6.6c0-.33-.256-.599-.571-.599"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Value;
