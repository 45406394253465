import React from 'react';

const View = () => (
  <svg viewBox="0 0 22 15" fill="none" width="1em" height="1em">
    <path
      d="M11 4.5a3 3 0 100 6 3 3 0 000-6zm0 8a5 5 0 110-10 5 5 0 010 10zM11 0C6 0 1.73 3.11 0 7.5 1.73 11.89 6 15 11 15s9.27-3.11 11-7.5C20.27 3.11 16 0 11 0z"
      fill="#000"
    />
  </svg>
);

export default View;
