import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Color } from '../../../Foundation';
import { Icons } from '../../../Icons';

interface ButtonConfirmProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

export function ButtonConfirm({
  label = 'Bekræft',
  ...rest
}: ButtonConfirmProps): JSX.Element {
  return (
    <Button {...rest}>
      <Icons.CheckmarkWhite />
      <Label>{label}</Label>
    </Button>
  );
}

const Label = styled.label`
  color: ${Color.white};
  cursor: inherit;
  display: block;
  font-size: 10px;
  font-style: italic;
  font-weight: normal;
  padding-top: 2px;
`;

const Button = styled.button`
  background-color: ${Color.green60};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 6px 6px;
  z-index: 1;

  display: grid;
  place-items: center;
`;
