import React from 'react';

/** @deprecated Use Icons.Action.Profile instead.*/
const Customer = () => (
  <svg viewBox="0 0 18 19" width="1em" height="1em">
    <g
      transform="translate(1 1)"
      stroke="#D50018"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    >
      <circle cx={8} cy={4.5} r={4} />
      <path d="M.5 18v-4l3-3L8 12.5l4.5-1.5 3 3v4" />
    </g>
  </svg>
);

export default Customer;
