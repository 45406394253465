import React from 'react';

/** @deprecated Use Icons.Action.Confirm instead.*/
const Confirm = () => (
  <svg viewBox="0 0 18 18" width="1em" height="1em">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle cx={8} cy={8} r={8} stroke="#000" strokeWidth={2} />
      <path
        fill="#000"
        d="M10.153 5.395A1 1 0 0111.815 6.5l-.07.105-3.233 4.258a1 1 0 01-1.366.218l-.097-.077L4.334 8.58a1 1 0 011.233-1.57l.1.078L7.572 8.79l2.58-3.395z"
      />
    </g>
  </svg>
);

export default Confirm;
