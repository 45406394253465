import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Box, BoxProps } from '../../Layout';

const BASE_PROPS = {
  height: 16,
  borderRadius: 4,
};

export type SkeletonProps = BoxProps & {
  active?: boolean;
  type?: 'line' | 'paragraph';
};

/**
 * Provide a placeholder while you wait for content to load, or to visualise content that doesn't exist yet.
 */
export const Skeleton: React.FC<SkeletonProps> = ({
  type = 'line',
  color,
  ...rest
}) => {
  if (type === 'paragraph') {
    return (
      <>
        <Base
          {...BASE_PROPS}
          height={20}
          width="40%"
          marginBottom={4}
          {...rest}
        />
        <Base {...BASE_PROPS} marginBottom={3} {...rest} />
        <Base {...BASE_PROPS} marginBottom={3} {...rest} />
        <Base {...BASE_PROPS} width="60%" {...rest} />
      </>
    );
  }
  return <Base {...BASE_PROPS} {...rest} />;
};

const Base = styled(Box)<SkeletonProps>`
  background: hsla(0, 0%, 75%, 0.2);

  ${(props) =>
    props.active &&
    css`
      animation: ${loadAnimation} 1.4s ease infinite;
      background: linear-gradient(
        90deg,
        hsla(0, 0%, 75%, 0.2) 25%,
        hsla(0, 0%, 51%, 0.24) 37%,
        hsla(0, 0%, 75%, 0.2) 63%
      );
      background-size: 400% 100%;
    `}
`;

const loadAnimation = keyframes`
  from {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
`;
