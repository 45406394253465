import React from 'react';

const SortDown = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <g fill="none" fillRule="evenodd">
      <path
        d="M12.158 4.08l5.695 6.854c.106.211 0 .422-.21.422H6.252c-.212 0-.317-.316-.212-.422l5.695-6.855a.32.32 0 01.422 0z"
        fill="#B10B1F"
        className="primaryArrow"
      />
      <path
        d="M11.736 20.742l-5.695-6.855c-.105-.21 0-.422.211-.422h11.496c.21 0 .316.317.21.422l-5.694 6.855c-.211.106-.422.106-.528 0z"
        fill="#595959"
        className="secondaryArrow"
      />
    </g>
  </svg>
);

export default SortDown;
