import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../../Foundation';

type ResultCountProps = {
  count?: number | null;
  hitsLabel: string;
};
export const Hits: React.FC<ResultCountProps> = ({ count, hitsLabel }) =>
  count !== null && hitsLabel.length > 0 ? (
    <SearchResultsCount>
      <Typography type="h8">
        {count} {hitsLabel}
      </Typography>
    </SearchResultsCount>
  ) : null;
const SearchResultsCount = styled.div`
  position: relative;
  top: 20px;
`;
