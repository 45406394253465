import * as React from 'react';
import styled, { css } from 'styled-components';

export type SlantPosition = 'left' | 'right';
export type SlantPositionProps = { slantPosition: SlantPosition };

type SlantedOverlayProps = SlantPositionProps & {
  children?: React.ReactNode;
};

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

const Lines = styled.div<SlantPositionProps>`
  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 4px;
    background: rgb(249, 245, 245);
    background: linear-gradient(
      90deg,
      rgba(249, 245, 245, 1) 0%,
      rgba(250, 248, 248, 1) 100%
    );
    transform: skew(-12deg);

    ${({ slantPosition }) =>
      slantPosition === 'left' &&
      css`
        left: 3px;
        transform-origin: bottom left;
      `}

    ${({ slantPosition }) =>
      slantPosition === 'right' &&
      css`
        right: 2px;
        transform-origin: top right;
      `}
  }

  ::after {
    ${({ slantPosition }) =>
      slantPosition === 'left' &&
      css`
        left: -6px;
      `}

    ${({ slantPosition }) =>
      slantPosition === 'right' &&
      css`
        right: -7px;
      `}
  }
`;

const Content = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
`;

const CornerOverlay = styled.div<SlantPositionProps>`
  width: 512.5px;
  background: #fff;
  opacity: 1;
  transform: skew(-12deg);
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;

  ${({ slantPosition }) =>
    slantPosition === 'left' &&
    css`
      left: -500px;
      transform-origin: bottom left;
    `}
  ${({ slantPosition }) =>
    slantPosition === 'right' &&
    css`
      right: -500px;
      transform-origin: top right;
    `}
`;

export const SlantedOverlay: React.FC<SlantedOverlayProps> = ({
  slantPosition,
  children,
  ...rest
}) => (
  <Container {...rest}>
    <Lines slantPosition={slantPosition} />
    <Content>
      <CornerOverlay slantPosition={slantPosition} />
      {children}
    </Content>
  </Container>
);
