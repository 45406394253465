import React from 'react';

const ValueBasedPricing = () => (
  <svg viewBox="0 0 948.385 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M947.103 367.82q0-2.564-1.281-4.486-1.282-1.922-2.563-4.486L786.903 121.752q-1.281-6.408-6.408-10.253-5.126-3.844-11.534-1.281l-281.952 62.798V32.04v-.641-.641h29.476q6.408 0 10.894-4.485 4.486-4.486 4.486-10.894t-4.486-10.893Q522.893 0 516.485 0h-87.149q-6.408 0-10.893 4.486-4.486 4.485-4.486 10.893t4.486 10.894q4.485 4.485 10.893 4.485h28.196l-.641.641-.641.641v148.666l-285.797 65.361q-2.563 0-3.845 1.282l-2.563 2.563h-1.282q-1.281 1.282-1.922 2.563-.641 1.282-.641 2.564L2.563 493.417h1.282q-1.282 1.281-1.923 3.204-.64 1.922-.64 4.485Q0 504.951 0 508.796v7.689q0 73.052 52.546 125.597 52.545 52.546 125.597 52.546 74.332 0 126.237-52.546 51.905-52.545 51.905-125.597v-8.971-7.689q0-2.564-1.281-4.486-1.282-1.922-3.845-4.486h1.281L208.901 269.136l247.349-57.672v721.542H230.688q-6.408 0-10.253 4.485-3.844 4.486-3.844 10.894v60.235q0 6.408 3.844 10.894 3.845 4.485 10.253 4.485h484.446q6.408 0 10.893-4.485 4.486-4.486 4.486-10.894v-60.235q0-6.408-4.486-10.894-4.485-4.485-10.893-4.485H487.009v-727.95l246.067-55.109-138.413 211.465q-1.282 1.281-1.922 3.204-.641 1.922-.641 4.485 0 3.845-.641 7.049t-.641 7.049q0 74.333 52.546 126.878 52.546 52.546 125.597 52.546 74.333 0 126.878-52.546 52.546-52.545 52.546-126.878 0-3.845-.641-7.69t-.641-7.689zm-767.679-87.149l133.287 206.338H44.856zm-1.281 383.199q-60.236 0-103.81-42.934-43.575-42.934-43.575-103.169h294.769q0 60.235-42.934 103.169t-104.45 42.934zm725.386-308.866H635.674l133.287-205.057 134.568 205.057zM768.961 531.865q-60.235 0-103.169-42.934t-44.215-103.169h296.05q-1.282 60.235-44.216 103.169-42.933 42.934-104.45 42.934z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default ValueBasedPricing;
