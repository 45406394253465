import { breakpoints, breakpointsBootstrap } from './breakpoints';

type MediaQueryType = 'min' | 'max';

/**
 * Function to generate the media query CSS code
 * @param type Type of media query, either min-width or max-width
 * @param width The width for the media query
 */
function createMediaQuery(type: MediaQueryType, width: number) {
  return `@media (${type}-width: ${width}px)`;
}

/**
 * Helper for creating media-query rules in CSS.
 */
export const mediaQuery = {
  /**
   * @deprecated This is deprecated. Please migrate to mobile-first styling and use Boostrap breakpoints (sm, md, lg, xl, xxl).
   */
  minWidth: {
    // No "mobileSmall" as it is the starting point when doing min-width
    mobileLarge: createMediaQuery('min', breakpoints.mobileLarge),
    tablet: createMediaQuery('min', breakpoints.tablet),
    desktop: createMediaQuery('min', breakpoints.desktop),
    desktopLarge: createMediaQuery('min', breakpoints.desktopLarge),
  },
  /**
   * @deprecated This is deprecated. Please migrate to mobile-first styling and use Boostrap breakpoints (sm, md, lg, xl, xxl).
   */
  maxWidth: {
    mobileSmall: createMediaQuery('max', breakpoints.mobileLarge - 1),
    mobileLarge: createMediaQuery('max', breakpoints.tablet - 1),
    tablet: createMediaQuery('max', breakpoints.desktop - 1),
    desktop: createMediaQuery('max', breakpoints.desktopLarge - 1),
    // No "desktopLarge" as it is the starting point when doing max-width
  },
  ie11:
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)',

  // New Bootstrap-inspired breakpoints:
  // Bootstrap docs: https://getbootstrap.com/docs/5.0/layout/breakpoints/#media-queries

  /**
   * X-Small devices (starting point, should rarely be used, 0px and up)
   */
  xs: createMediaQuery('min', breakpointsBootstrap.xs),
  /**
   * Small devices (landscape phones, 576px and up)
   */
  sm: createMediaQuery('min', breakpointsBootstrap.sm),
  /**
   * X-Small devices (portrait phones, less than 576px)
   */
  smDown: createMediaQuery('max', breakpointsBootstrap.sm - 0.02),
  /**
   * Medium devices (tablets, 768px and up)
   */
  md: createMediaQuery('min', breakpointsBootstrap.md),
  /**
   * Small devices (landscape phones, less than 768px)
   */
  mdDown: createMediaQuery('max', breakpointsBootstrap.md - 0.02),
  /**
   * Large devices (desktops, 992px and up)
   */
  lg: createMediaQuery('min', breakpointsBootstrap.lg),
  /**
   * Medium devices (tablets, less than 992px)
   */
  lgDown: createMediaQuery('max', breakpointsBootstrap.lg - 0.02),
  /**
   * X-Large devices (large desktops, 1200px and up)
   */
  xl: createMediaQuery('min', breakpointsBootstrap.xl),
  /**
   * Large devices (desktops, less than 1200px)
   */
  xlDown: createMediaQuery('max', breakpointsBootstrap.xl - 0.02),
  /**
   * XX-Large devices (larger desktops, 1400px and up)
   */
  xxl: createMediaQuery('min', breakpointsBootstrap.xxl),
  /**
   * X-Large devices (large desktops, less than 1400px)
   */
  xxlDown: createMediaQuery('max', breakpointsBootstrap.xxl - 0.02),
};
