import {
  ClassNames,
  IndexerPosition,
  EventHandlerState,
  Constants,
} from './types';

const getClientX = (e: any) =>
  typeof e.touches !== 'undefined' ? e.touches[0].clientX : e.clientX;

export const setEllipsisStyle = (
  wrapperRef: React.RefObject<HTMLDivElement>
) => {
  if (!wrapperRef?.current) return;

  const showLeft =
    wrapperRef.current.scrollLeft > Constants.ellipsisScrollOffset;
  const showRight =
    wrapperRef.current.clientWidth + wrapperRef.current.scrollLeft <
    wrapperRef.current.scrollWidth - Constants.ellipsisScrollOffset;
  const ellipsisLeft: any = wrapperRef.current.parentElement?.querySelector(
    '.' + ClassNames.ellipsisLeft
  );
  const ellipsisRight: any = wrapperRef.current.parentElement?.querySelector(
    '.' + ClassNames.ellipsisRight
  );
  if (ellipsisLeft && showLeft) {
    ellipsisLeft.classList.add(ClassNames.ellipsisVisible);
  } else {
    ellipsisLeft.classList.remove(ClassNames.ellipsisVisible);
  }
  if (ellipsisRight && showRight) {
    ellipsisRight.classList.add(ClassNames.ellipsisVisible);
  } else {
    ellipsisRight.classList.remove(ClassNames.ellipsisVisible);
  }
};

export const leftEllipsisClick = (
  wrapperRef: React.RefObject<HTMLDivElement>
) => {
  if (!wrapperRef?.current) return;
  if (wrapperRef.current.scrollBy) {
    wrapperRef.current.scrollBy({
      left: -150,
      top: 0,
      behavior: 'smooth',
    });
  } else {
    wrapperRef.current.scrollLeft -= 150;
  }
};

export const rightEllipsisClick = (
  wrapperRef: React.RefObject<HTMLDivElement>
) => {
  if (!wrapperRef?.current) return;
  if (wrapperRef.current.scrollBy) {
    wrapperRef.current.scrollBy({
      left: 150,
      top: 0,
      behavior: 'smooth',
    });
  } else {
    wrapperRef.current.scrollLeft += 150;
  }
};

export const selectorMouseDown = (
  eventHandlerState: EventHandlerState,
  e: React.MouseEvent | React.TouchEvent
) => {
  eventHandlerState.mouseDownStartX = getClientX(e);
  eventHandlerState.mouseDown = true;
  eventHandlerState.mouseDownTimestamp = e.timeStamp;
  eventHandlerState.prevLeft = getClientX(e);
  if (!eventHandlerState.mouseUpListenerSet) {
    eventHandlerState.mouseUpListenerSet = true;
    window.addEventListener('mouseup', () => {
      if (!eventHandlerState.mouseDown) return;
      eventHandlerState.mouseDown = false;
    });
    window.addEventListener('touchend', () => {
      if (!eventHandlerState.mouseDown) return;
      eventHandlerState.mouseDown = false;
    });
  }
};

export const selectorMouseMove = (
  eventHandlerState: EventHandlerState,
  e: React.MouseEvent | React.TouchEvent,
  wrapperRef: React.RefObject<HTMLDivElement>
) => {
  if (
    !eventHandlerState.mouseDown ||
    !wrapperRef?.current ||
    !wrapperRef.current.scrollBy
  )
    return;
  const distance = Math.abs(eventHandlerState.prevLeft - getClientX(e));
  let goingRight = eventHandlerState.prevLeft < getClientX(e);
  eventHandlerState.prevLeft = getClientX(e);
  if (goingRight) {
    wrapperRef.current.scrollBy(-distance, 0);
  } else {
    wrapperRef.current.scrollBy(distance, 0);
  }
  momentumScroll(eventHandlerState, e, wrapperRef);
};

export const momentumScroll = (
  eventHandlerState: EventHandlerState,
  e: React.MouseEvent | React.TouchEvent,
  wrapperRef: React.RefObject<HTMLDivElement>
) => {
  var distanceX = eventHandlerState.mouseDownStartX - getClientX(e);
  var travelTime = Math.abs(eventHandlerState.mouseDownTimestamp - e.timeStamp);
  var xPxS = distanceX / travelTime;
  if (Math.abs(xPxS) > 0.2) {
    if (!wrapperRef?.current) return;
    const scrollX = xPxS * 1.5;
    const scrollInterval = setInterval(() => {
      if (!wrapperRef?.current) return;
      wrapperRef.current.scrollBy(scrollX, 0);
      setTimeout(() => clearInterval(scrollInterval), 250);
    }, 10);
  }
};

export const handleTabClick = (
  index: number,
  tabRefs: React.RefObject<any>[],
  wrapperRef: React.RefObject<HTMLDivElement>,
  setIndexerPosition: React.Dispatch<React.SetStateAction<IndexerPosition>>
) => {
  if (tabRefs[index].current !== null) {
    setIndexerPosition({
      left: tabRefs[index].current.offsetLeft,
      width: tabRefs[index].current.offsetWidth,
    });
    tabRefs.forEach((ref) =>
      ref.current.classList.remove(ClassNames.selectedTab)
    );
    tabRefs[index].current.classList.add(ClassNames.selectedTab);
    scrollTabIntoView(index, tabRefs, wrapperRef);
  }
};

const scrollTabIntoView = (
  index: number,
  tabRefs: React.RefObject<any>[],
  wrapperRef: React.RefObject<HTMLDivElement>
) => {
  if (
    !wrapperRef.current ||
    !wrapperRef.current?.scrollTo ||
    !wrapperRef.current.clientWidth
  )
    return;
  let tabLeft = 0;
  let tabRight = 0;
  const wrapperLeft = wrapperRef.current?.scrollLeft || 0;
  const wrapperRight = wrapperLeft + wrapperRef.current?.clientWidth;
  for (let i = 0; i < index; i++) {
    tabLeft += tabRefs[i].current.clientWidth;
  }
  tabRight = tabLeft + tabRefs[index].current.clientWidth;
  if (tabLeft < wrapperLeft && tabRight < wrapperRight) {
    wrapperRef.current?.scrollTo({ left: tabLeft, behavior: 'smooth' });
  } else if (
    tabRight + Constants.ellipsisScrollOffset > wrapperRight &&
    tabLeft < wrapperRight
  ) {
    wrapperRef.current?.scrollTo({
      left:
        wrapperLeft +
        tabRefs[index].current.clientWidth -
        Constants.ellipsisScrollOffset,
      behavior: 'smooth',
    });
  }
};
