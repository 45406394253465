import React from 'react';

const MandatoryStar = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <path
      d="M13.889 16.556L12 13.666l-1.889 2.89-2-1.445 2.222-2.667L7 11.556l.778-2.445L11 10.444 10.778 7h2.444L13 10.444l3.222-1.333.778 2.445-3.333.888 2.222 2.667z"
      fill="currentColor"
    />
  </svg>
);

export default MandatoryStar;
