import styled from 'styled-components';
import { mediaQuery } from '../../Layout';

/**
 * Just a simple <div /> wrapper for the carousel, so it has a nice padding on both
 * sides horizontally when displayed on desktop, and no padding when displayed on mobile.
 */
export const CarouselDefaultContainer = styled.div`
  position: relative;

  .slick-slider {
    position: static !important;
  }

  ${mediaQuery.md} {
    padding: 0 70px;
  }
`;
