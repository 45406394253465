import { HandleTopLevelKeyDownArgs } from './types';

const EXCLUDE_TAGS = ['SPAN'];
const ROUND_ROBIN = true;

export const useMenuNavigation = (
  closeNavigation: (menuIndex?: number) => void = () => {}
) => {
  const handleTopLevelKeyDown = (args: HandleTopLevelKeyDownArgs) => {
    const {
      event,
      dropdownMenuId,
      index,
      loginRef,
      navigationRef,
      onLoginFocus = () => null,
      onParentFocus = () => null,
    } = args;
    event.stopPropagation();
    const navigationLastElement = navigationRef
      ? navigationRef?.current?.children.item(
          navigationRef.current.children.length - 1
        )
      : null;
    switch (event.key) {
      case 'ArrowLeft': {
        if (event.currentTarget === loginRef?.current?.childNodes[0]) {
          const currentElement = navigationLastElement as HTMLButtonElement;
          currentElement?.focus();
        } else {
          const currentElement = event.currentTarget;
          const prevMenuButton = currentElement.previousElementSibling as HTMLButtonElement;
          if (prevMenuButton) {
            prevMenuButton.focus();
          }
        }
        break;
      }

      case 'ArrowRight': {
        const currentElement = event.currentTarget;
        const nextMenuButton = currentElement.nextElementSibling as HTMLButtonElement;
        if (nextMenuButton) {
          nextMenuButton.focus();
        } else {
          if (currentElement === navigationLastElement) {
            const loginDropdown = loginRef?.current
              ?.childNodes[0] as HTMLButtonElement;
            loginDropdown?.focus();
          }
        }
        break;
      }

      case 'ArrowDown': {
        const dropDownMenu = document.querySelector(`#${dropdownMenuId}`);
        if (event.currentTarget === loginRef?.current?.childNodes[0]) {
          onLoginFocus();
        } else {
          onParentFocus(index ?? 0);
        }
        if (dropDownMenu) {
          event.preventDefault();
          dropDownMenu.querySelector('a')?.focus();
        }
        break;
      }
    }
  };

  const handleDropdownKeyDown = (
    event: React.KeyboardEvent,
    menuIndex: number
  ) => {
    event.stopPropagation();
    const currentElement = event.currentTarget.parentElement;

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const nextElement = getNextElement(currentElement);
        const nextMenu = getNextMenu(event.currentTarget);
        if (nextElement) {
          nextElement.firstElementChild.focus();
        } else if (nextMenu) {
          nextMenu.childNodes[1].firstElementChild.focus();
        } else if (ROUND_ROBIN) {
          const firstMenu = getFirstMenu(currentElement?.firstChild);
          const firstElement = getFirstElement(firstMenu);
          firstElement?.firstElementChild?.focus();
        } else {
          closeNavigation();
        }
        break;
      }

      case 'ArrowUp': {
        event.preventDefault();
        const prevElement = getPrevElement(currentElement);
        const prevMenu = getPrevMenu(event.currentTarget);
        if (prevElement) {
          prevElement.firstElementChild.focus();
        } else if (prevMenu) {
          prevMenu.lastElementChild?.firstElementChild?.focus();
        } else {
          closeNavigation(menuIndex);
          const anchor = currentElement?.firstElementChild as HTMLAnchorElement;
          anchor.blur();
        }
        break;
      }

      case 'ArrowRight': {
        const nextMenu = getNextMenu(event.currentTarget);
        if (nextMenu) {
          nextMenu.childNodes[1].firstElementChild.focus();
        } else if (ROUND_ROBIN) {
          const firstMenu = getFirstMenu(event.currentTarget);
          if (!firstMenu?.contains(event.currentTarget))
            firstMenu?.childNodes[1]?.firstElementChild?.focus();
        } else {
          closeNavigation();
        }
        break;
      }

      case 'ArrowLeft': {
        const prevMenu = getPrevMenu(event.currentTarget);
        if (prevMenu) {
          prevMenu.childNodes[1].firstElementChild.focus();
        } else if (ROUND_ROBIN) {
          const lastMenu = getLastMenu(event.currentTarget);
          if (!lastMenu?.contains(event.currentTarget))
            lastMenu?.childNodes[1]?.firstElementChild?.focus();
        } else {
          closeNavigation();
        }
        break;
      }

      case 'Escape': {
        closeNavigation();
        break;
      }

      default: {
        break;
      }
    }
  };
  return { handleTopLevelKeyDown, handleDropdownKeyDown };
};

const getFirstElement = (element: any): any =>
  Array.from(element?.childNodes).filter(
    (node: any) => EXCLUDE_TAGS.indexOf(node?.tagName) === -1
  )[0];

const getNextElement = (element: any) => element?.nextElementSibling;

const getPrevElement = (element: any) =>
  EXCLUDE_TAGS.indexOf(element?.previousElementSibling?.tagName) === -1
    ? element?.previousElementSibling
    : null;

const getFirstMenu = (element: any): any =>
  Array.from(
    element?.parentElement?.parentElement?.parentElement?.childNodes
  ).filter((node: any) => node?.childElementCount > 0)[0];

const getLastMenu = (element: any) =>
  element?.parentElement?.parentElement?.parentElement?.lastElementChild;

const getNextMenu = (element: any) =>
  element?.parentElement?.parentElement?.nextElementSibling;

const getPrevMenu = (element: any) =>
  element?.parentElement?.parentElement?.previousElementSibling
    ?.childElementCount > 0
    ? element?.parentElement?.parentElement?.previousElementSibling
    : null;
