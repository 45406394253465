import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../../Icons';

import { mediaQuery } from '../../../Layout';

type SearchIconProps = {
  onClick: (e: React.SyntheticEvent) => void;
};
export const SearchIcon: React.FC<SearchIconProps> = ({ onClick }) => (
  <SearchIconContainer>
    <button onClick={onClick}>
      <Icons.Action.Search />
    </button>
  </SearchIconContainer>
);

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  margin-right: 10px;

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  svg {
    height: 20px;
    width: 20px;
  }
  ${mediaQuery.lgDown} {
    svg {
      margin-top: 6px;
      height: 24px;
      width: 24px;
    }
  }
`;
