import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../Icons';
import { Col } from '../../Layout/Grid';

const ALT_TEXT = 'Realkredit Danmark';

type LogoProps = {
  image?: string;
  link?: string;
};

export const Logo: React.FC<LogoProps> = React.memo(({ image, link }) => {
  const showCustomImage = !!image;

  return (
    <Col xs={6} lg={2.5}>
      <Container>
        <a href={link} title={ALT_TEXT}>
          {showCustomImage ? (
            <img src={image} alt={ALT_TEXT} />
          ) : (
            <Icons.Logo />
          )}
        </a>
      </Container>
    </Col>
  );
});

const Container = styled.div`
  display: flex;
  width: 130px;
  height: 70px;
  align-items: center;

  a {
    display: block;
  }

  svg {
    width: 130px;
    height: 32px;
  }

  img {
    display: block;
    max-width: 130px;
    max-height: 70px;
  }
`;
