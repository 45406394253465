import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../../Layout';
import { CarouselDotButton } from './CarouselDotButton';
import { Dot, dotActiveStyle } from './Dot';

const Container = styled.ul<Pick<CarouselDotsProps, 'dotArrows'>>`
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
  ${(props) =>
    props.dotArrows &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  li {
    display: inline-block;
  }

  ${mediaQuery.smDown} {
    margin-top: 15px;
  }
`;

const SlickCarouselDot = styled(Dot)`
  .slick-active & {
    ${dotActiveStyle}
  }
`;

type CarouselDotsProps = {
  children?: ReactNode;
  dotArrows?: boolean;
  sliderRef?: Slider;
};

export const CarouselDots = ({
  children,
  dotArrows,
  sliderRef,
}: CarouselDotsProps) => {
  const childrenNodeArray = Array.isArray(children) && children;
  if (dotArrows && childrenNodeArray) {
    return (
      <Container dotArrows={dotArrows}>
        <CarouselDotButton
          arrowType="ArrowLeft"
          childrenNodeArray={childrenNodeArray}
          onClick={() => {
            if (sliderRef && sliderRef.slickPrev) {
              sliderRef.slickPrev();
            }
          }}
        />
        {children}
        <CarouselDotButton
          arrowType="ArrowRight"
          childrenNodeArray={childrenNodeArray}
          onClick={() => {
            if (sliderRef && sliderRef.slickNext) {
              sliderRef.slickNext();
            }
          }}
        />
      </Container>
    );
  }
  return <Container>{children}</Container>;
};

type CarouselDotProps = {
  i: number;
  onClick?: () => void;
};

export const CarouselDot = (props: CarouselDotProps) => {
  return <SlickCarouselDot onClick={props.onClick}>{props.i}</SlickCarouselDot>;
};
