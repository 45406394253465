import React from 'react';

const SpeechBubble = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <path
      d="M2.625 21.75h-.25A.687.687 0 012 21.125V4.875c0-.5.25-1 .5-1.375S3.375 3 3.875 3H20c1.125 0 2 .875 2 1.875v9.875c0 1-.875 1.875-1.875 1.875H7.875L3.125 21.5c-.125.125-.375.25-.5.25zm1.25-17.5c-.125 0-.25.125-.375.25s-.25.25-.25.375v14.75l3.875-3.875c.125-.25.25-.25.375-.25H20c.375 0 .625-.25.625-.625v-10c.125-.375-.125-.625-.5-.625H3.875z"
      fill="currentColor"
    />
  </svg>
);

export default SpeechBubble;
