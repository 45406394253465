import React from 'react';

/** @deprecated Use Icons.Action.Calculator instead.*/
const Calculator = () => (
  <svg viewBox="0 0 29 36" width="1em" height="1em">
    <g fill="none" fillRule="evenodd">
      <path d="M8 9h13v4H8z" fill="#000" />
      <path stroke="#262626" strokeWidth={2.5} d="M27 2v32H2V2z" />
      <path
        fill="#000"
        d="M8 16h2.5v2.5H8zm0 5h2.5v2.5H8zm5-5h2.5v2.5H13zm0 5h2.5v2.5H13zm5-5h2.5v2.5H18zm0 5h2.5v2.5H18zM8 25h2.5v2.5H8zm5 0h2.5v2.5H13zm5 0h2.5v2.5H18z"
      />
    </g>
  </svg>
);

export default Calculator;
