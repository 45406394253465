import React from 'react';

/** @deprecated Use Icons.Action.Checkmark instead.*/
const CheckmarkWhite = () => (
  <svg viewBox="0 0 20 18" width="1em" height="1em">
    <path
      d="M7.408 17.053a.999.999 0 01-.716-.302L.283 10.173a1 1 0 111.433-1.395L7.32 14.53 18.207.39a1 1 0 111.584 1.221L8.201 16.663a.998.998 0 01-.793.39"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default CheckmarkWhite;
