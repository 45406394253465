import React from 'react';

const Eboks = () => (
  <svg viewBox="0 0 1021.163 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M499.235 292.166q-140.41 0-193.595 57.44-53.186 57.441-53.186 202.106 0 160.266 56.731 207.069 56.732 46.803 194.305 46.803 177.285 0 203.524-52.476 26.238-52.477 20.565-92.189H616.953l-1.419 15.601q-2.836 18.438-19.856 28.366-17.019 9.928-87.933 9.928-83.679 0-112.044-23.402-28.366-23.401-28.366-111.335h364.498q1.419-8.509 2.837-26.238 1.418-17.728 1.418-33.33 0-97.861-39.002-163.102-39.003-65.241-197.851-65.241zM368.753 494.98q5.673-55.313 24.82-84.387 19.147-29.075 104.244-29.075 83.679 0 103.535 30.493 19.855 30.493 19.855 82.969H368.753zM974.36 0H46.803Q26.947 0 13.474 14.183 0 28.366 0 46.803v930.393q0 19.856 13.474 33.33Q26.947 1024 46.803 1024H974.36q19.856 0 33.329-13.474 13.474-13.474 13.474-33.33V46.803q0-18.437-13.474-32.62Q994.216 0 974.36 0zm12.764 977.196q0 5.673-3.545 9.219-3.546 3.546-9.219 3.546H46.803q-5.673 0-9.219-3.546-3.545-3.546-3.545-9.219V46.803q0-5.673 3.545-9.219 3.546-3.545 9.219-3.545H974.36q5.673 0 9.219 3.545 3.545 3.546 3.545 9.219z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Eboks;
