import styled from 'styled-components';
import { RddkTheme } from '../../Foundation';
import { Color } from '../../Foundation/Color';

export const ErrorMessage = styled.span.attrs({
  'aria-live': 'polite',
  role: 'alert',
})`
  font-family: ${RddkTheme.fonts.sans};
  color: ${Color.rdRed};
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
`;
