import React from 'react';

/** @deprecated Use Icons.Action.Sort instead.*/
const Sort = () => (
  <svg viewBox="0 0 10 12" width="1em" height="1em">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M.757 7h8.486L5 11.243zM.757 5h8.486L5 .757z" />
    </g>
  </svg>
);

export default Sort;
