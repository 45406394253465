import React from 'react';

const Badge = () => (
  <svg viewBox="0 0 14 19" fill="none" width="1em" height="1em">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.024 6.174a.599.599 0 01.165.42.624.624 0 01-.165.421l-3.375 3.563a.562.562 0 01-.398.174.537.537 0 01-.399-.174L4.165 8.796A.598.598 0 014 8.376a.623.623 0 01.165-.42.562.562 0 01.398-.174.538.538 0 01.398.174l1.29 1.362 2.976-3.144A.561.561 0 019.626 6a.537.537 0 01.398.174z"
      fill="#000"
    />
    <path
      d="M0 2.375c0-.63.237-1.234.659-1.68A2.192 2.192 0 012.25 0h9c.597 0 1.169.25 1.591.696.422.445.659 1.05.659 1.679v16.031a.619.619 0 01-.08.305.577.577 0 01-.217.218.538.538 0 01-.577-.029L6.75 15.557.874 18.9a.542.542 0 01-.794-.189.619.619 0 01-.08-.305V2.375zm2.25-1.188c-.298 0-.585.126-.796.348-.21.223-.329.525-.329.84v14.922l5.313-2.947a.542.542 0 01.624 0l5.313 2.947V2.375c0-.315-.118-.617-.33-.84a1.096 1.096 0 00-.795-.347h-9z"
      fill="#000"
    />
  </svg>
);

export default Badge;
