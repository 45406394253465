import { css } from 'styled-components';
import { Color, Effects } from '../../Foundation';
import { RddkTheme } from '../../Foundation/Theme';

export const linkAnimatedUnderlineStyle = (
  textColor = Color.rdPureBlack,
  underlineColorPrimary?: Color,
  underlineColorSecondary?: Color
) => css`
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: ${textColor};
  font-family: ${RddkTheme.fonts.sans};
  font-weight: 600;
  line-height: 1.07;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.5s;
  ${Effects.Underline(underlineColorPrimary, underlineColorSecondary)}
`;

export const linkAnimatedUnderlineStyleHover = css`
  &:hover,
  &:focus {
    ${Effects.UnderlineHover}
  }
`;
