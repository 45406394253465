import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { RddkTheme, Typography } from '../../Foundation';
import { Color } from '../../Foundation/Color';
import { IconWrapper } from '../../Icons';
import { mediaQuery } from '../../Layout';

export type InputButtonProps = {
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  children?: React.ReactNode;
};

const StyledInputText = styled.div`
  cursor: pointer;
  display: inline-flex;
  padding: 0 ${`${RddkTheme.space[2]}px`};
  align-items: center;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  svg {
    width: 20px;
    height: 20px;
    ${mediaQuery.sm} {
      width: 24px;
      height: 24px;
    }
  }
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  &:not([disabled]):not(:checked):focus-visible {
    & ~ ${StyledInputText} {
      background-color: ${Color.rdLightRed};
    }
  }
  &:disabled {
    & ~ ${StyledInputText} {
      opacity: 0.4;
    }
  }
`;

const StyledLabel = styled.label`
  position: relative;
  &:hover {
    ${StyledInput}:not([disabled]) {
      & ~ ${StyledInputText} {
        background-color: ${Color.rdLightRed};
      }
    }
  }
`;

export const InputButton: FC<InputButtonProps> = ({
  children,
  disabled,
  onClick,
  iconLeft,
  iconRight,
  ...rest
}) => (
  <StyledLabel>
    <StyledInput
      type="button"
      disabled={disabled}
      onClick={onClick}
      {...rest}
    />
    <StyledInputText>
      {iconLeft && <IconWrapper mr={3}>{iconLeft}</IconWrapper>}
      <Typography type="h7">{children}</Typography>
      {iconRight && <IconWrapper ml={3}>{iconRight}</IconWrapper>}
    </StyledInputText>
  </StyledLabel>
);
