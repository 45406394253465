import React from 'react';

/** @deprecated Use Icons.Illustration.Facts instead.*/
const Info = () => (
  <svg viewBox="0 0 48 56" width="1em" height="1em">
    <path
      d="M23.542 42.192c-.854 0-1.428-.19-1.757-.58-.384-.459-.485-1.26-.3-2.387l2.055-12.536a.584.584 0 00-.129-.47.575.575 0 00-.44-.205H21.22l.045-.257 4.093-.681-2.003 12.5c-.257 1.53-.308 2.463.16 3.02.383.457.974.457 1.17.457 1.223 0 2.205-.996 3.158-2.2l.175.127c-1.494 2.36-2.701 3.212-4.475 3.212m5.586-3.84l-1.065-.768a.577.577 0 00-.793.12c-1.057 1.385-1.82 2.187-2.587 2.187-.24 0-.288-.043-.288-.043-.079-.095-.172-.49.097-2.082l2.13-13.303a.576.576 0 00-.662-.664l-5.29.88a.58.58 0 00-.473.475l-.23 1.342a.583.583 0 00.569.679h1.756l-1.944 11.86c-.248 1.498-.065 2.587.558 3.328.553.657 1.44.99 2.636.99 2.665 0 4.173-1.618 5.743-4.229a.582.582 0 00-.157-.773m-2.13-19.655c0 .828-.694 1.528-1.516 1.528-.821 0-1.515-.7-1.515-1.528 0-.828.694-1.528 1.515-1.528.822 0 1.517.7 1.517 1.528m-1.517 2.69c1.473 0 2.67-1.206 2.67-2.69 0-1.483-1.197-2.69-2.67-2.69-1.47 0-2.669 1.207-2.669 2.69 0 1.484 1.198 2.69 2.67 2.69m11.282 25.023c0 .32-.258.58-.577.58H11.812a.579.579 0 01-.577-.58V11.725c0-.321.258-.581.577-.581H17v2.739c0 .32.258.58.576.58h12.848c.318 0 .576-.26.576-.58v-2.74h5.188c.319 0 .577.26.577.582v34.684zM23.999 7.162c.992 0 1.797.624 2.05 1.492H21.95c.255-.868 1.059-1.492 2.049-1.492zM37.424 9.65H31v-.416c0-.32-.258-.58-.576-.58h-3.187C26.965 7.128 25.645 6 24 6c-1.644 0-2.964 1.129-3.235 2.654h-3.188a.58.58 0 00-.576.581v.416h-6.424a.578.578 0 00-.576.58v37.671c0 .32.258.58.576.58h26.848c.318 0 .576-.26.576-.58v-37.67a.579.579 0 00-.576-.581z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Info;
