import React from 'react';

const Graph = () => (
  <svg viewBox="0 0 17 13" fill="none" width="1em" height="1em">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.375 0c.11 0 .216.034.305.098a.54.54 0 01.194.26l3.751 10.515 1.626-4.559a.54.54 0 01.194-.258.524.524 0 01.305-.097h3.719c.14 0 .276.057.375.158a.547.547 0 010 .766.526.526 0 01-.375.159h-3.347l-1.998 5.601a.54.54 0 01-.194.259.524.524 0 01-.804-.259L6.375 2.128 4.749 6.686a.54.54 0 01-.194.258.524.524 0 01-.305.098H.531a.526.526 0 01-.375-.159.547.547 0 010-.766.526.526 0 01.375-.158h3.347L5.876.358a.54.54 0 01.194-.26A.524.524 0 016.375 0z"
      fill="#000"
    />
  </svg>
);

export default Graph;
