import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps as TypoProps,
} from 'styled-system';
import { TYPOGRAPHY_STYLES } from './styles';
import { TYPOGRAPHY_STYLES_AS_CSS } from './styles-css';
import { TextType } from './types';

export interface TypographyProps
  extends BackgroundProps,
    BorderProps,
    ColorProps,
    DisplayProps,
    LayoutProps,
    SpaceProps,
    TypoProps {
  type: TextType;
  fontWeight?: 400 | 500 | 600;
  fontStyle?: 'italic' | 'normal' | 'oblique';
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase';
  children?: any;
  as?: string;
}

const typographyProps = compose(
  background,
  border,
  color,
  display,
  layout,
  space,
  typography
);

export const Typography = styled.div.attrs<TypographyProps>((props) => {
  const style = TYPOGRAPHY_STYLES[props.type];
  return {
    as: props?.as || style?.renderAs || 'div',
  };
})<TypographyProps>`
  margin-top: 0;
  margin-bottom: 0;
  ${({ type }) => TYPOGRAPHY_STYLES_AS_CSS[type]}
  ${({ textTransform }) =>
    textTransform && `text-transform: ` + textTransform + `;`};
  ${typographyProps};
`;
