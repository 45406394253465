import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Color } from '../../Foundation';
import { Icons } from '../../Icons';
import { Container, mediaQuery, Visible } from '../../Layout';
import { LinkItem } from './types';

type MobileMenuOverlayProps = {
  links: LinkItem[];
  show: boolean;
  onClearFocus: () => void;
  hasTopStrip?: boolean;
  onToggleMobileMenu: () => void;
};

export const MobileMenuOverlay: React.FC<MobileMenuOverlayProps> = ({
  links,
  show,
  onClearFocus,
  onToggleMobileMenu,
  hasTopStrip,
}) => {
  const onBackLinkClickHandler = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      onClearFocus();
    },
    [onClearFocus]
  );

  /**
   *  handle on click of login link item with callback action and hide the login drop down.
   * @param e synthetic event
   * @param link  the link item with callback action on click
   */
  const onLinkItemClickHandler = useCallback((e: any, link: LinkItem): void => {
    if (link.onClick !== undefined) {
      link.onClick(e);
    }
    onToggleMobileMenu();
    onClearFocus();
  }, []);

  return (
    <Visible xs sm md>
      <Wrapper active={show} hasTopStrip={hasTopStrip}>
        <Container>
          {links.map((link, i) => {
            const showBackLink = i === 0;
            const children = link.children || [];

            return (
              <Column key={`column${i}`}>
                {showBackLink ? (
                  <BackLinkTitle
                    as="a"
                    href={link.url}
                    onClick={onBackLinkClickHandler}
                  >
                    <ArrowIcon>
                      <Icons.ArrowLeft />
                    </ArrowIcon>
                    {link.name}
                  </BackLinkTitle>
                ) : (
                  <ColumnTitle>{link.name}</ColumnTitle>
                )}

                {children.map((subItem, i) => (
                  <div key={`link${i}`}>
                    {i + 1 === children.length && subItem.hasDivider ? (
                      <Divider />
                    ) : undefined}
                    <Link
                      href={subItem.url}
                      data-tracking-id={subItem.trackingcode}
                      onClick={(e) => onLinkItemClickHandler(e, subItem)}
                    >
                      {subItem.icon}
                      {subItem.name}
                      {subItem.label && <Label>{subItem.label}</Label>}
                    </Link>
                    {subItem.text && <Text>{subItem.text}</Text>}
                  </div>
                ))}
              </Column>
            );
          })}
        </Container>
      </Wrapper>
    </Visible>
  );
};

const Wrapper = styled.div<{ active?: boolean; hasTopStrip?: boolean }>`
  overflow-y: scroll;
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: #fff;
  padding-top: ${(props) => (props.hasTopStrip ? '125px' : '80px')};
  padding-bottom: 20px;
  box-shadow: ${(props) =>
    props.active ? '0 2px 24px 0 rgba(0, 0, 0, 0.11)' : 'none'};
  transform: translateX(${(props) => (props.active ? '0%' : '100%')});
  transition: transform 0.6s;
`;

const Column = styled.div`
  margin-bottom: 20px;
`;

const BackLinkTitle = styled.span`
  display: block;
  padding: 10px 0;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
`;

const ArrowIcon = styled.span`
  svg {
    width: 7px;
    height: 13px;
    position: relative;
    top: 1px;
    margin-right: 8px;
  }
`;

const ColumnTitle = styled(BackLinkTitle)`
  text-transform: uppercase;
  font-size: 12px;
  cursor: auto;
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 8px 0;
  text-decoration: none;
  font-size: 23px;
  font-weight: 600;
  color: #000;
  text-decoration: none;

  ${mediaQuery.sm} {
    font-size: 25px;
  }
`;

const Label = styled.span`
  display: inline-block;
  margin-left: 8px;
  background: ${Color.rdRed};
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
`;

const Text = styled.span`
  display: block;
  color: #616161;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
`;

const Divider = styled.div`
  background-color: ${Color.grey30};
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
