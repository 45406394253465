import React from 'react';

const Confirm = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M10 0c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0zm0 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm5 4.5c.2.2.3.5.1.7l-6.2 7.9c-.089.267-.178.296-.337.3H8.5c-.1 0-.3-.1-.4-.2l-3.3-3.4c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l2.9 3 5.9-7.5c.2-.2.5-.3.7-.1z"
        id="svg-confirm-a"
      />
    </defs>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <mask id="svg-confirm-b" fill="#fff">
        <use xlinkHref="#svg-confirm-a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#svg-confirm-a" />
      <g mask="url(#svg-confirm-b)" fill="currentColor">
        <path d="M0 0v20h20V0z" />
      </g>
    </g>
  </svg>
);

export default Confirm;
