import React, { TextareaHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { Color, RddkTheme } from '../../Foundation';
import { Icons, IconWrapper } from '../../Icons';
import { Box, Flex } from '../../Layout';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Input } from './components/Input';

export interface TextAreaProps {
  label: string | React.ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  value?: string;
  maxLength?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaStyled = styled(Flex)<{ disabled?: boolean; focused?: boolean }>`
  background-color: ${({ focused }) => (focused ? '#FFFFFF' : '#f4f4f4')};
  min-height: 222px;
  padding: 0 0 20px 0;
  border-radius: 5px;
  box-shadow: ${({ focused }) =>
    focused ? '0 2px 6px 0 rgba(0, 0, 0, 0.15)' : ''};
`;

const TextAreaInput = styled(Input)`
  resize: none;
  flex: 1;
  padding: 15px 20px 0 20px;
`;

const Label = styled(Box)<{
  isRequired: boolean;
}>`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  letter-spacing: -0.27px;
  color: ${Color.black};
  padding: 20px 20px 17px 20px;
  ${({ isRequired }) =>
    isRequired &&
    `:after {
    content:"*";
    color: ${Color.rdRed};
  }`}
`;

const Counter = styled(Box)<{ limitReached: boolean }>`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  letter-spacing: -0.27px;
  color: ${({ limitReached }) => (limitReached ? Color.rdRed : Color.black)};
  padding: 0 20px 0 20px;
`;

export const TextArea: React.FC<
  TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({
  label,
  disabled,
  errorMessage,
  value,
  onChange,
  maxLength,
  required,
  id,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength && event.target.value.length > maxLength) {
      return event.stopPropagation();
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Box>
      <TextAreaStyled
        flexDirection="column"
        disabled={!!disabled}
        focused={isFocused}
      >
        <Label as="label" htmlFor={id} isRequired={!!required}>
          {label}
        </Label>
        <IconWrapper width="100%" height="3px">
          <Icons.DividerHorizontal />
        </IconWrapper>
        <TextAreaInput
          as="textarea"
          id={id}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          value={value}
          onChange={handleOnChange}
          required={required}
          {...rest}
        />
        {maxLength && (
          <Counter limitReached={value ? value?.length >= maxLength : false}>
            {value?.length || 0}/{maxLength}
          </Counter>
        )}
      </TextAreaStyled>
      {errorMessage && (
        <Box mt={1} ml={20}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
    </Box>
  );
};
