import React from 'react';

const WriteText = () => (
  <svg viewBox="0 0 16 16" fill="none" width="1em" height="1em">
    <path
      d="M7.707 8.452H3.271l2.22-6.214 2.216 6.214zm.357 1l.228.64.783-.783L6.055.846a.6.6 0 00-1.13 0L1.017 11.788a.5.5 0 00.942.336l.953-2.672h5.151zM12.8 7l-4.83 4.83a2.196 2.196 0 00-.579 1.02l-.374 1.498a.917.917 0 00-.024.14 4.598 4.598 0 01-1.11-.088c-.074-.017-.1-.11-.067-.177.18-.349.233-1.074-.404-1.33-.86-.346-1.978.125-2.862.497-.366.154-.692.291-.944.347-.387.085-.848-.066-1.216-.25-.212-.106-.482.082-.36.286.22.367.614.737 1.326.825.82.102 1.391-.15 1.975-.41.4-.178.805-.358 1.3-.428.086-.012.145.09.112.17-.152.357-.133.894.316 1.245.518.404 2.191.51 3.313.183l1.221-.306c.387-.096.74-.296 1.021-.578l4.83-4.83A1.87 1.87 0 1012.8 7z"
      fill="#000"
    />
  </svg>
);

export default WriteText;
