import React from 'react';

const UpCollapse = () => (
  <svg viewBox="0 0 1024 529.339" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M973.166 528.842q-10.002 0-18.753-3.126-8.752-3.125-16.253-10.627L511.836 121.271 86.762 515.089q-16.252 15.003-37.506 13.753-21.254-1.251-35.006-16.253Q-.753 497.586.497 476.332q1.251-21.253 16.253-36.256L476.83 13.752Q491.832 0 511.836 0q20.003 0 35.006 13.752l460.08 426.324q16.252 15.003 16.878 35.631.625 20.629-13.128 36.882-7.501 8.751-17.503 12.502-10.001 3.751-20.003 3.751z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default UpCollapse;
