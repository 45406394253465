import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Color, Typography } from '../../Foundation';
import { Col, Container, Hidden, Row } from '../../Layout/Grid';
import { constants } from './constants';
import { LinkItem, OnClearDropdown } from './types';
import { useMenuNavigation } from './useMenuNavigation';

type DesktopLoginDropdownProps = {
  index: number;
  links: LinkItem[];
  showLoginItems: boolean;
  onClearFocus: () => void;
  onClearLoginDropdown: OnClearDropdown;
};

export const DesktopLoginDropdown: React.FC<DesktopLoginDropdownProps> = ({
  index,
  links,
  showLoginItems,
  onClearFocus,
  onClearLoginDropdown,
}) => {
  const loginRef = React.useRef<HTMLDivElement>(null);
  const { handleDropdownKeyDown } = useMenuNavigation(onClearLoginDropdown);

  /**
   * Callback for handling click events on the link.
   * This will stop event propagation to the outer Container.
   */
  const onClickHandler = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  /**
   *  handle on click of login link item with callback action
   * @param e synthetic event
   * @param link  the link item with callback action on click
   */
  const onLinkItemClickHandler = useCallback((e: any, link: LinkItem): void => {
    if (link.onClick !== undefined) {
      link.onClick(e);
    }
    onClearFocus();
  }, []);

  const handleKeyPress = (event: any, link: any) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      onLinkItemClickHandler(event, link);
    }
  };

  return (
    <Hidden xs sm md>
      <Container>
        <Wrapper
          active={showLoginItems}
          onClick={onClearFocus}
          id={`logindropdown${index}`}
          role="menu"
          aria-hidden={!showLoginItems}
        >
          <Row justify="end">
            <Col lg={3} xxl={2}>
              <Dropdown
                active={showLoginItems}
                onClick={onClickHandler}
                ref={loginRef}
              >
                {links.map((link, i) => {
                  return (
                    <>
                      {i + 1 === links.length && link.hasDivider ? (
                        <Divider key={`divider${i}`} />
                      ) : undefined}
                      <LinkRow key={`loginlink${i}`}>
                        <Link
                          href={link.url}
                          tabIndex={showLoginItems ? 0 : -1}
                          data-tracking-id={link.trackingcode}
                          onClick={(e) => onLinkItemClickHandler(e, link)}
                          onKeyDown={(event) => {
                            handleDropdownKeyDown(event, index);
                          }}
                          onKeyPress={(event) => {
                            handleKeyPress(event, link);
                          }}
                        >
                          <LinkContainer>
                            {link.icon}
                            <Typography type="h8">{link.name}</Typography>
                          </LinkContainer>
                          {link.text && <LinkText>{link.text}</LinkText>}
                        </Link>
                      </LinkRow>
                    </>
                  );
                })}
              </Dropdown>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </Hidden>
  );
};

const Wrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(${(props) => (props.active ? '0%' : '-100%')});
  transition: all ${constants.NAV_ANIMATION_TIME}ms;
`;

const Dropdown = styled.div<{ active?: boolean }>`
  padding: 30px;
  border-radius: 0 0 10px 10px;
  background: #fff;
  box-shadow: ${(props) =>
    props.active ? '0 2px 24px 0 rgba(0, 0, 0, 0.11)' : 'none'};
  transition: all ${constants.NAV_ANIMATION_TIME}ms;
`;

const LinkRow = styled.div`
  margin-top: 20px;
  :first-child {
    margin-top: 0;
  }
`;

const Link = styled.a`
  color: #212121;
  text-decoration: none;
  transition: color ${constants.LINK_COLOR_ANIMATION_TIME}ms;

  :hover {
    color: #000;
  }
`;

const LinkText = styled.span`
  margin-top: 5px;
  display: block;
  color: #616161;
  font-size: 14px;
  font-style: italic;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Divider = styled.div`
  background-color: ${Color.grey30};
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
