import { css } from 'styled-components';
import { Color } from '../../Foundation';
import { mediaQuery } from '../../Layout';
import { IndexerPosition, Size } from './types';

const tabStyleNormal = css`
  font-size: 16px;
  letter-spacing: -0.18px;
  font-weight: 600;

  ${mediaQuery.sm} {
    font-size: 23px;
    letter-spacing: -0.26px;
  }
  ${mediaQuery.lg} {
    font-size: 24px;
    letter-spacing: -0.27px;
  }
`;
const tabStyleSmall = css`
  font-size: 15px;
  letter-spacing: -0.17px;
  font-weight: 600;

  ${mediaQuery.sm} {
    font-size: 18px;
    letter-spacing: -0.19px;
  }
  ${mediaQuery.lg} {
    font-size: 20px;
    letter-spacing: -0.22px;
  }
`;

export type TabsWrapperStylesProps = {
  isDarkTheme: boolean;
  darkThemColor: string;
  indexerPosition: IndexerPosition;
};
export const tabsWrapperStyles = css<TabsWrapperStylesProps>`
  position: relative;
  display: inline-block;
  padding-bottom: 7px;

  ${({ isDarkTheme, darkThemColor }) =>
    isDarkTheme &&
    darkThemColor &&
    css`
      background-color: ${darkThemColor};
    `};

  ::before {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #f1e9e9, #f4f0f0);

    ${({ isDarkTheme }) =>
      isDarkTheme &&
      css`
        background: ${Color.coolGrey};
      `};
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 4px;
    background: ${Color.scarlet};
    transition: all 0.5s ease;
    left: ${({ indexerPosition }) => indexerPosition.left}px;
    width: ${({ indexerPosition }) => indexerPosition.width}px;
  }
`;

export type TableSelectorWrapperProps = {
  size: Size;
  scrollable: boolean;
};
export const tableSelectorWrapperStyles = css<TableSelectorWrapperProps>`
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-x: scroll;
      width: 100%;
    `}

  .table-selector__tab {
    ${({ size }) => (size === 'small' ? tabStyleSmall : tabStyleNormal)};

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ellipsisContainerStyles = css`
  position: relative;
`;

const ellipsisStyles = css`
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  height: 80%;
  width: 50px;
  transition: all 0.5s ease;
  opacity: 0;
  perspective: 500px;

  > svg {
    height: 12px;
  }
  &.ellipsis--visible {
    opacity: 1;
    transform: rotateY(0deg) translateX(0px);
  }
`;

type LeftEllipsisStylesProps = {
  isDarkTheme: boolean;
  darkThemeColor: string;
};
export const leftEllipsisStyles = css<LeftEllipsisStylesProps>`
  left: -1px;
  text-align: left;
  padding-left: 0;
  background: linear-gradient(
    to right,
    ${({ isDarkTheme, darkThemeColor }) =>
        isDarkTheme && darkThemeColor ? darkThemeColor : '#fff'}
      15%,
    transparent
  );
  transform-origin: left center;
  transform: rotateY(-90deg) translateX(-60px);
  ${ellipsisStyles}
  ${({ isDarkTheme }) =>
    isDarkTheme &&
    css`
      > svg {
        fill: #fff;
      }
    `}
`;

type RightEllipsisStylesProps = {
  isDarkTheme: boolean;
  darkThemeColor: string;
};
export const rightEllipsisStyles = css<RightEllipsisStylesProps>`
  right: -1px;
  text-align: right;
  padding-right: 0;
  background: linear-gradient(
    to left,
    ${({ isDarkTheme, darkThemeColor }) =>
        isDarkTheme && darkThemeColor ? darkThemeColor : '#fff'}
      15%,
    transparent
  );
  transform-origin: right center;
  transform: rotateY(90deg) translateX(60px);
  ${ellipsisStyles}
  ${({ isDarkTheme }) =>
    isDarkTheme &&
    css`
      > svg {
        fill: #fff;
      }
    `}
`;

type StyledButtonProps = {
  isDarkTheme: boolean;
};
export const buttonStyles = css<StyledButtonProps>`
  user-select: none;
  border: 0;
  outline: none;
  background: no-repeat;
  margin: 0 7px;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  transition: color 0.5s ease;

  color: ${({ isDarkTheme }) =>
    isDarkTheme ? Color.coolGrey : Color.rdPureBlack};
  &.table-selector__tab--selected {
    color: ${(props) =>
      props.isDarkTheme ? Color.rdBrightWhite : Color.scarlet};
  }
`;
