import React from 'react';

/** @deprecated Use Icons.Action.Message instead.*/
const Mail = () => (
  <svg viewBox="0 0 14 14" width="1em" height="1em">
    <g
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <path strokeLinecap="round" d="M.386 11.299h13V2.938h-13z" />
      <path d="M.386 2.938l6.635 4.654 6.365-4.654" />
    </g>
  </svg>
);

export default Mail;
