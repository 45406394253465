import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Color } from '../../Foundation/Color/Color';
import { Icons } from '../../Icons';
import { Hidden } from '../../Layout/Grid';
import { constants } from './constants';
import { LinkItem, OnLoginFocus } from './types';
import { useMenuNavigation } from './useMenuNavigation';

type DesktopLoginTopLevelProps = {
  navigationRef: React.RefObject<HTMLElement>;
  loginRef: React.RefObject<HTMLDivElement>;
  links: LinkItem[];
  showLoginItems: boolean;
  onLoginFocus: OnLoginFocus;
};

export const DesktopLoginTopLevel: React.FC<DesktopLoginTopLevelProps> = ({
  navigationRef,
  loginRef,
  links,
  showLoginItems,
  onLoginFocus,
}) => {
  const { handleTopLevelKeyDown } = useMenuNavigation();
  /**
   * Callback for handling click events on the link.
   * It will stop event propagation.
   */
  const onClickHandler = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      onLoginFocus();
    },
    [onLoginFocus]
  );

  return (
    <Hidden md sm xs>
      <Container ref={loginRef}>
        {links.map((link, i) => {
          const dropdownMenuId = `logindropdown${i}`;
          return (
            <Button
              key={`loginbutton${i}`}
              onClick={onClickHandler}
              onKeyDown={(event) => {
                handleTopLevelKeyDown({
                  event,
                  dropdownMenuId,
                  loginRef,
                  navigationRef,
                  onLoginFocus,
                });
              }}
              aria-expanded={showLoginItems}
              aria-haspopup={true}
              aria-controls={dropdownMenuId}
            >
              <Title>
                <UserIcon>
                  <Icons.Customer />
                </UserIcon>
                {link.name}
                <Underline active={showLoginItems} />
              </Title>
              <ArrowIcon>
                <Icons.ArrowRight />
              </ArrowIcon>
            </Button>
          );
        })}
      </Container>
    </Hidden>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
`;

const Underline = styled.span<{ active?: boolean }>`
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: ${(props) => (props.active ? '100%' : '0%')};
  height: 2px;
  background: ${Color.rdRed};
  transition: width ${constants.TOP_LEVEL_UNDERLINE_ANIMATION_TIME}ms;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  height: 70px;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  width: auto;
  appearance: none;
  background: transparent;
  white-space: nowrap;

  &:hover ${Underline}, &:focus ${Underline} {
    width: 100%;
  }
`;

const UserIcon = styled.span`
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const ArrowIcon = styled.span`
  svg {
    width: 10px;
    height: 10px;
    margin-left: 6px;
    margin-top: 4px;
    transform: rotate(90deg); /* TODO: change to real down icon */
  }
`;
