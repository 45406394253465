import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Typography } from '../../Foundation';
import type { FileUploadTexts } from './types';
import { Color } from '../../Foundation/Color';

export const Overlay: React.FC<FileUploadTexts> = ({ textOverlay }) => {
  return (
    <Container>
      <Typography type="h3">{textOverlay}</Typography>
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    transform: scale(0.97);
    opacity: 0;
  }
  to {
    transform: scale(1)
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 999999;
  background-color: #eefbf6;
  border: 2px dashed ${Color.green50};
  border-radius: 5px;
  padding: 10px;
  color: ${Color.grey90};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: ${fadeIn} 0.3s ease;
  animation-iteration-count: 1;
`;
