import React from 'react';

/** @deprecated Use Icons.Action.Update instead.*/
const Recalculate = () => (
  <svg viewBox="0 0 32 28" width="1em" height="1em">
    <defs>
      <path
        d="M29.252 14.147l1.461-1.461a.748.748 0 01.54-.243c.196 0 .376.08.538.243.14.14.209.313.209.522 0 .208-.07.382-.209.521l-3.27 3.235-3.234-3.235a.708.708 0 01-.209-.521c0-.209.07-.383.209-.522a.659.659 0 01.522-.243.66.66 0 01.521.243l1.392 1.357c-.279-1.577-.789-2.969-1.53-4.174a11.548 11.548 0 00-2.714-3.113 11.828 11.828 0 00-3.513-1.93 13.065 13.065 0 00-4-.697c-1.229 0-2.4.174-3.513.522a11.291 11.291 0 00-3.095 1.513c-.951.661-1.81 1.479-2.574 2.452a13.562 13.562 0 00-1.948 3.27.738.738 0 01-.418.417.768.768 0 01-.556 0 .738.738 0 01-.418-.417.768.768 0 010-.557 17.026 17.026 0 012.192-3.686 13.655 13.655 0 012.904-2.714 13.037 13.037 0 013.496-1.704 12.308 12.308 0 013.93-.556c1.577 0 3.096.266 4.557.8a12.977 12.977 0 014.017 2.226c1.194.974 2.209 2.18 3.044 3.617.834 1.415 1.39 3.026 1.67 4.835zm-26.47 3.826l-1.495 1.496a.81.81 0 01-.54.208.81.81 0 01-.538-.208.708.708 0 01-.209-.522c0-.209.07-.383.209-.522l3.27-3.235 3.234 3.235c.14.14.209.313.209.522 0 .209-.07.382-.209.522a.708.708 0 01-.522.208.708.708 0 01-.521-.208l-1.357-1.357c.278 1.577.8 2.968 1.565 4.174a11.637 11.637 0 006.192 4.974c1.298.44 2.631.672 4 .696a11.56 11.56 0 006.591-2.035 11.759 11.759 0 002.591-2.452 14.19 14.19 0 001.948-3.27.627.627 0 01.4-.417.815.815 0 01.574 0 .75.75 0 01.383.417c.07.186.07.371 0 .557a16.041 16.041 0 01-2.157 3.687 14.395 14.395 0 01-2.94 2.713 13.038 13.038 0 01-3.495 1.704c-1.252.394-2.562.58-3.93.556-1.554 0-3.073-.266-4.557-.8a13.071 13.071 0 01-3.982-2.226c-1.195-.974-2.209-2.168-3.044-3.582-.835-1.415-1.391-3.026-1.67-4.835z"
        id="svg-recalculate-a"
      />
    </defs>
    <g transform="translate(0 -2)" fill="none" fillRule="evenodd">
      <mask id="svg-recalculate-b" fill="#fff">
        <use xlinkHref="#svg-recalculate-a" />
      </mask>
      <use
        fill="currentColor"
        fillRule="nonzero"
        xlinkHref="#svg-recalculate-a"
      />
      <g mask="url(#svg-recalculate-b)">
        <path fill="#E2001A" d="M0 0h32v32H0z" />
      </g>
    </g>
  </svg>
);

export default Recalculate;
