import React from 'react';

const Message = () => (
  <svg viewBox="0 0 1024 817.761" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M653.489 411.279L972.44 93.527v637.902zm-241.011 39.569l9.592 9.592q17.986 17.986 41.368 28.178t49.761 10.192q25.18 0 48.562-9.592 23.382-9.593 41.368-27.579l14.389-14.388L936.468 767.4H95.925l316.553-316.552zm-35.972-35.972L51.56 738.623V87.532l324.946 327.344zm190.651 9.592q-10.792 11.991-24.581 17.387-13.789 5.396-29.377 5.396t-29.377-5.996q-13.789-5.995-25.78-16.787L87.532 50.361h856.131L567.157 424.468zM947.26 0H76.74Q45.564 0 22.782 22.183 0 44.365 0 76.74v664.281q0 32.375 22.782 54.557 22.782 22.183 53.958 22.183h870.52q31.176 0 53.958-22.183Q1024 773.396 1024 741.021V76.74q0-32.375-22.782-54.557Q978.436 0 947.26 0z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Message;
