import React from 'react';

/** @deprecated Use Icons.Action.ArrowRight instead.*/
const ArrowRight = () => (
  <svg viewBox="0 0 11 20" width="1em" height="1em">
    <path d="M1.477.125a.978.978 0 00-.664.257.953.953 0 00-.053 1.36l7.528 8.01L.761 17.74a.953.953 0 00.051 1.36.985.985 0 001.38-.051l8.144-8.641a.95.95 0 000-1.308L2.195.433a.987.987 0 00-.718-.308" />
  </svg>
);

export default ArrowRight;
