import React from 'react';

/** @deprecated Use Icons.Action.SortUp instead.*/
const Upwards = () => (
  <svg viewBox="0 0 14 12" width="1em" height="1em">
    <g fill="#000" fillRule="evenodd">
      <rect width={2} height={8} x={8} y={4} rx={0.5} />
      <rect width={2} height={9} x={4} y={3} rx={0.5} />
      <rect width={2} height={6} y={6} rx={0.5} />
      <rect width={2} height={12} x={12} rx={0.5} />
    </g>
  </svg>
);

export default Upwards;
