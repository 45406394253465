import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { RddkTheme } from '../../Foundation';
import { mediaQuery } from '../../Layout';
import ChevronDownDarkSvg from './ChevronDownDark.svg';
import ChevronDownLightSvg from './ChevronDownLight.svg';

export type OptionValue = string | number;

export interface Option {
  value: OptionValue;
  label: OptionValue;
  disabled?: boolean;
  hidden?: boolean;
}

interface SelectLayoutProps {
  isBold?: boolean;
  isDark?: boolean;
  hasBorder?: boolean;
}

export interface SelectProps
  extends SelectLayoutProps,
    InputHTMLAttributes<HTMLSelectElement> {
  options?: Array<Option>;
}

/**
 * Dropdown input element that allows te user to select a single
 * value from a list of options. This is a wrapper around the
 * HTML `<select>` tag.
 */
export const Select: React.FC<SelectProps> = ({
  options = [],
  isBold = false,
  isDark = false,
  hasBorder = false,
  ...rest
}) => {
  return (
    <SelectWrapper
      isBold={isBold}
      isDark={isDark}
      hasBorder={hasBorder}
      {...rest}
    >
      {options.map((option: Option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          hidden={option.hidden}
        >
          {option.label}
        </option>
      ))}
    </SelectWrapper>
  );
};

const SelectWrapper = styled.select<SelectLayoutProps>`
  background-color: transparent;
  font-family: ${RddkTheme.fonts.sans};
  color: ${(props) => (props.isDark ? '#fafafa' : 'black')};
  font-size: ${(props) => (props.isBold ? '1.063rem' : '1rem')};
  font-weight: ${(props) => (props.isBold ? 600 : 'normal')};
  padding-right: 10px;
  border-style: none;
  border-radius: 5px;
  border: ${(props) => (props.hasBorder ? '1px solid #9e9e9e' : 'initial')};
  text-overflow: ellipsis;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  /* Add custom arrow */
  background-image: ${(props) =>
    props.isDark
      ? `url("${ChevronDownDarkSvg}")`
      : `url("${ChevronDownLightSvg}")`};
  background-repeat: no-repeat;
  background-position: right 0 top 55%;
  width: -webkit-fill-available;

  ${(props) =>
    props.hasBorder &&
    css`
      background-position: right 20px top 55%;
      padding: 13px 35px 13px 20px;
      ${mediaQuery.md} {
        padding: 17px 35px 17px 20px;
      }
    `}

  & > option {
    color: black;
  }
`;
