import React from 'react';

const Pdf = () => (
  <svg viewBox="0 0 71.2 95.9" width="1em" height="1em">
    <path d="M68.192 95.969h-65.2c-1.6.1-3-1.2-3-2.8v-90.6c.2-1.6 1.5-2.7 3-2.6h42.9c.9-.1 1.7.1 2.5.5l21.7 17.7c.1.3.3.5.4.7.4.5.6 1.2.6 1.8v72.2c.1 1.6-1.2 3-2.8 3 0 .1-.1.1-.1.1zm-61.6-90.4c-.5 0-1 .5-1 1v82.8c0 .5.5 1 1 1h57.5c.5 0 1-.5 1-1v-64.1h-16.1c-1.6-.1-2.9-1.4-3-3v-16.7h-39.4z" />
    <path d="M18.692 76.269c-1.9 0-3.7-.8-5-2-2.2-2.1-2.8-5.3-1.5-8.1.8-1.7 2.1-3.2 3.6-4.3.5-.4 1-.8 1.4-1.3 1.7-1.1 3.4-2.1 5.3-2.9.7-.3 1.5-.7 2.3-1.1l1.5-4.6c.2-.8.4-1.7.5-2.5.1-.9.3-1.7.5-2.5-1.8-2.2-3.3-4.6-4.6-7.1-1.2-2.2-1.9-4.6-2-7.1.4-3 2.6-5.5 5.6-6.1.6-.2 1.3-.3 2-.3 2.3.1 4.5 1.1 6.1 2.8 1.5 2 2.2 4.6 2 7.1.1 2.9-.1 5.7-.5 8.6.3.3.6.7 1 1.1.7.9 1.6 1.8 2.6 2.5l3.5 3.5c2.8-.4 5.7-.6 8.6-.5 2.5 0 5 .7 7.1 2 2.6 1.8 3.6 5.1 2.5 8.1-.9 2.8-3.2 4.9-6.1 5.6-2.4-.1-4.8-.6-7.1-1.5-.8-.6-1.7-1.2-2.7-1.9-1.4-1-2.9-2-4.4-3.2-.8.3-1.7.4-2.5.5s-1.6.2-2.5.5l-4.6 1.6c-.4.8-.8 1.5-1.1 2.3-.8 1.9-1.8 3.6-2.9 5.3-1.3 2.2-3.2 3.9-5.6 5-.9.4-2 .6-3 .5zm7.6-14.7c-2.1.8-4.1 1.8-6.1 3-1.5.8-2.8 2-3.5 3.5-.4.9-.2 1.9.5 2.5.4.4 1 .7 1.6.7.3 0 .6-.1.9-.2 1.4-.8 2.4-2.1 3-3.5.6-.9 1.1-1.9 1.8-3 .5-.8 1.1-1.9 1.8-3zm25.2-5.5c-1.1 0-2.1.1-3.1.2-1.3.2-2.6.3-3.9.3 1.7 1.4 3.6 2.6 5.6 3.5 1.1.7 2.3 1.1 3.6 1.2.3 0 .6-.1.9-.2.7-.1 1.3-.5 1.7-1.1.1-.1.2-.3.4-.4.4-.9.2-1.9-.5-2.5-1.5-.7-3.1-1-4.7-1zm-19.2-7.6c-.2.8-.4 1.7-.5 2.5-.1.9-.3 1.7-.5 2.5-.5 1.5-1 3-1.5 5l.2-.1c1.4-.5 2.9-1 4.9-1.5 1.6-.6 3.3-.9 5-1-1.4-.9-2.6-2.1-3.5-3.5-1.1-.9-2.2-1.9-3.1-3-.4-.2-.7-.6-1-.9zm-3.7-17.4c-.3 0-.6.1-.9.2-1 .5-1.5 1-1.5 2 .1 1 .3 2 .6 3 .1.5.3 1 .4 1.5.4.6.8 1.2 1.1 1.9.7 1.3 1.5 2.5 2.4 3.7.4-2.3.6-4.7.5-7.1 0-1.6-.3-3.1-1-4.5-.3-.4-.9-.6-1.6-.7z" />
  </svg>
);

export default Pdf;
