import React, { ChangeEvent, FC } from 'react';
import { Typography } from '../../Foundation/Typography';
import {
  StyledCheckmark,
  StyledBg as StyledBaseBg,
  StyledInput,
  StyledLabel as StyledBaseLabel,
} from './RadioButtonBase';
import styled from 'styled-components';
import { Color } from '../../Foundation/Color';
import { BoxShadow } from '../../Foundation/BoxShadow';

export type RadioButtonBoxVariant = 'primary' | 'secondary';
export type RadioButtonBoxProps = {
  label: string;
  id: string;
  name: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  /** The variant of the radio button box */
  variant?: RadioButtonBoxVariant;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

type StyledLabelProps = {
  variant?: RadioButtonBoxVariant;
};

const StyledBg = styled(StyledBaseBg)<StyledLabelProps>`
  border: ${(props) =>
    props.variant === 'primary' ? '1px solid rgba(0, 0, 0, 0)' : '0px'};
`;

const StyledLabel = styled(StyledBaseLabel)<StyledLabelProps>`
  box-shadow: ${(props) =>
    props.variant === 'primary' ? BoxShadow.grey : 'none'};
  border: ${(props) =>
    props.variant === 'primary' ? '' : '1px solid #9e9e9e'};
  &:hover {
    ${StyledInput}:not([disabled]) {
      & ~ ${StyledBg} {
        border-color: ${(props) =>
          props.variant === 'primary' ? Color.scarlet : ''};
        background-color: ${Color.rdLightRed};
      }
      & ~ ${StyledCheckmark} {
        border-color: ${Color.scarlet};
      }
    }
  }
`;

export const RadioButtonBox: FC<RadioButtonBoxProps> = ({
  children,
  label,
  id,
  name,
  value,
  checked,
  disabled,
  onChange,
  variant = 'primary',
  ...rest
}) => (
  <StyledLabel variant={variant} htmlFor={id}>
    <StyledInput
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      {...rest}
    />
    <Typography type="list" fontWeight={600}>
      {label}
    </Typography>
    <StyledCheckmark />
    <StyledBg variant={variant} />
  </StyledLabel>
);
