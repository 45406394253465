import React from 'react';

const HousePrivate = () => (
  <svg viewBox="0 0 1024 620" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M331 316h-46q-5 0-8.5 3.5T273 328v46q0 5 3.5 8.5t8.5 3.5h46q5 0 8.5-3.5t3.5-8.5v-46q0-5-3.5-8.5T331 316zm0 109h-46q-5 0-8.5 3.5T273 437v46q0 5 3.5 8.5t8.5 3.5h46q5 0 8.5-3.5t3.5-8.5v-46q0-5-3.5-8.5T331 425zm111-109h-46q-5 0-8.5 3.5T384 328v46q0 5 3.5 8.5t8.5 3.5h46q5 0 8.5-3.5t3.5-8.5v-46q0-5-3.5-8.5T442 316zm0 109h-46q-5 0-8.5 3.5T384 437v46q0 5 3.5 8.5t8.5 3.5h46q5 0 8.5-3.5t3.5-8.5v-46q0-5-3.5-8.5T442 425zm570 195q5 0 8.5-3.5t3.5-8.5q0-5-3.5-8.5t-8.5-3.5H865V270h36q4 0 6.5-2t4.5-5q1-3 1-6t-2-6L760 51q-1-2-3.5-3.5T751 46H638V12q0-5-3.5-8.5T626 0h-66q-5 0-8.5 3.5T548 12v34H273q-3 0-5.5 1.5T264 51L113 251q-2 3-2 6t1 6q2 3 4.5 5t6.5 2h36v326H12q-5 0-8.5 3.5T0 608q0 5 3.5 8.5T12 620h1000zM147 246L279 70h466l132 176zm425 350V342h133v254zm157 0V330q0-5-3.5-8.5T717 318H560q-5 0-8.5 3.5T548 330v266H183V270h658v326H729zm-48-131q0 4-3.5 7.5T669 476q-5 0-8.5-3.5T657 465q0-5 3.5-8.5t8.5-3.5q5 0 8.5 3.5t3.5 8.5z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default HousePrivate;
