import { ReactNodeArray } from 'prop-types';
import React, { isValidElement } from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../Foundation';
import { Icons } from '../../Icons';

export type ArrowTypes = 'ArrowRight' | 'ArrowLeft';

type CarouselDotButtonProps = {
  onClick?: () => void;
  childrenNodeArray: ReactNodeArray;
  arrowType: ArrowTypes;
};

const Button = styled.button<Pick<CarouselDotButtonProps, 'arrowType'>>`
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus {
    outline: none;
  }
  ${(props) =>
    props.arrowType === 'ArrowLeft' &&
    css`
      margin-right: 5px;
      padding-right: 2px;
    `}
  ${(props) =>
    props.arrowType === 'ArrowRight' &&
    css`
      margin-left: 5px;
      padding-left: 2px;
    `}
  :disabled {
    cursor: not-allowed;
    svg {
      fill: ${Color.rdBlackTint6};
    }
  }
  :not(:disabled) {
    cursor: pointer;
    :focus,
    :hover {
      outline: none;
      background: #ddd;
    }
  }
  svg {
    stroke-width: 2px;
    fill: ${Color.rdRed};
    width: 7px;
    height: 14px;
  }
`;

const isDisabled = (
  childrenNodeArray: ReactNodeArray,
  arrowType: ArrowTypes
) => {
  const activeDot = childrenNodeArray.findIndex(
    (el) =>
      isValidElement<HTMLElement>(el) && el?.props.className === 'slick-active'
  );
  if (arrowType === 'ArrowLeft') {
    return activeDot === 0;
  } else if (arrowType === 'ArrowRight') {
    return activeDot + 1 === childrenNodeArray.length;
  }
  return false;
};

export const CarouselDotButton: React.FC<CarouselDotButtonProps> = ({
  childrenNodeArray,
  arrowType,
  onClick,
}) => {
  const ArrowIcon = Icons[arrowType];
  const ariaLabel = arrowType === 'ArrowLeft' ? 'Previous slide' : 'Next slide';
  return (
    <Button
      type="button"
      aria-label={ariaLabel}
      arrowType={arrowType}
      onClick={onClick}
      disabled={isDisabled(childrenNodeArray, arrowType)}
    >
      <ArrowIcon />
    </Button>
  );
};
