import React from 'react';

/** @deprecated Use Icons.Action.Calendar instead.*/
const CalendarAdd = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <g fill="#000" fillRule="nonzero">
      <path d="M9.569 16.5H2.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2.07a7.28 7.28 0 012 0V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 01-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 01-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.569a7.28 7.28 0 010-2z" />
      <path d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.75 9a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5c0 .138.112.25.25.25H20a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25V20z" />
    </g>
  </svg>
);

export default CalendarAdd;
