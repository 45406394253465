import React from 'react';

const HourGlass = () => (
  <svg viewBox="0 0 14 15" fill="none" width="1em" height="1em">
    <path
      d="M1.75 1.813a.437.437 0 01.438-.438h9.624a.438.438 0 010 .875h-.874v.875A3.937 3.937 0 018.7 6.678c-.254.121-.387.33-.387.516v.612c0 .187.134.395.387.516a3.938 3.938 0 012.238 3.553v.875h.874a.438.438 0 010 .875H2.188a.438.438 0 010-.875h.874v-.875A3.937 3.937 0 015.3 8.322c.254-.121.388-.33.388-.516v-.612c0-.187-.135-.395-.388-.516a3.937 3.937 0 01-2.237-3.553V2.25h-.876a.437.437 0 01-.437-.438zm2.188.437v.875a3.062 3.062 0 001.74 2.763c.466.224.885.692.885 1.305v.614c0 .613-.419 1.08-.885 1.305a3.063 3.063 0 00-1.74 2.763v.875h6.124v-.875a3.062 3.062 0 00-1.74-2.763c-.466-.224-.884-.691-.884-1.305v-.614c0-.613.418-1.08.884-1.305a3.062 3.062 0 001.74-2.763V2.25H3.939z"
      fill="#000"
    />
  </svg>
);

export default HourGlass;
