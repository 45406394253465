import React from 'react';

/** @deprecated Use Icons.Action.CallInformation instead.*/
const Phone = () => (
  <svg viewBox="0 0 12 14" width="1em" height="1em">
    <path
      d="M1 4.232A2 2 0 011.732 1.5L3.897.25a.5.5 0 01.683.183l1.5 2.598a.5.5 0 01-.183.683l-1.299.75 3 5.196 1.3-.75a.5.5 0 01.682.183l1.5 2.598a.5.5 0 01-.183.683l-2.165 1.25A2 2 0 016 12.892l-5-8.66z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default Phone;
