import React from 'react';

/** @deprecated Use Icons.Illustration.ValueBasedPricing instead.*/
const Justice = () => (
  <svg viewBox="0 0 48 48" width="1em" height="1em">
    <path
      d="M35.228 23.793c-3.077 0-5.583-2.442-5.61-5.454h11.22c-.028 3.012-2.533 5.454-5.61 5.454zm5.088-6.594H30.133l5.073-7.64 5.11 7.64zM12.773 28.711c-3.077 0-5.582-2.442-5.609-5.454h11.218c-.027 3.012-2.532 5.454-5.61 5.454zm.054-14.27l5.048 7.676H7.683l5.144-7.676zm29.146 3.276a.557.557 0 00-.16-.336l.01-.007-5.928-8.863V8.51a.582.582 0 00-.695-.43l-10.687 2.384v-5.27c0-.02-.009-.034-.01-.054h1.096a.575.575 0 00.581-.57c0-.315-.26-.57-.581-.57h-3.281a.575.575 0 00-.581.57c0 .315.26.57.58.57h1.044c-.001.02-.01.035-.01.054v5.529l-10.874 2.425a.574.574 0 00-.268.15l-.006-.004-.014.022a.597.597 0 00-.12.18L6.12 22.373l.008.005a.543.543 0 00-.101.264 7.015 7.015 0 00-.026.566c0 3.662 3.038 6.642 6.773 6.642 3.734 0 6.773-2.98 6.773-6.642 0-.194-.01-.385-.027-.574a.556.556 0 00-.166-.34l.017-.01-5.45-8.289 9.43-2.103v26.846H14.78a.57.57 0 00-.575.563v2.254c0 .31.258.562.575.562h18.375a.569.569 0 00.574-.562v-2.254a.57.57 0 00-.574-.563h-8.642V11.633l9.306-2.075-5.245 7.9.007.005a.539.539 0 00-.1.261 7.061 7.061 0 00-.026.567c0 3.663 3.039 6.642 6.773 6.642 3.735 0 6.772-2.98 6.772-6.642 0-.194-.01-.385-.027-.574z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Justice;
