export enum FILE_STATUS {
  UPLOADING,
  DONE,
  ERROR,
  CANCELLED,
}

export type ErrorCode =
  | 'upload-cancelled'
  | 'file-too-large'
  | 'file-too-small'
  | 'too-many-files'
  | 'file-invalid-type'
  | 'invalid-upload-params'
  | 'upload-failed'
  | string;

export interface FileWithMeta {
  id: string;
  file: File;
  xhr?: XMLHttpRequest;
  name: string;
  size: number;
  type: string;
  uploadedDate: string;
  lastModifiedDate: string;
  status: FILE_STATUS;
  percent: number;
  error?: ErrorCode;
  /**
   * The request response is saved after upload success
   */
  response?: any;
}

export interface IUploadParams {
  url: string;
  method?: string;
  body?: string | FormData | ArrayBuffer | Blob | File | URLSearchParams;
  fields?: { [name: string]: string | Blob };
  headers?: { [name: string]: string };
}

export type FileUploadTexts = {
  /**
   * The text before the input.
   */
  textBeforeInput?: string;
  /**
   * The text displayed in the box with dashed border.
   */
  textInputBox?: string;
  /**
   * The text on the button.
   */
  textInputButton?: string;
  /**
   * The text display below the box with dashed border.
   */
  textAfterInput?: string;
  /**
   * The text/title before the list of files/previews.
   */
  textBeforeFiles?: string;
  /**
   * The text on the submit button.
   */
  textSubmit?: string;
  /**
   * The text in the dropzone overlay when file is OK.
   */
  textOverlay?: string;
  /**
   * Dictionary of error texts.
   * The key is the ErrorCode and the value is the message to be displayed.
   */
  errorTexts?: { [errorCode: string]: string };
};

export type FileUploadProps = FileUploadTexts & {
  /**
   * Allow multiple files to be selected at the same time.
   */
  multiple?: boolean;
  /**
   * Disable the upload form.
   */
  disabled?: boolean;
  /**
   * The "accept" attribute of file input (which file types are accepted).
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
   */
  accept?: string;
  /**
   * Minimum file size in bytes (1024 * 1024 = 1MB).
   */
  minSize?: number;
  /**
   * Maximum file size in bytes (1024 * 1024 = 1MB)
   */
  maxSize?: number;
  /**
   * The max amount of files to be uploaded in total.
   */
  maxFiles?: number;
  /**
   * Disable the drag-n-drop functionality.
   */
  noDrag?: boolean;
  /**
   * If files, pass the filelist to display files.
   */
  fileList?: FileWithMeta[];
  /**
   * Show/Hide Submit Button based on the design
   */
  hideSubmitButton?: boolean;
  /**
   * If files should be cleared after submitting.
   */
  clearOnSubmit?: boolean;
  /**
   * The key/property name for the file in the upload Form Data (default is "file")
   */
  formDataFileKey?: string;
  /**
   * Function to validate the file in any way you want.
   * Return null if file is valid.
   * Return a string with the error code if the file is invalid.
   * @param file The file that is being uploaded
   */
  validate?(file: File): string | null;
  /**
   * Function that is called before uploading.
   * Should return params needed for upload: { fields (object), headers (object), method (string), url (string) };
   * Omit to disable upload functionality.
   * @param file The file that is being uploaded
   */
  getUploadParams?(file: FileWithMeta): IUploadParams | Promise<IUploadParams>;
  /**
   * Callback for when an upload is cancelled.
   * @param file The file that is being uploaded.
   */
  onCancel?(file: FileWithMeta): void | Promise<void>;
  /**
   * Callback for when an upload has an error.
   * @param file The file that is being uploaded.
   */
  onUploadError?(file: FileWithMeta): void | Promise<void>;
  /**
   * Callback for when an upload succeeds.
   * Return void or null if file is valid.
   * Return an error code (string) if upload should be marked as error.
   * @param file The file that is being uploaded.
   * @param response The response
   */
  onUploadSuccess?(
    file: FileWithMeta,
    response: any
  ): void | null | string | Promise<null | string>;
  /**
   * Called when user removes a file. Should return a boolean whether it was succesful or not.
   * @param file The file that will be removed.
   */
  onRemove?(file: FileWithMeta): boolean | Promise<boolean>;
  /**
   * Called when user presses submit button.
   * @param files The array of files whose status is DONE
   */
  onSubmit?(files: FileWithMeta[]): void | Promise<void>;
  /**
   * Displays fullscreen drag area when something i dragged into the browser window.
   * (default behavior only displays fullscreen drag area, when something is dragged into the component)
   */
  fullscreenOnWindowDragEnter?: boolean;
};
