import React from 'react';

const SearchWeb = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <path
      d="M15.5 14l5 5-1.5 1.5-5-5v-.79l-.27-.28A6.47 6.47 0 019.5 16 6.5 6.5 0 1116 9.5c0 1.61-.59 3.09-1.57 4.23l.28.27h.79zm-6-9.5l-.55.03c-.24.52-.61 1.4-.88 2.47h2.86c-.27-1.07-.64-1.95-.88-2.47-.18-.03-.36-.03-.55-.03zM13.83 7a4.943 4.943 0 00-2.68-2.22c.24.53.55 1.3.78 2.22h1.9zM5.17 7h1.9c.23-.92.54-1.69.78-2.22A4.943 4.943 0 005.17 7zM4.5 9.5c0 .5.08 1.03.23 1.5h2.14l-.12-1.5.12-1.5H4.73c-.15.47-.23 1-.23 1.5zm9.77 1.5c.15-.47.23-1 .23-1.5s-.08-1.03-.23-1.5h-2.14c.16.994.16 2.006 0 3h2.14zm-6.4-3l-.12 1.5.12 1.5h3.26c.16-.994.16-2.006 0-3H7.87zm1.63 6.5c.18 0 .36 0 .53-.03.25-.52.63-1.4.9-2.47H8.07c.27 1.07.65 1.95.9 2.47l.53.03zm4.33-2.5h-1.9c-.23.92-.54 1.69-.78 2.22A4.943 4.943 0 0013.83 12zm-8.66 0a4.943 4.943 0 002.68 2.22c-.24-.53-.55-1.3-.78-2.22h-1.9z"
      fill="#000"
    />
  </svg>
);

export default SearchWeb;
