import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Color } from '../../Foundation';
import { IconWrapper } from '../../Icons';

const StyledDiv = styled.div`
  background-color: ${Color.white};
  color: ${Color.grey60};
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
  border: 2px solid ${Color.grey60};

  .active & {
    color: ${Color.white};
    background-color: ${Color.red40};
    border-color: ${Color.red40};

    svg {
      path {
        fill: white;
      }
    }
  }

  &.completed {
    background-color: ${Color.red40};
  }

  &.clickable {
    cursor: pointer;
  }
`;

export type StepIconSize = 'small' | 'medium' | 'large';

export type StepIconProps = {
  /**
   * The Icon to display.
   */
  icon: ReactNode;
  /**
   * The size of the icon. Can be "small", "medium", "large" or a custom value
   * @default medium
   */
  size?: StepIconSize | string;

  /**
   *
   * A click handler method, for when the icon is clicked
   */
  onClick?: () => void;
};

export const StepIcon = ({ size, onClick, icon }: StepIconProps) => {
  //const size: StepIconSize = size ?? 'medium';
  const hasClickHandler = onClick ?? false;

  const iconSize = () => {
    if (size === 'small') return '14px';
    if (size === 'medium') return '20px';
    if (size === 'large') return '44px';
    if (size === undefined) return '20px';
    return size;
  };

  return (
    <>
      <StyledDiv
        className={clsx({ clickable: hasClickHandler })}
        onClick={onClick}
      >
        <IconWrapper size={iconSize()}>{icon}</IconWrapper>
      </StyledDiv>
    </>
  );
};
