import React from 'react';

const CloseDark = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <circle id="svg-closedark-a" cx={12} cy={12} r={12} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="svg-closedark-b" fill="#fff">
        <use xlinkHref="#svg-closedark-a" />
      </mask>
      <use fill="#000" filter="url(#filter-3)" xlinkHref="#svg-closedark-a" />
      <g mask="url(#svg-closedark-b)" fill="#212121">
        <path d="M0 0h24v24H0z" />
      </g>
      <g fill="#F5F5F5" fillRule="nonzero">
        <path d="M15.268 8l.684.689L8.685 16 8 15.311z" />
        <path d="M8.732 8l-.684.689L15.315 16l.685-.689z" />
      </g>
    </g>
  </svg>
);

export default CloseDark;
