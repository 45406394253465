import React from 'react';

/** @deprecated Use Icons.Action.Logon instead.*/
const Lock = () => (
  <svg viewBox="0 0 17 18" width="1em" height="1em">
    <path
      d="M16 7v10H1V7h15zM8.667 1c.91 0 1.738.353 2.353.93a3.434 3.434 0 011.086 2.322h0L12.114 7H5.222V4.444c0-.95.386-1.812 1.01-2.435A3.428 3.428 0 018.666 1z"
      stroke="#000"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    />
  </svg>
);

export default Lock;
