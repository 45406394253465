import React from 'react';

const HandOffer = () => (
  <svg viewBox="0 0 32 14" fill="none" width="1em" height="1em">
    <path
      d="M30.796 4.225a3.105 3.105 0 00-1.264-.55 3.168 3.168 0 00-1.387.045L22.01 5.072a3.25 3.25 0 00.2-1.605 3.298 3.298 0 00-.6-1.51 3.483 3.483 0 00-1.263-1.07A3.648 3.648 0 0018.712.5h-6.788a4.19 4.19 0 00-1.557.295 4.053 4.053 0 00-1.319.848L5.901 4.66H1.898a1.94 1.94 0 00-1.342.533A1.783 1.783 0 000 6.48v5.2c0 .483.2.946.556 1.287a1.94 1.94 0 001.342.533H16a.846.846 0 00.198-.023l8.678-2.08a.993.993 0 00.12-.04L30.26 9.21l.045-.02a3.03 3.03 0 001.158-.966 2.83 2.83 0 00.28-2.838 2.961 2.961 0 00-.947-1.159v-.002zM1.627 11.68v-5.2c0-.069.029-.135.08-.184a.277.277 0 01.191-.076h3.526v5.72H1.898a.277.277 0 01-.191-.076.254.254 0 01-.08-.184zm27.97-3.9l-5.18 2.115L15.9 11.94H7.051V5.764l3.147-3.019c.226-.218.495-.39.791-.508.297-.118.614-.178.935-.177h6.788c.503 0 .986.192 1.342.533.356.341.556.804.556 1.287 0 .483-.2.946-.556 1.287a1.94 1.94 0 01-1.342.533h-3.797a.832.832 0 00-.575.228.764.764 0 00-.239.552c0 .207.086.405.239.552.152.146.36.228.575.228h4.339a.848.848 0 00.182-.02l9.084-2.003.033-.008c.342-.089.706-.054 1.023.098a1.4 1.4 0 01.693.728c.13.317.132.668.008.986a1.398 1.398 0 01-.68.739z"
      fill="#616161"
    />
  </svg>
);

export default HandOffer;
