import React from 'react';

const Hammer = () => (
  <svg viewBox="0 0 14 12" fill="none" width="1em" height="1em">
    <path
      d="M8.923 1.326A.433.433 0 008.78.837L8.62.724A4.53 4.53 0 006.763.035C5.56-.12 4.03.201 2.54 1.875h-.943a.454.454 0 00-.317.13L.131 3.137a.436.436 0 000 .62l2.3 2.268a.448.448 0 00.488.096.448.448 0 00.146-.096l1.15-1.134a.436.436 0 00.13-.31v-.562l7.505 7.802a.445.445 0 00.327.178.456.456 0 00.35-.128l1.342-1.319a.437.437 0 00.015-.606L5.716 2.358C6.384 1.957 7.3 1.62 8.5 1.62a.453.453 0 00.423-.294z"
      fill="#000"
    />
  </svg>
);

export default Hammer;
