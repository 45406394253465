/**
 * Formats bytes into human readable format like kb, MB or GB
 * @param b Bytes
 * @returns Formatted size in readable format
 */
export const formatBytes = (b = 0) => {
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = b;

  while (n >= 1024) {
    n /= 1024;
    l += 1;
  }

  return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`;
};
