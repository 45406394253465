import styled from 'styled-components';

/**
 * Use as a wrapper component for multiple Button component, in order to have equal margin between buttons.
 */
export const ButtonGroup = styled.div`
  & > a,
  & > button,
  & > span {
    margin: 0 10px 10px 0;
  }
`;
