import React from 'react';

const DashedSquare = () => (
  <svg viewBox="0 0 16 16" fill="none" width="1em" height="1em">
    <path
      d="M4.5 3A1.5 1.5 0 003 4.5a.5.5 0 11-1 0A2.5 2.5 0 014.5 2a.5.5 0 110 1zM3 11.5a.5.5 0 00-1 0A2.5 2.5 0 004.5 14a.5.5 0 000-1A1.5 1.5 0 013 11.5zm10.5-.5a.5.5 0 01.5.5 2.5 2.5 0 01-2.5 2.5.5.5 0 010-1 1.5 1.5 0 001.5-1.5.5.5 0 01.5-.5zm-2-9a.5.5 0 000 1A1.5 1.5 0 0113 4.5a.5.5 0 001 0A2.5 2.5 0 0011.5 2zm-5 .5A.5.5 0 017 2h2a.5.5 0 110 1H7a.5.5 0 01-.5-.5zM2 9a.5.5 0 101 0V7a.5.5 0 10-1 0v2zm4.5 4.5A.5.5 0 017 13h2a.5.5 0 010 1H7a.5.5 0 01-.5-.5zM13 9a.5.5 0 001 0V7a.5.5 0 00-1 0v2z"
      fill="#000"
    />
  </svg>
);

export default DashedSquare;
