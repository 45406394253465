import React from 'react';

/** @deprecated Use Icons.Action.Close instead.*/
const Close = () => (
  <svg viewBox="0 0 18 18" width="1em" height="1em">
    <path
      d="M15.793.793l1.414 1.414L10.414 9l6.793 6.793-1.414 1.414L9 10.414l-6.793 6.793-1.414-1.414L7.585 9 .793 2.207 2.207.793 9 7.585z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Close;
