import RCSlider, {
  Handle,
  SliderProps as RCSliderProps,
  RangeProps as RCRangeProps,
} from 'rc-slider';
import React, { CSSProperties } from 'react';
import type { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { Color, useWindowSize } from '../../Foundation';
import { Icons, IconWrapper } from '../../Icons';
import { Flex, breakpointsBootstrap } from '../../Layout';
import { Select } from '../Select';

interface SliderProps extends RCSliderProps {
  value: number;
  onChange: any;
  width?: string;
  selectWidth?: string; // set different width for select
  selectSuffix?: string; // suffix for options in select
  /**
   * The breakpoint (in pixels) where slider turns into a <select>
   */
  breakpoint?: number;
  inveredRailAndTrackStyle?: boolean;
}

interface RangeProps extends RCRangeProps {
  value: number[];
  onChange: any;
  width?: string;
  selectWidth?: string; // set different width for select
  selectSuffix?: string; // suffix for options in select
  /**
   * The breakpoint (in pixels) where slider turns into a <select>
   */
  breakpoint?: number;
  inveredRailAndTrackStyle?: boolean;
}

export function Slider({
  value,
  onChange,
  min = 0,
  max = 30,
  width = '100%',
  selectWidth,
  selectSuffix = '',
  disabled = false,
  breakpoint = breakpointsBootstrap.sm,
  inveredRailAndTrackStyle = false,
  ...rest
}: SliderProps | RangeProps) {
  const [windowWidth] = useWindowSize();

  const isRange = Array.isArray(value);

  const ifRangeThenArray = (object: CSSProperties): any => {
    return isRange ? [object, object] : object;
  };

  const onChangeSelect: ChangeEventHandler<HTMLSelectElement> = (
    event: React.FormEvent<HTMLSelectElement>
  ) => onChange(parseInt(event.currentTarget.value));

  if (windowWidth < breakpoint && !isRange) {
    const values = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    return (
      <div style={{ width: selectWidth || width }}>
        <Select
          onChange={onChangeSelect}
          options={values.map((optionValue: number) => ({
            label: optionValue + selectSuffix,
            value: optionValue,
          }))}
          disabled={disabled}
          value={value as number}
        />
      </div>
    );
  }

  const Slider = isRange ? RCSlider.Range : RCSlider;
  return (
    <Container width={width}>
      <Slider
        handle={({ dragging, ...restProps }: any) => {
          return (
            <Handle
              dragging={dragging.toString()}
              {...restProps}
              key={restProps.index}
            >
              <Flex justifyContent="center" marginTop="10px">
                <IconWrapper width="8px">
                  <Icons.Slider />
                </IconWrapper>
              </Flex>
            </Handle>
          );
        }}
        handleStyle={ifRangeThenArray({
          borderColor: 'white',
          height: 32,
          width: 32,
          marginTop: -14,
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.28)',
        })}
        railStyle={
          inveredRailAndTrackStyle
            ? { backgroundColor: Color.red50 }
            : { backgroundColor: '#686868', opacity: 0.23 }
        }
        trackStyle={
          inveredRailAndTrackStyle
            ? ifRangeThenArray({ backgroundColor: '#e0e0e0' })
            : ifRangeThenArray({ backgroundColor: Color.red50 })
        }
        min={min}
        max={max}
        allowCross={false}
        onChange={onChange}
        disabled={disabled}
        value={value as any}
        {...rest}
        step={rest.step as any}
        tabIndex={rest.tabIndex as number}
        defaultValue={rest.defaultValue as any}
        onAfterChange={rest.onAfterChange as any}
        onBeforeChange={rest.onBeforeChange as any}
      />
    </Container>
  );
}

const Container = styled.div<{ width: string }>`
  width: ${(props) => props.width};

  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 4px;
    border-radius: 6px;
  }
  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #abe2fb;
  }
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -5px;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #96dbfa;
    background-color: #fff;
    touch-action: pan-x;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #96dbfa;
  }
  .rc-slider-handle:focus {
    outline: none;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #96dbfa;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
  }
  .rc-slider-mark-text-active {
    color: #666;
  }
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }
  .rc-slider-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
  }
  .rc-slider-dot-active {
    border-color: #96dbfa;
  }
  .rc-slider-dot-reverse {
    margin-right: -4px;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
    100% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
  }
  .rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip-hidden {
    display: none;
  }
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  .rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
  }
`;
