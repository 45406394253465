import React from 'react';

/** @deprecated Use Icons.Illustration.ValueBasedPricing instead.*/
const Balance = () => (
  <svg viewBox="0 0 35 30" width="1em" height="1em">
    <path
      d="M18 29L18.5.5M28 14.914V4M7.5 16.5v-11m1 23h19M1 7l33.5-3.5M2 17c0 2.761 2.462 5 5.5 5s5.5-2.239 5.5-5H2zm21-2a5 5 0 0010 0H23z"
      stroke="#262626"
      strokeWidth={2.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default Balance;
