import styled, { css } from 'styled-components';

import { ResponsiveProps } from './ResponsiveProps';

export type SpaceSize =
  | 0
  | 4
  | 8
  | 12
  | 16
  | 24
  | 36
  | 48
  | 60
  | 80
  | 90
  | 120
  | 128;

export type ResponsiveSpacing = Partial<ResponsiveProps<SpaceSize>>;

export type SpaceProps = {
  flex?: boolean;
  top?: SpaceSize | ResponsiveSpacing;
  right?: SpaceSize | ResponsiveSpacing;
  bottom?: SpaceSize | ResponsiveSpacing;
  left?: SpaceSize | ResponsiveSpacing;
};

function applyStaticProp(
  applyCss: (value: number) => string,
  prop: SpaceSize | ResponsiveSpacing | undefined
) {
  if (!prop || !isSpaceSize(prop)) {
    return null;
  }
  return applyCss(prop);
}

function isSpaceSize(prop: SpaceSize | ResponsiveSpacing): prop is SpaceSize {
  return typeof prop === 'number';
}

function applyTop(size: number) {
  return `margin-top: ${size}px;`;
}

function applyRight(size: number) {
  return `margin-right: ${size}px;`;
}

function applyBottom(size: number) {
  return `margin-bottom: ${size}px;`;
}

function applyLeft(size: number) {
  return `margin-left: ${size}px;`;
}

function generateStaticStyles({ top, right, bottom, left, flex }: SpaceProps) {
  return css`
    box-sizing: border-box;
    ${flex && `display: flex;`}
    ${applyStaticProp(applyTop, top)}
    ${applyStaticProp(applyRight, right)}
    ${applyStaticProp(applyBottom, bottom)}
    ${applyStaticProp(applyLeft, left)}
  `;
}

export const Space = styled.div`
  ${(props: SpaceProps) => generateStaticStyles(props)}
`;
