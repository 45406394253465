import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Icons } from '../../Icons';

interface Option {
  value: string;
  label: string;
}

export interface Props {
  options: Option[];
  width?: string;
  placeHolder?: string;
  onSelect?: (selectedOptions: Option[]) => void;
}

const Container = styled.div<{ width: string }>`
  position: relative;
  width: ${(props) => (props.width ? props.width : '300px;')};
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 32px 8px 8px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid rgb(158, 158, 158);
  padding: 17px 35px 17px 20px;
  border-radius: 5px;
  margin-bottom: 5px;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const List = styled.ul`
  padding: 0;
  margin-top: 0;
  border-radius: 10px;
  box-shadow: 0px 13px 35px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  background: white;
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0px 2px 0px 0px;
    background: black;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
`;

const ListItem = styled.li<{ isSelected: boolean }>`
  padding: 8px;
  margin: 2px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#f0f0f0' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'black' : 'initial')};
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease, color 0.2s ease,
    border-color 0.2s ease;

  &:hover {
    transition: background-color 0.2s ease, color 0.2s ease,
      border-color 0.2s ease;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
      border-color: white;
    `}
`;

const SelectedIconWrapper = styled.div<{ isSelected: boolean }>`
  visibility: ${({ isSelected }) => (isSelected ? 'visible' : 'hidden')};
  margin-right: 4px;

  svg {
    height: 25px;
    width: 25px;
  }
`;

export const SearchableMultiSelect: React.FC<Props> = ({
  options,
  width,
  placeHolder,
  onSelect,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSelect = (option: Option) => {
    const isAlreadySelected = selectedOptions.find(
      (selected) => selected.value === option.value
    );
    if (!isAlreadySelected) {
      setSelectedOptions((prev) => {
        const newSelectedOptions = [...prev, option];
        onSelect?.(newSelectedOptions);
        return newSelectedOptions;
      });
    } else {
      setSelectedOptions((prev) => {
        const newSelectedOptions = prev.filter(
          (selected) => selected.value !== option.value
        );
        onSelect?.(newSelectedOptions);
        return newSelectedOptions;
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={ref} width={width ?? '300px'}>
      <Input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={() => setIsDropdownOpen(true)}
        placeholder={placeHolder ?? 'Search...'}
      />
      <SearchIconWrapper onClick={toggleDropdown}>
        <Icons.Action.DownExpand />
      </SearchIconWrapper>
      {isDropdownOpen && (
        <List>
          {options
            .slice()
            .sort((a, b) => {
              const aSelected = selectedOptions.some(
                (selected) => selected.value === a.value
              )
                ? 1
                : 0;
              const bSelected = selectedOptions.some(
                (selected) => selected.value === b.value
              )
                ? 1
                : 0;
              return bSelected - aSelected;
            })
            .filter((option) =>
              option.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((option) => {
              const isSelected = selectedOptions.some(
                (selected) => selected.value === option.value
              );
              return (
                <ListItem
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  isSelected={isSelected}
                >
                  {option.label}
                  <SelectedIconWrapper isSelected={isSelected}>
                    <Icons.Action.Check />
                  </SelectedIconWrapper>
                </ListItem>
              );
            })}
        </List>
      )}
    </Container>
  );
};
