import * as React from 'react';
import styled, { css } from 'styled-components';
import { Color, Typography } from '../../Foundation';
import { mediaQuery } from '../../Layout';
import { Button } from '../Button';
import { FileUploadTexts } from './types';

type Props = FileUploadTexts & {
  disabled: boolean;
  openDialog: () => void;
};

export const Input: React.FC<Props> = ({
  disabled,
  openDialog,
  textInputBox,
  textInputButton,
  textAfterInput,
}) => {
  return (
    <>
      <Container onClick={openDialog}>
        <BoxDashed disabled={disabled}>{textInputBox}</BoxDashed>
        <Button disabled={disabled}>{textInputButton}</Button>
      </Container>

      <HelpText>
        <Typography type="image">{textAfterInput}</Typography>
      </HelpText>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const BoxDashed = styled.div<{ disabled?: boolean }>`
  flex: 1;
  min-height: 56px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  background: #fff;
  border: 2px dashed ${Color.grey40};
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-style: italic;
  color: ${Color.grey80};
  transition: all 0.3s;

  :hover {
    background: #f2f2f2;
    cursor: pointer;
  }

  ${mediaQuery.sm} {
    font-size: 16px;
  }

  ${(props) =>
    props.disabled &&
    css`
      border-color: ${Color.grey30};
      color: ${Color.grey60};

      :hover {
        cursor: not-allowed;
      }
    `}
`;

const HelpText = styled.div`
  padding: 10px 22px;
`;
