import * as React from 'react';
import styled from 'styled-components';

export type ButtonRotatedProps = React.HTMLProps<HTMLButtonElement>;

/**
 * Button with dashed border that has a -90deg rotated text/content inside.
 * Used in conjunction with a flexbox layout and the button will be flexible in height.
 * Styles can be added/overriden with styled-components or "style" prop.
 */
export const ButtonRotated: React.FC<ButtonRotatedProps> = ({
  as,
  ref,
  type,
  children,
  ...rest
}) => {
  return (
    <Button {...rest}>
      <Text>{children}</Text>
    </Button>
  );
};

const Text = styled.span`
  transform: rotate(-90deg);
  font-size: 14px;
  color: #616161;
  transition: color 0.3s;
`;

const Button = styled.button`
  width: 80px;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 1px dashed #555;
  outline: none;
  transition: all 0.3s;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  :enabled {
    :hover,
    :active,
    :focus {
      cursor: pointer;
      background: #f1f1f1;

      ${Text} {
        color: #000;
      }
    }
  }
`;
