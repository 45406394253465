import React from 'react';

/** @deprecated Use Icons.Action.AddExtraUser instead.*/
const Customers = () => (
  <svg viewBox="0 0 24 19" width="1em" height="1em">
    <g
      transform="translate(1 1)"
      stroke="#D50018"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    >
      <circle cx={7.5} cy={4} r={4} />
      <path d="M0 17.5v-4l3-3L7.5 12l4.5-1.5 3 3v4M12.9.3a4.098 4.098 0 011.6-.3A4 4 0 1113 7.7m3 3.8l3-1 3 3v4" />
    </g>
  </svg>
);

export default Customers;
