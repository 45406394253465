import React from 'react';

/** @deprecated Use Icons.Action.Back instead.*/
const ArrowLeft = () => (
  <svg viewBox="0 0 11 21" width="1em" height="1em">
    <path d="M9.37 20.232a.98.98 0 00.664-.257.953.953 0 00.053-1.36l-7.529-8.01 7.528-7.988a.952.952 0 00-.052-1.36.986.986 0 00-1.38.051L.511 9.95a.95.95 0 00-.001 1.308l8.143 8.667a.98.98 0 00.717.308" />
  </svg>
);

export default ArrowLeft;
