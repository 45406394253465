import React from 'react';
import styled, { css } from 'styled-components';
import { Icons } from '../../../Icons';
import { Button } from '../../Button/Button';
import { Color } from '../../../Foundation/Color';
import { mediaQuery } from '../../../Layout';
import { LoadMoreResultsHandler } from './types';

type PaginationProps = {
  query?: string;
  numberOfPages?: number;
  currentPage?: number;
  hits?: number | null;
  handleLoadMoreResults: LoadMoreResultsHandler;
  showMoreLabel: string;
  resultsPerPage: number;
  pagesToShow: number;
  loadMoreStylePagination?: boolean;
};
export const Pagination: React.FC<PaginationProps> = ({
  query,
  numberOfPages = 0,
  currentPage,
  hits,
  handleLoadMoreResults,
  showMoreLabel,
  resultsPerPage,
  pagesToShow,
  loadMoreStylePagination = false,
}) => {
  const pagesArr = Array.from(Array(numberOfPages).keys());
  const [paginationFocus, setPaginationFocus] = React.useState({
    lower: currentPage ?? 1,
    upper: pagesToShow,
  });

  const onLoadMoreResultsClick = React.useCallback(
    (query, startFrom, count, accumulate = false) => {
      let lower = 1,
        upper = pagesToShow;
      let focusChanged = false;
      let focusStart = startFrom;
      if (focusStart === paginationFocus.lower - 1)
        focusStart = paginationFocus.lower;
      if (focusStart === paginationFocus.upper + 1)
        focusStart = paginationFocus.upper;

      if (focusStart === paginationFocus.lower && focusStart !== 1) {
        lower = focusStart - pagesToShow <= 0 ? 1 : focusStart - pagesToShow;
        upper = pagesToShow + (lower === 1 ? 0 : lower);
        focusChanged = true;
      } else if (focusStart === paginationFocus.upper) {
        lower = focusStart;
        upper = focusStart + pagesToShow;
        focusChanged = true;
      }
      if (focusChanged) {
        setPaginationFocus({
          lower,
          upper,
        });
      }

      handleLoadMoreResults(query, startFrom, count, accumulate);
    },
    [
      handleLoadMoreResults,
      setPaginationFocus,
      paginationFocus.lower,
      paginationFocus.upper,
      pagesToShow,
    ]
  );

  const loadNextPage = React.useCallback(
    (accumulate = false) => {
      if (currentPage && currentPage < numberOfPages) currentPage++;
      onLoadMoreResultsClick(query, currentPage, resultsPerPage, accumulate);
    },
    [query, currentPage, numberOfPages, onLoadMoreResultsClick, resultsPerPage]
  );

  const loadPrevPage = React.useCallback(() => {
    if (currentPage && currentPage > 1) currentPage--;
    const amount = resultsPerPage;
    onLoadMoreResultsClick(query, currentPage, amount);
  }, [query, currentPage, onLoadMoreResultsClick, resultsPerPage]);

  const getAriaLabel = (
    isCurrent: boolean,
    displayPage: number,
    numberOfPages: number
  ) =>
    isCurrent
      ? `Current Page, page ${displayPage}`
      : displayPage === numberOfPages
      ? 'Last page'
      : `Goto page ${displayPage}`;

  return numberOfPages === 0 || hits === null ? null : (
    <>
      <PaginationWrapper
        loadMoreStylePagination={loadMoreStylePagination}
        role="navigation"
        aria-label="Search Result Pagination Navigation"
      >
        {paginationFocus.lower >= pagesToShow && (
          <PrevPageButton
            aria-label="Load previous search results"
            onClick={loadPrevPage}
          >
            <Icons.Action.Back />
          </PrevPageButton>
        )}
        <ul>
          {pagesArr.map((page, i) => {
            const displayPage = page + 1;
            const isCurrent = displayPage === currentPage;

            if (
              displayPage < paginationFocus.lower ||
              displayPage > paginationFocus.upper
            )
              return null;

            return (
              <li className={isCurrent ? 'current-page' : ''} key={i}>
                <PageLink
                  role="button"
                  aria-current={isCurrent ? 'true' : 'false'}
                  aria-label={getAriaLabel(
                    isCurrent,
                    displayPage,
                    numberOfPages
                  )}
                  onClick={() => {
                    onLoadMoreResultsClick(query, displayPage, resultsPerPage);
                  }}
                >
                  {displayPage}
                </PageLink>
              </li>
            );
          })}
        </ul>
        {numberOfPages > 0 && (
          <NextPageButton
            aria-label="Load more search results"
            onClick={() => loadNextPage(false)}
          >
            <Icons.Action.Next />
          </NextPageButton>
        )}
      </PaginationWrapper>
      <LoadMoreWrapper loadMoreStylePagination={loadMoreStylePagination}>
        <Button
          aria-label="Load more search results"
          onClick={() => loadNextPage(true)}
          variant="secondary"
        >
          {showMoreLabel} <Icons.Action.DownExpand />
        </Button>
      </LoadMoreWrapper>
    </>
  );
};

const PrevPageButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  svg {
    margin-right: 15px;
  }
`;
const NextPageButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  svg {
    margin-left: 15px;
  }
`;
type LoadMoreWrapperProps = {
  loadMoreStylePagination: boolean;
};
const LoadMoreWrapper = styled.div<LoadMoreWrapperProps>`
  display: flex;
  margin-bottom: 80px;
  margin-top: 40px;
  ${(props) =>
    !props.loadMoreStylePagination &&
    css`
      ${mediaQuery.md} {
        display: none;
      }
    `}

  svg {
    margin-left: 10px;
  }
`;
type PaginationWrapperProps = {
  loadMoreStylePagination: boolean;
};
const PaginationWrapper = styled.nav<PaginationWrapperProps>`
  display: flex;
  margin-bottom: 80px;
  margin-top: 40px;

  ${(props) =>
    props.loadMoreStylePagination
      ? css`
          display: none;
        `
      : css`
          ${mediaQuery.mdDown} {
            display: none;
          }
        `}

  ul {
    display: flex;
    flex-direction: row;
  }

  li {
    list-style: none;
    display: flex;
    width: 35px;
    height: 48px;
    background-color: ${Color.white};
    border: 1px solid ${Color.grey30};
    border-radius: 5px;
    margin-right: 3px;

    &.current-page {
      background-color: ${Color.red40};
      color: ${Color.white};
    }
    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    align-self: center;
  }
`;
const PageLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
