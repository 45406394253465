import React from 'react';

const Bricks = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <mask
      id="svg-bricks-a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <path
        d="M3 22l-.048-.003a.948.948 0 01-.952-.95v-2.895c0-.526.426-.952.952-.952H3l2.809-.001v-2.8L3 14.4l-.048-.003a.948.948 0 01-.952-.95v-2.895c0-.526.426-.952.952-.952H3l2.809-.001v-2.8L3 6.8l-.048-.003A.948.948 0 012 5.847V2.953C2 2.426 2.426 2 2.952 2h14.239l.047.002a.95.95 0 01.953.95L18.19 5.8 21 5.8l.047.002a.95.95 0 01.953.95l-.001.03L22 6.8v2.848a.953.953 0 01-.841.946l-.111.006-2.858-.001v2.8L21 13.4l.047.002a.95.95 0 01.953.95l-.001 2.847v.017l.001.032a.953.953 0 01-.841.946l-.111.006-2.858-.001v2.849a.953.953 0 01-.84.946l-.112.006H3zm6.619-3.801H2.952V21h6.667v-2.801zm7.619 0h-6.667V21h6.667v-2.801zm-3.81-3.8h-2.857l-.952.001-2.858-.001v2.8h6.667v-2.8zm7.619 0H18.19l-.952.001-2.858-.001v2.8h6.667v-2.8zm-11.428-3.8H2.952v2.8h6.667v-2.8zm7.619 0h-6.667v2.8h6.667v-2.8zm-3.81-3.8H6.761v2.8h6.667v-2.8zm7.619 0H14.38v2.8h6.667v-2.8zM9.619 3H2.952v2.799h6.667V3zm7.619 0h-6.667v2.799h6.667V3z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#svg-bricks-a)">
      <path
        d="M3 22l-.048-.003a.948.948 0 01-.952-.95v-2.895c0-.526.426-.952.952-.952H3l2.809-.001v-2.8L3 14.4l-.048-.003a.948.948 0 01-.952-.95v-2.895c0-.526.426-.952.952-.952H3l2.809-.001v-2.8L3 6.8l-.048-.003A.948.948 0 012 5.847V2.953C2 2.426 2.426 2 2.952 2h14.239l.047.002a.95.95 0 01.953.95L18.19 5.8 21 5.8l.047.002a.95.95 0 01.953.95l-.001.03L22 6.8v2.848a.953.953 0 01-.841.946l-.111.006-2.858-.001v2.8L21 13.4l.047.002a.95.95 0 01.953.95l-.001 2.847v.017l.001.032a.953.953 0 01-.841.946l-.111.006-2.858-.001v2.849a.953.953 0 01-.84.946l-.112.006H3zm6.619-3.801H2.952V21h6.667v-2.801zm7.619 0h-6.667V21h6.667v-2.801zm-3.81-3.8h-2.857l-.952.001-2.858-.001v2.8h6.667v-2.8zm7.619 0H18.19l-.952.001-2.858-.001v2.8h6.667v-2.8zm-11.428-3.8H2.952v2.8h6.667v-2.8zm7.619 0h-6.667v2.8h6.667v-2.8zm-3.81-3.8H6.761v2.8h6.667v-2.8zm7.619 0H14.38v2.8h6.667v-2.8zM9.619 3H2.952v2.799h6.667V3zm7.619 0h-6.667v2.799h6.667V3z"
        fill="#000"
      />
    </g>
  </svg>
);

export default Bricks;
