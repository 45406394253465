import React from 'react';

/** @deprecated Use Icons.Action.UpCollapse instead.*/
const ArrowUp = () => (
  <svg viewBox="0 0 100 100" width="1em" height="1em">
    <path d="M45.1 13.3l-28 30c-1.5 1.6-1.4 4.1.2 5.7 1.6 1.5 4.1 1.4 5.7-.2l21-22.7V84c0 2.2 1.8 4 4 4s4-1.8 4-4V25.7l23.2 23.2c1.6 1.6 4.1 1.6 5.7 0C81.6 48 82 47 82 46s-.4-2-1.2-2.8l-30-30C50 12.4 49 12 47.9 12c-1.1 0-2.1.5-2.8 1.3z" />
  </svg>
);

export default ArrowUp;
