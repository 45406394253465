import React from 'react';

/** @deprecated Use Icons.Action.Next instead.*/
const Chiron = () => (
  <svg viewBox="0 0 12 14" width="1em" height="1em">
    <path
      d="M4.162 1.838L9.763 6.8l-5.601 4.962"
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chiron;
