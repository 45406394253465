import React from 'react';

const HomeAlt = () => (
  <svg viewBox="0 0 13 14" fill="none" width="1em" height="1em">
    <path
      d="M5 13.5V9.995c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-7a.5.5 0 00-.146-.354L11.5 4.794V1.5A.5.5 0 0011 1h-1a.5.5 0 00-.5.5v1.293L6.854.147a.5.5 0 00-.708 0l-6 6A.5.5 0 000 6.5v7a.5.5 0 00.5.5h4a.5.5 0 00.5-.5z"
      fill="#000"
    />
  </svg>
);

export default HomeAlt;
