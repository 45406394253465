import styled, { css } from 'styled-components';
import { BoxShadow, Color } from '../../Foundation';
import { mediaQuery } from '../../Layout';

export const Container = styled.div<{ bordered?: boolean }>`
  position: relative;
  margin: auto;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: ${BoxShadow.small};

  ${mediaQuery.sm} {
    box-shadow: ${BoxShadow.medium};
  }

  ${(props) =>
    props.bordered &&
    css`
      border: 1px solid #e4e4e4;
      border-bottom: 0;
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Scroller = styled.div`
  overflow-x: auto;
  overflow-y: visible;
`;

export const StyledThead = styled.thead<{
  isHeaderSticky?: boolean;
  width?: number;
}>`
  ${(props) =>
    props.isHeaderSticky &&
    `
      position: fixed;
      overflow: hidden;
      top: 0px;
      width: ${props.width}px;
      z-index: 100;
    `}
`;

export const RelativeRow = styled.tr<{ left?: number }>`
  position: relative;
  ${(props) => `left: ${props.left}px;`}
`;

export const StyledTable = styled.table<{ clone?: boolean; striped?: boolean }>`
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0;
    border-right: 0;
    background: #fff;
    color: #212121;
    vertical-align: top;
    text-align: left;

    ${mediaQuery.sm} {
      padding: 21px 20px;
    }
  }
  th {
    background: ${Color.grey90};
    color: #fafafa;
  }

  .sortable {
    cursor: pointer;
  }
  .fixed-column {
    border-left: 0;
  }

  ${(props) =>
    props.striped &&
    css`
      .odd td {
        background: ${Color.white};
      }
      .even td {
        background: ${Color.grey10};
      }
    `}

  span.prefix {
    display: block;
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 11px;

    ${mediaQuery.sm} {
      font-size: 12px;
    }
  }

  span.value {
    display: block;
    font-size: 14px;

    ${mediaQuery.sm} {
      font-size: 16px;
    }
  }

  span.suffix {
    display: block;
    margin-top: 3px;
    font-weight: normal;
    font-style: italic;
    opacity: 0.6;
    font-size: 11px;

    ${mediaQuery.sm} {
      font-size: 12px;
    }
  }

  /* CLONED TABLE */
  ${(props) =>
    props.clone &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;

      th,
      td {
        visibility: hidden;
      }

      .fixed-column {
        visibility: visible;
        pointer-events: auto;
        box-shadow: 4px 8px 8px 0 rgba(109, 109, 109, 0.35);
      }
    `}
`;
