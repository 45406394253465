import md5 from 'md5';
import { useCookies } from 'react-cookie';

const CONSENT_COOKIE_NAME = 'dbconsent';
const COOKIE_LONG_LIVE_DAYS = 360;
const COOKIE_SHORT_LIVE_DAYS = 90;
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export enum ConsentLevel {
  Necessary = 0,
  Functional = 1,
  Statistics = 2,
  Marketing = 3,
}

export type ConsentLevels = ConsentLevel[];

export enum ConsentType {
  Passive = 0,
  Active = 1,
}

type ConsentVersion = {
  layout?: string;
  content?: string;
};

type ConsentData = {
  levels: ConsentLevels;
  type: ConsentType;
  time: number;
  version: ConsentVersion;
  id: string;
  info: string;
};

export type useCookieConsentConfig = {
  levelsDefault?: ConsentLevels;
  levelMax?: ConsentLevel;
  logApiEndpoint?: string | null;
};

function getDomain() {
  let domain = '';
  if (document.location.host.search(/^localhost:\d{4}$/) === 0) {
    return '';
  }
  const parts: Array<string> = document.location.host.split('.');

  if (document.location.host.search(/co.uk/i) !== -1) {
    domain =
      parts[parts.length - 3] +
      '.' +
      parts[parts.length - 2] +
      '.' +
      parts[parts.length - 1];
  } else {
    domain = parts[parts.length - 2] + '.' + parts[parts.length - 1];
  }

  return domain.toLowerCase();
}

export const useCookieConsent = (config?: useCookieConsentConfig) => {
  const {
    levelsDefault = [ConsentLevel.Necessary],
    logApiEndpoint = '/api/cclog/',
  } = config || {};

  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);

  function init(): void {
    // Set initial consent level for first visits
    if (getLevelMax() < ConsentLevel.Necessary) {
      setConsent(levelsDefault, ConsentType.Passive, {});
    }
  }

  function getCookiePartByIndex(index: number): string {
    const arrCookieParts = (cookies[CONSENT_COOKIE_NAME] || '').split('.');
    return arrCookieParts.length >= index + 1 ? arrCookieParts[index] : '';
  }

  function getConsentLevels(): ConsentLevels {
    const currentLevelsStr = getCookiePartByIndex(0);
    let currentLevelsArr: string[] = [];
    if (currentLevelsStr.length > 0) {
      currentLevelsArr = currentLevelsStr.split('|');
    }
    return currentLevelsArr.map(Number);
  }

  function getLevelsSum(levels?: ConsentLevels): number {
    levels = Array.isArray(levels) ? levels : getConsentLevels();
    return levels.reduce((acc, val) => acc + val, 0);
  }

  function getLevelMax(levels?: ConsentLevels): number {
    levels = Array.isArray(levels) ? levels : getConsentLevels();
    return Math.max.apply(Math, levels);
  }

  function getConsentType(): ConsentType {
    return parseInt(getCookiePartByIndex(1)) || 0;
  }

  function setConsent(
    levels: ConsentLevels,
    type: ConsentType,
    version: ConsentVersion
  ) {
    const oldLevels = getConsentLevels();
    const oldType = getConsentType();

    // Skip if nothing has changed
    if (
      JSON.stringify(levels) === JSON.stringify(oldLevels) &&
      type === oldType
    ) {
      return;
    }

    // Gather data for cookie
    const tsShort = Math.round(Date.now() / 1000),
      tsLong =
        window.performance && window.performance.now
          ? window.performance.now()
          : Date.now(),
      ua = navigator.userAgent,
      loc = window.location.href,
      info = window.btoa ? window.btoa(loc + ' ' + ua) : '',
      id = md5(
        levels.toString() +
          type.toString() +
          tsLong.toString() +
          version.content +
          version.layout +
          info
      ).toString(),
      data: ConsentData = {
        levels,
        type,
        time: tsShort,
        version,
        id,
        info,
      };

    // Set cookie consent data
    setConsentCookie(data);

    // Log data if active consent
    if (type === ConsentType.Active) {
      log(data);
    }

    // Delete cookies if new level is lower than old level
    if (getLevelsSum(levels) < getLevelsSum(oldLevels)) {
      deleteCookies();
    }
  }

  function log(data: ConsentData): void {
    // Check logApiEndpoint from config. Skip if empty.
    if (!logApiEndpoint) {
      return;
    }

    fetch(logApiEndpoint + data.id, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  function setConsentCookie(data: ConsentData): void {
    // Set expiry time
    const expires = new Date();
    expires.setTime(
      expires.getTime() +
        (getLevelMax(data.levels) > ConsentLevel.Functional
          ? COOKIE_LONG_LIVE_DAYS
          : COOKIE_SHORT_LIVE_DAYS) *
          DAY_IN_MILLISECONDS
    );

    // Set cookie value
    const cookieValue =
      data.levels.join('|') +
      '.' +
      data.type +
      '.' +
      data.time +
      (data.type === 1 ? '.' + data.id : '');

    setCookie(CONSENT_COOKIE_NAME, cookieValue, {
      domain: getDomain(),
      expires,
      path: '/',
      sameSite: 'lax',
      encode: (v) => v,
    });
  }

  function deleteCookies(): void {
    if (typeof localStorage !== 'undefined') localStorage.clear();
    if (typeof sessionStorage !== 'undefined') sessionStorage.clear();
    if (typeof document === 'undefined') return;

    const cookies = document.cookie.split(';');
    for (const c of cookies) {
      const cookieName = c.split('=')[0];
      // If the cookie name contains our consent cookie name, then skip to next
      if (cookieName.indexOf(CONSENT_COOKIE_NAME) > -1) {
        continue;
      }
      // All other cookies are deleted now
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    }
  }

  function isAllowed(level: ConsentLevel): boolean {
    return getConsentLevels().indexOf(level) > -1;
  }

  return {
    init,
    getConsentLevels,
    getLevelMax,
    getConsentType,
    setConsent,
    deleteCookies,
    isAllowed,
  };
};
