import React from 'react';

const DocAlt = () => (
  <svg viewBox="0 0 16 17" fill="none" width="1em" height="1em">
    <path
      d="M11.333 2.833H4.667c-.737 0-1.334.635-1.334 1.417v9.208c0 .783.597 1.417 1.334 1.417h6.666c.737 0 1.334-.634 1.334-1.417V4.25c0-.782-.597-1.417-1.334-1.417z"
      stroke="#000"
    />
    <path
      d="M6 6.375h4M6 9.208h4m-4 2.834h2.667"
      stroke="#000"
      strokeLinecap="round"
    />
  </svg>
);

export default DocAlt;
