import React from 'react';

const Close = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M593.892 511.288L1006.91 99.694Q1024 82.604 1024 58.392q0-24.211-17.09-41.302Q989.819 0 965.608 0q-24.212 0-41.302 17.09L511.288 430.108 99.694 17.09Q82.604 0 58.392 0 34.181 0 17.09 17.09 0 34.181 0 58.392q0 24.212 17.09 41.302l413.018 411.594L17.09 924.306Q0 941.396 0 965.608q0 24.211 17.09 41.302 8.546 8.545 19.227 12.817Q46.999 1024 58.392 1024q11.394 0 22.075-4.273 10.682-4.272 19.227-12.817l411.594-413.018 413.018 413.018q8.545 8.545 19.227 12.817 10.681 4.273 22.075 4.273 11.393 0 22.075-4.273 10.681-4.272 19.227-12.817 17.09-17.091 17.09-41.302 0-24.212-17.09-41.302L593.892 511.288z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Close;
