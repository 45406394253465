import { Placement } from '@popperjs/core';
import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Color } from '../../Foundation/Color';
import { RddkTheme } from '../../Foundation/Theme';

export interface TooltipProps {
  element: ReactNode;
  content: ReactNode;
  heading?: string;
  placement?: Placement;
  autoWidth?: boolean;
  tooltipColor?: string;
  headingColor?: string;
  contentColor?: string;
}

export function Tooltip({
  element,
  content,
  heading,
  placement,
  autoWidth,
  tooltipColor,
  headingColor,
  contentColor,
}: TooltipProps) {
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );

  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
      {
        name: 'arrow',
        options: {
          element: '[data-popper-element]',
        },
      },
    ],
  });

  return (
    <TooltipCard ref={setTriggerElement}>
      {element}
      <TooltipBox
        color={tooltipColor}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        autoWidth={autoWidth}
      >
        <Arrow data-popper-element style={styles.arrow} color={tooltipColor} />
        {heading && (
          <TooltipHeading color={headingColor}>{heading}</TooltipHeading>
        )}
        <TooltipContent color={contentColor}>{content}</TooltipContent>
      </TooltipBox>
    </TooltipCard>
  );
}

const TooltipHeading = styled.strong<{ color: string | undefined }>`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 5px;
  display: block;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${({ color }) => color || Color.rdBlackTint2};
`;

const TooltipContent = styled.div<{ color: string | undefined }>`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 0.813rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.23;
  margin: 0;
  letter-spacing: normal;
  color: ${({ color }) => color || Color.grey90};
`;

const TooltipBox = styled.div<{ autoWidth?: boolean; color?: string }>`
  visibility: hidden;
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '252px')};
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ color }) => color || Color.rdBrightWhite};
  z-index: 2;
  padding: 20px;
`;

const Arrow = styled.div<{ color?: string }>`
  height: 0px;
  width: 0px;
  border-width: 11px;
  border-style: solid;
  pointer-events: none;
  [data-popper-placement^='top'] && {
    bottom: -20px;
    border-color: ${({ color }) => color || Color.rdBrightWhite} transparent
      transparent transparent;
  }
  [data-popper-placement^='right'] && {
    left: -20px;
    border-color: transparent ${({ color }) => color || Color.rdBrightWhite}
      transparent transparent;
  }
  [data-popper-placement^='bottom'] && {
    top: -20px;
    border-color: transparent transparent
      ${({ color }) => color || Color.rdBrightWhite} transparent;
  }
  [data-popper-placement^='left'] && {
    right: -20px;
    border-color: transparent transparent transparent
      ${({ color }) => color || Color.rdBrightWhite};
  }
`;

const TooltipCard = styled.div`
  display: inline-flex;
  & :hover + ${TooltipBox} {
    visibility: visible;
  }
`;
