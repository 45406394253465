import React from 'react';

/** @deprecated Use Icons.Action.Profile instead.*/
const User = () => (
  <svg viewBox="0 0 20 25" width="1em" height="1em">
    <g
      transform="translate(2 2)"
      stroke="#000"
      strokeWidth={2.5}
      fill="none"
      fillRule="evenodd"
    >
      <path d="M0 23v-3a7 7 0 017-7h2a7 7 0 017 7v3h0" />
      <circle cx={8.5} cy={4.5} r={4.5} />
    </g>
  </svg>
);

export default User;
