import { format, Locale } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../../../Foundation/Color/Color';
import { Typography } from '../../../Foundation/Typography/Typography';
import {
  dayCardActiveStyle,
  dayCardBaseStyle,
  dayCardDisabledStyle,
} from './DayCardStyles';

export interface DayCardProps {
  day: Date;
  disabled?: boolean;
  selected?: boolean;
  locale?: Locale;
}
enum DayCardDateStyles {
  weekDay = 'eee',
  monthDay = 'd',
  month = 'MMM',
}

const Card = styled.div<DayCardProps>`
  ${dayCardBaseStyle}
  ${({ disabled }) => disabled && dayCardDisabledStyle}
   ${({ selected }) => selected && dayCardActiveStyle}
`;

const { softPink, rdBlackTint2, rdPureBlack, rdBrightWhite } = Color;

export const DayCard: React.FC<DayCardProps> = (props) => {
  const { day, selected, locale } = props;
  const formatMonthName = (name: string) => {
    const firstLetterCapitalized = name[0].toUpperCase();
    const noFirstLetterName = name.substr(1);
    return (firstLetterCapitalized + noFirstLetterName).replace('.', '');
  };
  return (
    <Card {...props}>
      <Typography
        textAlign="center"
        fontSize={10}
        fontWeight={600}
        type="none"
        color={selected ? softPink : rdBlackTint2}
      >
        {format(day, DayCardDateStyles.weekDay, {
          locale,
        })
          .toUpperCase()
          .replace('.', '')}
      </Typography>
      <Typography
        textAlign="center"
        type="h5"
        color={selected ? rdBrightWhite : rdPureBlack}
      >
        {format(day, DayCardDateStyles.monthDay)}
      </Typography>
      <Typography
        textAlign="center"
        type="image"
        color={selected ? softPink : rdBlackTint2}
      >
        {formatMonthName(
          format(day, DayCardDateStyles.month, {
            locale,
          })
        )}
      </Typography>
    </Card>
  );
};
