import MaterialTab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { Color, RddkTheme } from '../../Foundation';
import { mediaQuery } from '../../Layout';

export const Tab = styled(MaterialTab)`
  &.MuiTab-root {
    color: ${Color.rdPureBlack};
    margin-bottom: 5px;
    text-transform: none;
    font-family: ${RddkTheme.fonts.serif};
    font-size: 0.938rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.17px;
    max-width: none;
    min-width: auto;
    border-bottom: 3px solid ${Color.whiteThree};
    /* mask-image: linear-gradient(to left, transparent 0, black 5em); */
  }
  &.Mui-disabled {
    color: #c9c5c5;
  }
  &.Mui-selected {
    color: ${Color.scarlet};
    border-bottom: 3px solid ${Color.rdRedHover};
  }
  ${mediaQuery.md} {
    &.MuiTab-root {
      font-size: 1.438rem;
      font-weight: 600;
      letter-spacing: -0.26px;
      border-bottom: 4px solid ${Color.whiteThree};
    }
    &.Mui-selected {
      color: #e02020;
      border-bottom: 4px solid ${Color.rdRedHover};
    }
  }
`;
