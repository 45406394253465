import { useEffect } from 'react';

export const useOnWindowResize = (callback: () => void) => {
  useEffect(() => {
    if (!callback) return;
    callback();

    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback]);
};
