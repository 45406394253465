import { css } from 'styled-components';
import { Color } from '../Color/Color';

export const Effects = {
  Underline: (
    underlineColorPrimary = Color.rdGrey,
    underlineColorSecondary = Color.rdPureBlack
  ) => css`
    ::before,
    ::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      height: 3px;
      background: ${underlineColorPrimary};
    }
    ::after {
      right: 100%;
      background: ${underlineColorSecondary};
      transition: right 0.5s;
      will-change: right;
    }
  `,
  UnderlineHover: css`
    ::after {
      right: 0%;
    }
  `,
};
