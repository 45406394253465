import React from 'react';

const BurgerMenu = () => (
  <svg viewBox="0 0 1024 910.068" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M967.034 796.136q22.231 0 38.209 14.589 15.978 14.588 18.757 35.43v6.947q0 23.62-16.673 40.293t-40.293 16.673H56.966q-23.62 0-40.293-16.673T0 853.102q0-23.62 16.673-40.293t40.293-16.673h910.068zm0-397.373q22.231 0 38.209 13.894 15.978 13.894 18.757 36.125v6.947q0 22.23-16.673 38.903t-40.293 16.673H56.966q-23.62 0-40.293-15.978T0 455.729q0-23.62 16.673-40.293t40.293-16.673h910.068zm0-398.763q22.231 0 38.209 14.589 15.978 14.589 18.757 35.43v6.947q0 23.62-16.673 40.293t-40.293 16.673H56.966q-23.62 0-40.293-16.673T0 56.966q0-23.62 16.673-40.293T56.966 0h910.068z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default BurgerMenu;
