import * as React from 'react';
import styled, { css } from 'styled-components';
import { IconWrapper, Icons } from '../../Icons';
// import ArrowUp from '../../Icons/generated/ArrowUp';
import { mediaQuery } from '../../Layout';
import { AnimatedButton } from '../Button/AnimatedButton';

const THRESHOLD_DEFAULT = 50;

export enum ButtonHorizontalPosition {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type ScrollToTopProps = {
  /**
   * (Optional) Percentage of the screen height the user should scroll down before showing the button.
   * Default is 50
   */
  threshold?: number;
  /**
   * (Optional) Set horizon postion of buttom to left, center or right (default)
   */
  horizontalPosition?: string;
  /**
   * (Optional) Force button to always be shown
   */
  showAlways?: boolean;
};

/**
 * Round button in the bottom right corner that pops up when the user scrolls below a certain
 * threshold. Clicking the button scrolls the user back to top.
 */
export const ScrollToTop: React.FC<ScrollToTopProps> = ({
  threshold = THRESHOLD_DEFAULT,
  horizontalPosition = ButtonHorizontalPosition.right,
  showAlways,
}) => {
  const [shouldShow, setShouldShow] = React.useState(false);

  const scrollToTopCallback = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const checkThresholdCallback = React.useCallback(() => {
    if (typeof window !== 'undefined' && Boolean(window.innerHeight)) {
      const thresholdPx = (window.innerHeight / 100) * threshold;
      const isBelowThreshold = window.pageYOffset >= thresholdPx;
      setShouldShow(isBelowThreshold);
    }
  }, [threshold]);

  React.useEffect(() => {
    window.addEventListener('scroll', checkThresholdCallback);
    return () => {
      window.removeEventListener('scroll', checkThresholdCallback);
    };
  }, [checkThresholdCallback]);

  return (
    <FixedAnimatedButton
      horizontalPosition={horizontalPosition}
      role="button"
      aria-label="Scroll to top of the page"
      onClick={scrollToTopCallback}
      style={{
        transform:
          showAlways || shouldShow ? 'translateY(0px)' : 'translateY(200px)',
      }}
    >
      <IconWrapper width={'30px'}>
        <Icons.Action.UpCollapse />
      </IconWrapper>
    </FixedAnimatedButton>
  );
};

type FixedAnimatedButtonProps = {
  horizontalPosition: string;
};

const FixedAnimatedButton = styled(AnimatedButton)<FixedAnimatedButtonProps>`
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  ${({ horizontalPosition }) => {
    switch (horizontalPosition) {
      case ButtonHorizontalPosition.left:
        return css`
          left: 20px;
          ${mediaQuery.sm} {
            left: 50px;
          }
        `;
      case ButtonHorizontalPosition.center:
        return css`
          left: calc(50% - 56px / 2);
        `;
      default:
        return css`
          right: 20px;
          ${mediaQuery.sm} {
            right: 50px;
          }
        `;
    }
  }}
  ${mediaQuery.sm} {
    bottom: 50px;
  }
`;
