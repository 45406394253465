import React from 'react';

/** @deprecated Use Icons.Action.BurgerMenu instead.*/
const Hamburger = () => (
  <svg viewBox="0 0 34 18" width="1em" height="1em">
    <path
      d="M0 5h34v2.5H0zm0-5h34v2.5H0zm0 10h34v2.5H0zm0 5h34v2.5H0z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default Hamburger;
