import React from 'react';

/** @deprecated Use Icons.Illustration.AdjustYourLoan instead.*/
const Money = () => (
  <svg viewBox="0 0 48 48" width="1em" height="1em">
    <path
      d="M21.5 21.626l-.64.401-.154-.2c-.112-.129-.233-.178-.356-.12a.543.543 0 00-.157.08c-.29.18-.5.37-.642.57l1.526 2.607-.68.426-1.897-3.24.614-.383c.039.051.097.12.171.216.134-.34.373-.623.73-.847.621-.388 1.043-.375 1.266.038.042.07.113.224.219.453m15.625 5.984l-6.155 5.975a.428.428 0 01-.302.122.422.422 0 01-.3-.122l-2.743-2.661a.404.404 0 010-.585.433.433 0 01.603 0l2.44 2.368 5.854-5.682a.433.433 0 01.603 0 .404.404 0 010 .585m-10.721 8.366h11.192A.406.406 0 0038 35.57V24.285a.405.405 0 00-.404-.407H26.404a.405.405 0 00-.404.407V35.57c0 .224.18.407.404.407m13.846 2.268H24.5V22.366h15.75v15.878zm.342-16.634H24.158a.41.41 0 00-.408.411V38.59a.41.41 0 00.409.411h16.433A.41.41 0 0041 38.59V22.02a.408.408 0 00-.408-.411zm-9.35-4.537L29 15.735l.302-.54.83.496 1.66-2.967-1.03-.41.298-.534 1.114.413.576.343-1.976 3.538.77.46-.301.54zm3.717-1.97c-.314-.163-.546-.25-.702-.267a.63.63 0 00-.516.186c-.14.129-.332.381-.576.76-.338.527-.496.888-.472 1.088.026.195.263.41.724.65.455.236.78.314.976.233.201-.077.468-.38.809-.912.34-.53.504-.888.488-1.065-.02-.205-.26-.428-.731-.673zm-1.809 3.055c-.698-.388-1.07-.77-1.138-1.147-.063-.348.13-.884.57-1.618.445-.744.85-1.179 1.206-1.299.352-.122.877.003 1.56.382.709.396 1.09.79 1.144 1.174.05.333-.146.861-.586 1.594-.438.734-.823 1.164-1.161 1.288-.367.136-.901.012-1.595-.374zM12.37 34.984L5.988 23.891 24.79 12.999l4.079 7.088h.963l-3.139-5.458a6.357 6.357 0 016.352-5.792c1.082 0 2.178.277 3.18.857 3.048 1.766 4.095 5.69 2.336 8.749a6.362 6.362 0 01-1.365 1.644h1.178c.336-.373.65-.773.911-1.225 1.989-3.46.804-7.897-2.642-9.894-3.446-1.995-7.87-.807-9.86 2.65-.344.6-.588 1.23-.747 1.87l-.732-1.271a.417.417 0 00-.57-.154L5.209 23.375a.418.418 0 00-.153.572l6.8 11.82a.42.42 0 00.57.152l10.408-6.03v-.968L12.37 34.984zM23 29.533v.788m6.39-10.223h-.78M20 25.628l-.945.599-2.319-.66-.131.083.9 1.559-.71.45L14 22.814l.71-.448 1.554 2.695.132-.084.426-1.96.855-.542-.544 2.364 2.867.789z"
      fill="#292929"
      fillRule="nonzero"
    />
  </svg>
);

export default Money;
