import React from 'react';

/** @deprecated Use Icons.Action.DownExpand instead.*/
const DownArrow = () => (
  <svg viewBox="0 0 12 14" width="1em" height="1em">
    <path
      d="M10.662 3.662L5.7 9.263.738 3.662"
      stroke="#000"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownArrow;
