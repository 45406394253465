import React from 'react';

const AddExtraUser = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <g fill="currentColor">
      <path d="M12 11.2h.2c-.1 0-.1-.1-.2-.1s-.1.1-.2.1h.2zm5.3 0c1.2-.8 1.9-2.2 1.9-3.7 0-2.5-2-4.5-4.5-4.5-.7 0-1.3.2-1.8.4l.7.8c.4-.1.7-.2 1.1-.2 1.9 0 3.5 1.6 3.5 3.5S16.7 11 14.7 11l.6 1h.7c2.8 0 5 2.2 5 5v3h-1.7v-3.1c0-.3-.2-.5-.5-.5s-.5.2-.5.5V20h-.8v1h4c.3 0 .5-.2.5-.5V17c0-2.9-2-5.2-4.7-5.8z" />
      <path d="M13.8 12c-.5-.4-1.2-.6-1.8-.8h-.2c.1 0 .1-.1.2-.1.3-.2.5-.4.7-.7.6-.8 1-1.8 1-2.9 0-1.1-.4-2.1-1-2.8-.2-.3-.5-.5-.7-.7-.8-.6-1.7-1-2.7-1-2.5 0-4.5 2-4.5 4.5 0 1.5.8 2.9 1.9 3.7C4 11.8 2 14.1 2 17v3.5c0 .3.2.5.5.5H16c.3 0 .5-.2.5-.5V17c0-2.1-1.1-3.9-2.7-5zm-8-4.5C5.8 5.6 7.3 4 9.3 4c.8 0 1.4.2 2 .7.3.2.5.4.7.7.5.6.8 1.3.8 2.2 0 .9-.3 1.4-.8 2-.2.3-.4.5-.7.7-.6.4-1.3.7-2 .7-2 0-3.5-1.6-3.5-3.5zM15.5 20h-1.7v-3.1c0-.3-.2-.5-.5-.5s-.5.2-.5.5V20H5.7v-3.1c0-.3-.2-.5-.5-.5s-.5.2-.5.5V20H3v-3c0-2.8 2.2-5 5-5h2.5c.5 0 1 .1 1.5.2 2 .6 3.5 2.5 3.5 4.7V20z" />
    </g>
  </svg>
);

export default AddExtraUser;
