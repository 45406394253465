import React from 'react';
import { SortDownIcon, SortIcon, SortUpIcon } from './CustomTableIcons';
import { Cell, SortDirection } from './types';

type TableCellProps = {
  tag: 'th' | 'td';
  cell: Cell;
  rowIndex: number;
  columnIndex: number;
  onClick?: () => void;
  isSorting?: boolean;
  sortDirection?: SortDirection;
};

export const TableCell = (props: TableCellProps) => {
  const {
    tag,
    cell,
    rowIndex,
    columnIndex,
    onClick,
    isSorting,
    sortDirection,
  } = props;

  const Tag = tag;

  // Create string of class names
  const classNameStr = [
    columnIndex === 0 ? 'fixed-column' : '',
    cell.sortable ? 'sortable' : '',
  ]
    .join(' ')
    .trim();
  const className = classNameStr !== '' ? classNameStr : undefined;

  // Memoize the styling object
  const style = React.useMemo(
    () => ({
      width: cell.width,
      minWidth: cell.minWidth,
      height: cell.height,
      verticalAlign: cell.alignVertically,
      textAlign: cell.alignHorizontally,
      ...cell.style,
    }),
    [cell]
  );

  // Cell render props
  const cellRenderProps = { cell, rowIndex, columnIndex };

  return (
    <Tag
      className={className}
      id={cell.id}
      onClick={onClick}
      style={style}
      colSpan={cell.colSpan}
      rowSpan={cell.rowSpan}
    >
      {cell.cellRender ? (
        cell.cellRender(cellRenderProps)
      ) : (
        <>
          {/* Render prefix */}
          {cell.prefix && <span className="prefix">{cell.prefix}</span>}

          {/* Render value */}
          <span className="value">
            {cell.cellValueRender
              ? cell.cellValueRender(cellRenderProps)
              : cell.value}

            {cell.sortable && (
              <>
                {isSorting ? (
                  <>
                    {sortDirection === 'asc' && <SortUpIcon />}
                    {sortDirection === 'desc' && <SortDownIcon />}
                  </>
                ) : (
                  <SortIcon />
                )}
              </>
            )}
          </span>

          {/* Render suffix */}
          {cell.suffix && <span className="suffix">{cell.suffix}</span>}
        </>
      )}
    </Tag>
  );
};
