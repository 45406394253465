import * as React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../Foundation';
import { Icons, IconWrapper } from '../../Icons';
import { mediaQuery } from '../../Layout';
import { FileUploadTexts, FileWithMeta, FILE_STATUS } from './types';
import { formatBytes } from './utils';

const PROGRESS_INDICATOR_OFFSET = 20;

type Props = FileUploadTexts & {
  file: FileWithMeta;
  handleCancel: (id: string) => void;
  handleRemove: (id: string) => void;
};

export const Preview: React.FC<Props> = ({
  file,
  handleCancel,
  handleRemove,
  errorTexts = {},
}) => {
  const { name, size, status, percent, error } = file;

  const onCancel = React.useCallback(() => handleCancel(file.id), [
    handleCancel,
    file.id,
  ]);
  const onRemove = React.useCallback(() => handleRemove(file.id), [
    handleRemove,
    file.id,
  ]);

  // Get the error message from dictionary
  const errorMessage = error ? errorTexts[error] || error : undefined;

  // Round the percent number
  const percentRounded = Math.round(percent);

  // Due to the skewed progress indicator, we need to do some hacking in order to
  // make sure the corners are covered by the progress indicator as well.
  // We add some extra pixels to the width to cover corners due to skewed element:
  const progressExtraPixels =
    PROGRESS_INDICATOR_OFFSET * 2 * (percentRounded / 100);
  // We combine the percentage with the extra pixels in a CSS calc function:
  const progressWidth = `calc(${percentRounded}% + ${progressExtraPixels}px)`;

  return (
    <FileItem status={status}>
      <ProgressIndicator
        role="progressbar"
        aria-valuenow={percentRounded}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-label="A bounded progress bar from 0 to 100"
        style={{
          width: progressWidth,
          opacity: status === FILE_STATUS.UPLOADING ? 1 : 0,
        }}
      />

      <Contents>
        <IconWrapper size="24px">
          <Icons.File />
        </IconWrapper>

        <FileName>{name || '?'}</FileName>

        {!!errorMessage && (
          <ErrorMessage role="alert" aria-live="polite">
            {errorMessage}
          </ErrorMessage>
        )}

        <FileSize>{formatBytes(size)}</FileSize>

        {status === FILE_STATUS.UPLOADING && (
          <>
            <Percentage>{percentRounded}%</Percentage>

            <IconButton onClick={onCancel} aria-label="Cancel file upload">
              <Icons.Close />
            </IconButton>
          </>
        )}

        {status !== FILE_STATUS.UPLOADING && (
          <IconButton onClick={onRemove} aria-label="Remove file">
            <Icons.Close />
          </IconButton>
        )}
      </Contents>
    </FileItem>
  );
};

const FileItem = styled.div<{ status: FILE_STATUS }>`
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  transition: all 0.3s;

  ${mediaQuery.sm} {
    display: inline-block;
    margin-right: 10px;
  }

  ${({ status }) =>
    status === FILE_STATUS.DONE &&
    css`
      background: #fff;
    `}

  ${({ status }) =>
    (status === FILE_STATUS.ERROR || status === FILE_STATUS.CANCELLED) &&
    css`
      border-color: ${Color.red50};
      background: #fbd9dd;
    `}
`;

const ProgressIndicator = styled.span`
  position: absolute;
  top: 0;
  left: -${PROGRESS_INDICATOR_OFFSET}px;
  bottom: 0;
  transform: skewX(-20deg);
  width: 0%;
  z-index: 0;
  background: ${Color.grey30};
  transition: opacity 0.5s, width 0.1s;
`;

const Contents = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 56px;
  padding: 8px 16px;
`;

const FileName = styled.span`
  flex: 1;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-left: 6px;

  ${mediaQuery.smDown} {
    font-size: 14px;
  }
`;

const ErrorMessage = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: ${Color.red50};
  margin-left: 8px;
`;

const FileSize = styled.span`
  display: inline-block;
  font-size: 12px;
  color: #000;
  opacity: 0.6;
  margin-left: 10px;
`;

const Percentage = styled.span`
  display: inline-block;
  min-width: 50px;
  font-weight: bold;
  text-align: right;
`;

const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0 0 0 10px;

  svg {
    width: 10px;
    height: 10px;

    path {
      fill: #9e9e9e;
    }
  }
`;
