import React from 'react';

const Rooms = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <mask
      id="svg-rooms-a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <path
        d="M21 2a1 1 0 011 1l-.001 6L22 10v11a1 1 0 01-1 1H3a1 1 0 01-1-1V10a1 1 0 01.883-.993L3 9h5.999L9 3a1 1 0 011-1h11zm-.001 1h-11v6h.501v1h-.501l.001.5H9l-.001-.5H3v11h5.999L9 20.5h1l-.001.5h11V10H20.5V9h.499V3zM10 17.625v1.5H9v-1.5h1zm0-2.875v1.5H9v-1.5h1zm0-2.875v1.5H9v-1.5h1zM13.375 9v1h-1.5V9h1.5zm5.75 0v1h-1.5V9h1.5zM16.25 9v1h-1.5V9h1.5z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#svg-rooms-a)">
      <path
        d="M21 2a1 1 0 011 1l-.001 6L22 10v11a1 1 0 01-1 1H3a1 1 0 01-1-1V10a1 1 0 01.883-.993L3 9h5.999L9 3a1 1 0 011-1h11zm-.001 1h-11v6h.501v1h-.501l.001.5H9l-.001-.5H3v11h5.999L9 20.5h1l-.001.5h11V10H20.5V9h.499V3zM10 17.625v1.5H9v-1.5h1zm0-2.875v1.5H9v-1.5h1zm0-2.875v1.5H9v-1.5h1zM13.375 9v1h-1.5V9h1.5zm5.75 0v1h-1.5V9h1.5zM16.25 9v1h-1.5V9h1.5z"
        fill="#000"
      />
    </g>
  </svg>
);

export default Rooms;
