import React from 'react';

const Back = () => (
  <svg viewBox="0 0 529.12 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M477.385 1024.001q-9.997 0-19.995-4.374-9.998-4.374-17.496-13.122L13.747 547.865Q0 532.869 0 512.874q0-19.995 13.747-34.992L439.894 16.743Q454.891 1.747 476.135.497q21.245-1.25 36.242 13.747 14.996 13.747 16.246 34.991 1.25 21.245-13.747 37.491L121.221 512.874l393.655 424.898q14.997 14.996 13.747 36.241-1.25 21.245-16.246 36.241-7.498 6.249-16.246 9.998-8.748 3.749-18.746 3.749z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Back;
