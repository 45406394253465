import React from 'react';

/** @deprecated */
const Raise = () => (
  <svg viewBox="0 0 29 27" width="1em" height="1em">
    <g stroke="#262626" fill="none" fillRule="evenodd">
      <path strokeWidth={2.5} d="M29 25H2V0" />
      <path strokeWidth={3} d="M9 18l4.5-5.5 4.5 3 6-9" />
      <path strokeWidth={3} d="M18.013 6.169l6.913-1.095 1.095 6.913" />
    </g>
  </svg>
);

export default Raise;
