import React from 'react';

/** @deprecated Use Icons.Action.Broke instead.*/
const NoConnection = () => (
  <svg viewBox="0 0 41 47" width="1em" height="1em">
    <g fillRule="nonzero" fill="none">
      <path
        d="M22.648.476c.507-.825 1.747-.512 1.846.406L24.5 1l-.001 17h5.955c1.035 0 1.993.525 2.552 1.384l.106.176c.505.903.515 1.996.038 2.903l-.102.18-1.774 2.881a1 1 0 01-1.759-.945l.055-.103 1.774-2.882a1.046 1.046 0 00-.765-1.586L30.454 20H23.5a1 1 0 01-.993-.883L22.5 19l-.001-14.466-4.61 7.496a1 1 0 01-1.272.383l-.103-.055a1 1 0 01-.383-1.273l.055-.103L22.648.476zM9.726 21.476a1 1 0 011.76.945l-.056.103-1.775 2.884a1.044 1.044 0 00.766 1.585l.125.007H17.5a1 1 0 01.993.883L18.5 28v14.467l4.61-7.485a1 1 0 011.274-.383l.102.055a1 1 0 01.383 1.274l-.055.102-6.462 10.494c-.508.824-1.747.512-1.846-.406L16.5 46V29h-5.953a3.044 3.044 0 01-2.553-1.382l-.107-.177a3.044 3.044 0 01-.037-2.902l.102-.179 1.774-2.884z"
        fill="#000"
      />
      <path
        d="M.293 3.293a1 1 0 011.32-.083l.094.083 39 39a1 1 0 01-1.32 1.497l-.094-.083-39-39a1 1 0 010-1.414z"
        fill="#D50018"
      />
    </g>
  </svg>
);

export default NoConnection;
