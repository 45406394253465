import React, { FC } from 'react';
import styled from 'styled-components';
import { Color, Typography } from '../../Foundation';
import { Icons, IconWrapper } from '../../Icons';
import { Button, ButtonProps } from './Button';

const StyledButton = styled(Button)`
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: rgba(0, 0, 0, 0);
    g {
      stroke: ${Color.rdRed};
      transition: stroke 0.3s ease;
    }
  }
`;

export const EditButton: FC<ButtonProps> = ({ children, ...rest }) => (
  <StyledButton variant="custom" {...rest}>
    <IconWrapper size="18px">
      <Icons.Edit />
    </IconWrapper>
    <Typography type="h7">{children}</Typography>
  </StyledButton>
);
