import React from 'react';

/** @deprecated Use Icons.Action.Information instead.*/
const InfoCircle = () => (
  <svg viewBox="0 0 26 26" width="1em" height="1em">
    <defs>
      <path
        d="M12.5 0C19.404 0 25 5.596 25 12.5S19.404 25 12.5 25 0 19.404 0 12.5 5.596 0 12.5 0zm0 1.532c-6.057 0-10.968 4.91-10.968 10.968 0 6.057 4.91 10.968 10.968 10.968 6.057 0 10.968-4.91 10.968-10.968 0-6.057-4.91-10.968-10.968-10.968zm1.254 8.482v7.821h1.056v.99h-4.274v-.99h1.056v-6.122l-1.056-.214v-.825l3.218-.66zm-1.089-3.762c.407 0 .723.115.949.346.225.231.338.534.338.908v.082l-.007.156a1.195 1.195 0 01-.331.752c-.226.23-.542.346-.949.346-.407 0-.723-.115-.949-.346-.225-.231-.338-.534-.338-.908v-.082l.007-.156c.027-.303.138-.554.331-.752.226-.23.542-.346.949-.346z"
        id="svg-infocircle-a"
      />
    </defs>
    <g transform="translate(.111 .5)" fill="none" fillRule="evenodd">
      <mask id="svg-infocircle-b" fill="#fff">
        <use xlinkHref="#svg-infocircle-a" />
      </mask>
      <use fill="#D8D8D8" xlinkHref="#svg-infocircle-a" />
      <g mask="url(#svg-infocircle-b)" fill="#000">
        <path d="M0 0h25v25H0z" />
      </g>
    </g>
  </svg>
);

export default InfoCircle;
