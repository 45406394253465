import React from 'react';

const Bathrooms = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 5.25a2.75 2.75 0 015.5 0v1.083a.5.5 0 001 0V5.25a3.75 3.75 0 10-7.5 0v9.25H3a1 1 0 00-1 1 5 5 0 004.5 4.975V21.5a.5.5 0 001 0v-1h9v1a.5.5 0 001 0v-1-.025A5 5 0 0022 15.5a1 1 0 00-1-1H4.5V5.25zM3 15.5a4 4 0 004 4h10a4 4 0 004-4H3z"
      fill="#000"
    />
    <path
      d="M8.56 6.58A1.84 1.84 0 0110.382 5h.236a1.84 1.84 0 011.822 1.58.368.368 0 01-.364.42H8.924a.368.368 0 01-.364-.42z"
      fill="#000"
    />
  </svg>
);

export default Bathrooms;
