import orderBy from 'lodash/orderBy';
import toNumber from 'lodash/toNumber';
import { Action, SortDirection, State, TableData } from './types';

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_sorting': {
      return {
        ...state,
        sortColumnIndex: action.payload.sortColumnIndex,
        sortDirection: action.payload.sortDirection,
        sortedRows: action.payload.sortedRows,
      };
    }
    case 'reset': {
      return action.payload.state;
    }

    default:
      throw new Error();
  }
}

export function getNextSortDirection(direction: SortDirection): SortDirection {
  switch (direction) {
    case null:
      return 'asc';
    case 'asc':
      return 'desc';
    case 'desc':
      return null;
  }
}

export function getSortedRows(
  data: TableData,
  skipHeadRows: number,
  columnIndex: number,
  direction: Exclude<SortDirection, null>
): number[] {
  const mapped = data.slice(skipHeadRows).map((row, i) => {
    const value = toNumber(
      String(row[columnIndex].value)
        .replace(',', '.')
        .replace(/s+/, '')
        .replace(/[^\d.-]+/, '')
    );

    return {
      rowIndex: i + skipHeadRows,
      value: typeof value === 'number' ? value : null,
    };
  });
  const sorted = orderBy(mapped, (d) => d.value, [direction]).map(
    (d) => d.rowIndex
  );
  return skipHeadRows ? [0, ...sorted] : sorted;
}
