import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Color } from '../../Foundation';
import { Icons } from '../../Icons';
import { Col, Hidden, mediaQuery } from '../../Layout';
import { constants } from './constants';
import { LinkItem, OnParentFocus } from './types';
import { useMenuNavigation } from './useMenuNavigation';

type DesktopNavigationTopLevelProps = {
  navigationRef: React.RefObject<HTMLElement>;
  loginRef: React.RefObject<HTMLDivElement>;
  links: LinkItem[];
  activeParentIndex: number;
  showNavigationItems: boolean;
  onParentFocus: OnParentFocus;
};

export const DesktopNavigationTopLevel: React.FC<DesktopNavigationTopLevelProps> = ({
  navigationRef,
  loginRef,
  links,
  activeParentIndex,
  showNavigationItems,
  onParentFocus,
}) => {
  const { handleTopLevelKeyDown } = useMenuNavigation();

  /**
   * Callback for handling click events on the menu links.
   * It will stop event propagation and call the "onParentFocus".
   */
  const onClickHandler = useCallback(
    (index: number) => (e: React.SyntheticEvent) => {
      e.stopPropagation();
      onParentFocus(index);
    },
    [onParentFocus]
  );

  return (
    <Hidden xs sm md>
      <Col lg={6.5}>
        <Nav ref={navigationRef} role="navigation">
          {links.map((link, i) => {
            const active = showNavigationItems && activeParentIndex === i;
            const dropdownMenuId = `navdropdown${i}`;
            return (
              <Button
                key={`navbutton${i}`}
                onClick={onClickHandler(i)}
                onKeyDown={(event) => {
                  handleTopLevelKeyDown({
                    event,
                    dropdownMenuId,
                    index: i,
                    loginRef,
                    navigationRef,
                    onParentFocus,
                  });
                }}
                aria-expanded={active}
                aria-haspopup={true}
                aria-controls={dropdownMenuId}
              >
                <Title>
                  {link.name}
                  <Underline active={active} />
                </Title>
                <ArrowIcon>
                  <Icons.ArrowRight />
                </ArrowIcon>
              </Button>
            );
          })}
        </Nav>
      </Col>
    </Hidden>
  );
};

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
`;

const Title = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
`;

const Underline = styled.span<{ active?: boolean }>`
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: ${(props) => (props.active ? '100%' : '0%')};
  height: 2px;
  background: ${Color.rdRed};
  transition: width ${constants.TOP_LEVEL_UNDERLINE_ANIMATION_TIME}ms;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0;
  margin: 0 30px 0 0;
  text-decoration: none;
  transition: padding ${constants.RESPONSIVE_PADDING_ANIMATION_TIME}ms;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  width: auto;
  appearance: none;
  background: transparent;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  ${mediaQuery.xl} {
    margin-right: 40px;
  }

  &:hover ${Underline}, &:focus ${Underline} {
    width: 100%;
  }
`;

const ArrowIcon = styled.span`
  svg {
    width: 10px;
    height: 10px;
    margin-left: 6px;
    margin-top: 4px;
    transform: rotate(90deg); /* TODO: change to real down icon */
  }
`;
