import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieConsentHandlerInterface } from '../../Components';
import {
  ConsentLevel,
  ConsentType,
  useCookieConsent,
  useCookieConsentConfig,
} from './useCookieConsent';

const BANNER_COOKIE = 'dbconsent-banner';
const PROMPT_INTERVAL_DEFAULT = 1; // Note: Change this to higher number to allow page visits without consent

type CookieConsentBannerDefaultHandlerConfig = {
  /**
   * Config for useCookieConsent hook if you want to customize it
   */
  cookieConsentConfig?: useCookieConsentConfig;
  /**
   * The interval of page loads in which the cookie consent prompt/banner is shown.
   */
  promptInterval?: number;
  /**
   * Configure an extra callback for onSubmit handler
   */
  onSubmit?: (consentLevels: number[]) => void;
  /**
   * Hide cookie consent banner onInit (for cookie policy pages)
   */
  disabled?: boolean;
};

export const useCookieConsentBannerDefaultHandler = (
  config?: CookieConsentBannerDefaultHandlerConfig
): CookieConsentHandlerInterface => {
  const {
    cookieConsentConfig,
    promptInterval = PROMPT_INTERVAL_DEFAULT,
    onSubmit: _onSubmit,
    disabled = false,
  } = config || {};

  const cookieConsent = useCookieConsent(cookieConsentConfig);
  const [cookies, setCookie] = useCookies([]);
  const [show, setShow] = useState(false);
  const consentLevels = cookieConsent.getConsentLevels();

  /**
   * Callback for onInit handler
   */
  const onInit = () => {
    // Delete cookies if consent level is Necessary or less
    if (cookieConsent.getLevelMax(consentLevels) <= ConsentLevel.Necessary) {
      cookieConsent.deleteCookies();
    }

    // Active consent given
    if (cookieConsent.getConsentType() === ConsentType.Active) {
      return;
    } else {
      // If banner is ignored/dismissed, show again after promptInterval
      let cookieValue = parseInt(cookies[BANNER_COOKIE]) || 0;
      if (cookieValue > 0 && cookieValue < promptInterval) {
        cookieValue++;
      } else {
        cookieValue = 1;
      }
      setCookie(BANNER_COOKIE, cookieValue, { path: '/', sameSite: 'lax' });

      if (cookieValue > 1) {
        return;
      }
    }
    cookieConsent.init();
    if (!disabled) {
      setShow(true);
    }
  };

  /**
   * Callback for onSubmit handler
   */
  const onSubmit = (consentLevels: number[]) => {
    cookieConsent.setConsent(consentLevels, ConsentType.Active, {});
    if (_onSubmit) {
      _onSubmit(consentLevels);
    }
    onClose();
  };

  /**
   * Callback for onClose handler
   */
  const onClose = () => {
    setShow(false);
  };

  /**
   * Callback for onReopen handler
   */
  const onReopen = () => {
    setShow(true);
  };

  // Update body style when showBanner changes
  useEffect(() => {
    if (typeof document === 'undefined') return;

    if (show) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  }, [show]);

  return {
    show,
    consentLevels,
    onInit,
    onSubmit,
    onClose,
    onReopen,
  };
};
