import * as React from 'react';
import styled from 'styled-components';
import { SlantedOverlay, SlantPositionProps } from './SlantedOverlay';

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

type SlantedImageProps = SlantPositionProps & {
  src: string;
  alt?: string;
};

export const SlantedImage: React.FC<SlantedImageProps> = ({
  slantPosition,
  src,
  alt,
  ...rest
}) => (
  <SlantedOverlay slantPosition={slantPosition} {...rest}>
    <StyledImage src={src} alt={alt} />
  </SlantedOverlay>
);
