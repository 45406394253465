import { css } from 'styled-components';
import { Color, RddkTheme } from '../../Foundation';
import { mediaQuery } from '../../Layout';

/**
 * Base styling for buttons
 */
export const buttonBaseStyle = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 13px 16px 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 ${Color.shadowIdle};
  border-width: 0;
  font-family: ${RddkTheme.fonts.sans};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: normal;
  white-space: nowrap;
  outline: 0;
  user-select: none;
  transition: transform 0.2s;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: default;
  }

  ${mediaQuery.sm} {
    padding: 16px 20px 15px;
  }
`;

/**
 * Additional style for button of type "primary".
 * Must be combined with base style.
 */
export const buttonPrimaryStyle = css`
  color: ${Color.white};
  background-color: ${Color.red50};
  transition: color 0.3s, background-color 0.3s;
  &:disabled {
    box-shadow: none;
    color: ${Color.rdBlackTint2};
    background-color: ${Color.rdBlackTint6};
  }

  &::before {
    transition: all 0.5s ease-out;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 0;
    background-color: ${Color.red40};
    transform: skewX(-20deg);
    z-index: -1;
  }

  &:hover,
  &:focus {
    &::before {
      width: 125%;
    }
  }

  /* No animation when disabled */
  &:disabled:hover,
  &:disabled:focus {
    &::before {
      width: 0;
    }
  }
`;

/**
 * Additional style for button of type "secondary".
 * Must be combined with base style.
 */
export const buttonSecondaryStyle = css`
  color: ${Color.rdPureBlack};
  background-color: ${Color.rdBrightWhite};

  &:disabled {
    opacity: 0.4;
  }

  &::before {
    transition: all 0.2s ease-out;
    content: '';
    display: block;
    height: 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${Color.rdRed};
    z-index: -1;
  }

  &:hover,
  &:focus {
    &::before {
      height: 4px;
    }
  }

  /* No animation when disabled */
  &:disabled:hover,
  &:disabled:focus {
    &::before {
      height: 0;
    }
  }
`;

export const buttonCustomStyle = css`
  color: ${Color.rdPureBlack};
  background-color: ${Color.rdBrightWhite};
  padding: 0;
  box-shadow: none;
  ${mediaQuery.smDown} {
    padding: 0;
  }
  &:disabled {
    opacity: 0.4;
  }
`;
