import React from 'react';

const ArrowRight = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M13.253 13.156a.63.63 0 010-.858l4.78-5.025H.576C.259 7.273 0 7 0 6.666c0-.334.259-.606.576-.606h17.457l-4.78-5.025a.629.629 0 010-.858.558.558 0 01.815 0l5.761 6.06a.615.615 0 01.171.43.636.636 0 01-.166.424c-.001.001-.001.003-.004.005l-5.762 6.06a.556.556 0 01-.815 0"
        id="svg-arrowright-a"
      />
    </defs>
    <g transform="translate(2 5)" fill="none" fillRule="evenodd">
      <mask id="svg-arrowright-b" fill="#fff">
        <use xlinkHref="#svg-arrowright-a" />
      </mask>
      <use
        fill="currentColor"
        fillRule="nonzero"
        xlinkHref="#svg-arrowright-a"
      />
      <g mask="url(#svg-arrowright-b)">
        <path fill="currentColor" d="M-1.333-4H20v21.333H-1.333z" />
      </g>
    </g>
  </svg>
);

export default ArrowRight;
