import React from 'react';

const Logon = () => (
  <svg viewBox="0 0 817.761 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M744.618 949.658q-10.791 10.792-25.18 16.787-14.389 5.995-28.778 5.995l-562.36 1.199q-32.375 0-54.558-22.782-22.182-22.782-22.182-53.958L50.361 410.08h715.84L767.4 895.7q0 15.588-5.995 29.377t-16.787 24.581zM306.96 205.04q0-41.967 29.977-71.944 29.976-29.977 71.944-29.977 43.166 0 73.142 29.977Q512 163.073 512 206.239l1.199 152.281H306.96V205.04zM817.761 383.7q0-9.592-7.794-17.386-7.794-7.794-17.386-7.794H615.119l-1.199-153.48q0-83.935-59.953-144.487Q494.014 0 408.881 0q-83.935 0-144.488 60.553-60.552 60.552-60.552 144.487v153.48H25.18q-4.796 0-9.592 2.398t-8.394 5.995q-3.597 3.597-5.395 8.394Q0 380.103 0 384.899v512q0 52.759 37.77 89.93Q75.541 1024 128.3 1024h562.36q25.181 0 48.562-9.593 23.382-9.592 41.368-27.578 17.986-19.185 27.578-42.567 9.593-23.382 9.593-48.562v-512z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Logon;
