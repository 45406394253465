import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../Modal';
import { InputField } from '../../InputFields/InputField';
import { Color } from '../../../Foundation/Color';
import { mediaQuery } from '../../../Layout';
import {
  SearchResultsType,
  SearchResultType,
  LoadMoreResultsHandler,
  SearchLabels,
} from './types';
import { SearchResults } from './SearchResults';
import { Hits } from './SearchHits';
import { SearchFieldButton } from './SearchFieldButton';
import { Pagination } from './Pagination';

type SearchModalProps = {
  handleClose: (e: any) => void;
  open: boolean;
  handleSearch?: (query: string) => void;
  handleLoadMoreResults: LoadMoreResultsHandler | undefined;
  handleResultClick?: (result: SearchResultType) => void;
  searchResults?: SearchResultsType;
  CustomComponent?: React.FC;
  searchLabels?: SearchLabels;
  resultsPerPage?: number;
  pagesToShow?: number;
  loadMoreStylePagination?: boolean;
  searchQuery?: string;
};
export const SearchModal: React.FC<SearchModalProps> = ({
  handleClose,
  open,
  handleSearch = () => console.warn('No search handler implemented'),
  handleLoadMoreResults = () =>
    console.warn('No load more handler implemented'),
  handleResultClick,
  searchResults = { results: [], hits: null },
  CustomComponent,
  searchLabels = {
    searchInputLabel: 'Søg',
    hitsLabel: '',
    showMoreButtonLabel: 'Vis flere',
  },
  resultsPerPage = 10,
  pagesToShow = 10,
  loadMoreStylePagination,
  searchQuery = '',
}) => {
  const [inputValue, setInputValue] = React.useState(searchQuery);
  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  React.useEffect(() => {
    setInputValue(searchQuery);
  }, [searchQuery]);

  const onSearchInputKeyPress = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        handleSearch(inputValue);
      }
    },
    [handleSearch, inputValue]
  );

  const onSearchButtonClick = React.useCallback(() => {
    handleSearch(inputValue);
  }, [handleSearch, inputValue]);

  let numberOfPages = 0;
  const hits = Number(searchResults.hits);
  if (
    typeof hits === 'number' &&
    searchResults.results?.length &&
    hits > resultsPerPage
  ) {
    numberOfPages = Math.ceil(hits / searchResults.results.length);
  }

  return (
    <Modal
      open={open}
      fullscreen={true}
      ariaCloseLabel="Close Modal"
      handleClose={handleClose}
      backgroundColor="#fff"
      top={110}
      zIndex={1999}
    >
      <SearchContainer>
        <SearchResultsHeader>
          <SearchFieldContainer>
            <label>
              <InputField
                label={searchLabels.searchInputLabel}
                trailingElement={
                  <SearchFieldButton onClick={onSearchButtonClick} />
                }
                onKeyPress={onSearchInputKeyPress}
                focusOnRender={true}
                layout="white"
                value={inputValue}
                onChange={handleInputChange}
              />
            </label>
          </SearchFieldContainer>
          <Hits hitsLabel={searchLabels.hitsLabel} count={searchResults.hits} />
        </SearchResultsHeader>
        {CustomComponent}
        <SearchResults
          handleResultClick={handleResultClick}
          results={searchResults.results}
        />
        <Pagination
          query={inputValue}
          hits={searchResults.hits}
          numberOfPages={numberOfPages}
          currentPage={searchResults.currentPage}
          handleLoadMoreResults={handleLoadMoreResults}
          showMoreLabel={searchLabels.showMoreButtonLabel}
          resultsPerPage={resultsPerPage}
          pagesToShow={pagesToShow}
          loadMoreStylePagination={loadMoreStylePagination}
        ></Pagination>
      </SearchContainer>
    </Modal>
  );
};

const SearchFieldContainer = styled.div`
  width: 442px;

  ${mediaQuery.smDown} {
    width: 80%;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchResultsHeader = styled.div`
  height: 247px;
  background: ${Color.grey20};
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
