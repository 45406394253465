import React from 'react';

/** @deprecated Use Icons.Action.Close instead.*/
const Delete = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm3.787 12.273a1.071 1.071 0 01-1.514 1.516L10 11.516l-2.273 2.273a1.071 1.071 0 11-1.514-1.516L8.486 10 6.213 7.727a1.071 1.071 0 111.514-1.516L10 8.484l2.273-2.273a1.071 1.071 0 011.514 1.516L11.514 10l2.273 2.273z"
      fill="#D9D9D9"
      fillRule="nonzero"
    />
  </svg>
);

export default Delete;
