import React from 'react';

const BookMeeting = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M17.62 12.288h-2.483V9.15h2.484c.087 0 .161-.03.222-.091a.303.303 0 00.092-.222.303.303 0 00-.092-.223.303.303 0 00-.222-.091h-2.484V6.797a.303.303 0 00-.091-.222.302.302 0 00-.222-.091.303.303 0 00-.223.091.303.303 0 00-.091.222v1.726H10.3V6.745a.303.303 0 00-.09-.222.302.302 0 00-.223-.092.303.303 0 00-.222.092.303.303 0 00-.092.222v1.778H5.438V6.745a.303.303 0 00-.092-.222.303.303 0 00-.222-.092.303.303 0 00-.222.092.303.303 0 00-.092.222v1.778H2.353c-.07 0-.135.03-.196.091a.303.303 0 00-.092.223c0 .087.03.16.092.222a.275.275 0 00.196.091H4.81v3.138H2.353c-.07 0-.135.03-.196.091a.303.303 0 00-.092.222c0 .087.03.162.092.223a.275.275 0 00.196.091H4.81v1.987c0 .087.031.161.092.222a.303.303 0 00.222.092c.087 0 .161-.03.222-.092a.303.303 0 00.092-.222v-1.987h4.235v1.987c0 .087.03.161.092.222a.303.303 0 00.222.092c.087 0 .161-.03.222-.092a.303.303 0 00.092-.222v-1.987h4.209v1.987c0 .087.03.161.091.222a.303.303 0 00.223.092c.087 0 .16-.03.222-.092a.303.303 0 00.091-.222v-1.987h2.484c.087 0 .161-.03.222-.091a.303.303 0 00.092-.223.303.303 0 00-.092-.222.303.303 0 00-.222-.091zm-12.182 0V9.15h4.235v3.138H5.438zm4.863 0V9.15h4.209v3.138H10.3zM0 15.45c0 .418.148.776.444 1.072a1.46 1.46 0 001.072.444h16.968a1.46 1.46 0 001.072-.444A1.46 1.46 0 0020 15.45V2.275a.232.232 0 00-.026-.105.41.41 0 00-.079-.105.41.41 0 00-.104-.078.232.232 0 00-.105-.026h-5.15V.314a.303.303 0 00-.092-.222.303.303 0 00-.222-.092.303.303 0 00-.222.092.303.303 0 00-.092.222V1.96H6.065V.314a.303.303 0 00-.091-.222A.303.303 0 005.752 0a.303.303 0 00-.223.092.303.303 0 00-.091.222V1.96H.314a.303.303 0 00-.222.091.303.303 0 00-.092.223V15.45zm19.373 0a.856.856 0 01-.262.627.856.856 0 01-.627.262H1.516a.856.856 0 01-.627-.262.856.856 0 01-.262-.627V5.281h18.746v10.17z"
        id="svg-bookmeeting-a"
      />
    </defs>
    <g transform="translate(2 4)" fill="none" fillRule="evenodd">
      <mask id="svg-bookmeeting-b" fill="#fff">
        <use xlinkHref="#svg-bookmeeting-a" />
      </mask>
      <use
        fill="currentColor"
        fillRule="nonzero"
        xlinkHref="#svg-bookmeeting-a"
      />
      <g mask="url(#svg-bookmeeting-b)">
        <path fill="currentColor" d="M0-1.25h20v20H0z" />
      </g>
    </g>
  </svg>
);

export default BookMeeting;
