import React from 'react';

const HomeSize = () => (
  <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em">
    <mask
      id="svg-homesize-a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={2}
      width={19}
      height={20}
    >
      <path
        d="M12.79 2.079l2.842 2.319v-.843c0-.317.21-.527.526-.527h3.158c.316 0 .526.21.526.527v4.321l1.895 1.581c.105.106.21.211.21.422v11.594c0 .316-.21.527-.526.527H3.526C3.211 22 3 21.79 3 21.473V9.879c0-.106.105-.316.21-.422l8.948-7.378a.67.67 0 01.632 0zm-.316 1.054L4.053 10.09v10.856h16.842V10.09L19 8.614c-.105-.105-.21-.21-.21-.422v-4.11h-2.106v1.475c0 .211-.105.317-.316.422-.105 0-.42 0-.526-.105l-3.368-2.74zM16.21 9l.053.003a.943.943 0 01.947.944v2.58a.474.474 0 11-.947 0v-1.821L9.353 18h1.673a.5.5 0 110 1h-2.29l-.052-.003a.943.943 0 01-.947-.944v-2.58a.474.474 0 01.947 0v1.819L15.593 10H13.92a.5.5 0 110-1h2.29z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#svg-homesize-a)">
      <path
        d="M12.79 2.079l2.842 2.319v-.843c0-.317.21-.527.526-.527h3.158c.316 0 .526.21.526.527v4.321l1.895 1.581c.105.106.21.211.21.422v11.594c0 .316-.21.527-.526.527H3.526C3.211 22 3 21.79 3 21.473V9.879c0-.106.105-.316.21-.422l8.948-7.378a.67.67 0 01.632 0zm-.316 1.054L4.053 10.09v10.856h16.842V10.09L19 8.614c-.105-.105-.21-.21-.21-.422v-4.11h-2.106v1.475c0 .211-.105.317-.316.422-.105 0-.42 0-.526-.105l-3.368-2.74zM16.21 9l.053.003a.943.943 0 01.947.944v2.58a.474.474 0 11-.947 0v-1.821L9.353 18h1.673a.5.5 0 110 1h-2.29l-.052-.003a.943.943 0 01-.947-.944v-2.58a.474.474 0 01.947 0v1.819L15.593 10H13.92a.5.5 0 110-1h2.29z"
        fill="#000"
      />
    </g>
  </svg>
);

export default HomeSize;
