export enum BoxShadow {
  /** @deprecated Use small instead */
  grey = '0 3px 6px 0 rgba(0,0,0,0.15)',
  /** @deprecated Use medium instead */
  modal = '5px 17px 14px 13px rgba(140, 140, 140, 0.07)',
  // Zeplin: https://zpl.io/a8nomNd
  small = '0 5px 14px 0 rgba(0, 0, 0, 0.1)',
  medium = '0 10px 24px 0 rgba(0, 0, 0, 0.1)',
  /** Use for hover state */
  large = '0 20px 44px 0 rgba(0, 0, 0, 0.15)',
}
