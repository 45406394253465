import React from 'react';

/** @deprecated Use Icons.Action.Checkmark instead.*/
const Checkmark = () => (
  <svg viewBox="0 0 25 25" width="1em" height="1em">
    <defs>
      <linearGradient
        id="svg-checkmark-b"
        x1="51.646%"
        x2="51.646%"
        y1="-222.637%"
        y2="155.195%"
      >
        <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
        <stop offset="100%" stopColor="#8A727A" />
      </linearGradient>
      <circle id="svg-checkmark-a" cx={12.5} cy={12.5} r={12.5} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g opacity={0.3}>
        <use fill="#F3EDEF" xlinkHref="#svg-checkmark-a" />
        <use
          fill="url(#svg-checkmark-b)"
          fillOpacity={0.61}
          xlinkHref="#svg-checkmark-a"
        />
      </g>
      <path
        stroke="#292929"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7.432 12.778l4.242 3.79 5.053-6.653"
      />
    </g>
  </svg>
);

export default Checkmark;
