import React, { CSSProperties } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import styled from 'styled-components';
import { Color } from '../../Foundation';
import { mediaQuery } from '../../Layout';
import {
  ClearIndicator,
  Control,
  NoOptionsMessage,
  ValueContainer,
} from './CustomComponents';
import { menuListStyles, optionStyles } from './styles';

export type OptionType = {
  value: string;
  label: string;
};

export type SearchProps = {
  options: OptionType[];
  onChange:
    | React.Dispatch<React.SetStateAction<OptionType>>
    | React.Dispatch<React.SetStateAction<OptionType[]>>;
  noOptionsMessage?: string;
  noOptionsTitle?: string;
  floatingLabel?: string;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  width?: string | null;
  filterOption?:
    | ((option: OptionType, rawInput: string) => boolean)
    | null
    | undefined;
};

export function Search({
  floatingLabel = 'Search',
  noOptionsTitle = '',
  onChange,
  noOptionsMessage = 'No options available for this search',
  isMulti = false,
  closeMenuOnSelect = true,
  width = null,
  ...rest
}: SearchProps) {
  const [noOptions, setNoOptions] = React.useState(false);
  return (
    <SearchContainer>
      <ReactSelect
        floatingLabel={floatingLabel}
        onChange={(selectedOption: ValueType<OptionType>) => {
          onChange(selectedOption as any);
        }}
        components={{
          ClearIndicator,
          Control,
          ValueContainer,
          NoOptionsMessage: (noOptionsMessageProps: any) => (
            <NoOptionsMessage
              {...noOptionsMessageProps}
              noOptionsTitle={noOptionsTitle}
            />
          ),
        }}
        defaultValue={null}
        autoFocus={false}
        backspaceRemovesValue={true}
        hideSelectedOptions={true}
        isLoading={false}
        isClearable={true}
        isSearchable={true}
        captureMenuScroll={true} // set to false for third party scrollbar
        // menuIsOpen={true} // useful for development
        placeholder=""
        noOptionsMessage={() => noOptionsMessage}
        noOptions={noOptions}
        setNoOptions={setNoOptions}
        styles={{
          clearIndicator: (base: CSSProperties, state: any) => ({
            ...base,
            cursor: 'pointer',
            border: 'none',
            backgroundColor: state.isFocused
              ? Color.rdBrightWhite
              : Color.whiteSmoke,
          }),

          container: (base: CSSProperties) => ({
            ...base,
            border: 'none', // main border
          }),

          control: (base: CSSProperties) => ({
            ...base,
            border: 'none', // Control border
            boxShadow: 'none',
            display: 'inline-flex', // to align everything in custom Control to one line
            height: '56px',
            width: width || '207px', // width of Control (280px)
            margin: '0 auto', // center the Control element to drop-down

            backgroundColor: 'rgba(0,0,0,0)', // transparent, so CustomControl can define it
            borderRadius: '5px',

            [mediaQuery.md]: {
              width: width || '311px',
            },
          }),

          dropdownIndicator: (base: CSSProperties) => ({
            ...base,
            display: 'none', // remove entire indicator
            // "& svg": { display: "none" }, // remove default arrow
          }),

          // remove separator
          indicatorSeparator: (base: CSSProperties) => ({
            ...base,
            display: 'none',
          }),

          valueContainer: (base: CSSProperties) => ({
            ...base,
            overflow: 'visible',
            position: 'relative',
            top: '8px',
            cursor: 'pointer',
          }),

          // incompatible types prevent from abstracting this to a file
          noOptionsMessage: (base: CSSProperties) => ({
            ...base,
            color: Color.rdBlackTint2,
            fontFamily: 'IBM Plex Sans, sans-serif',
            fontSize: '1.25rem',
            lineHeight: '1.5',
            textAlign: 'left',
            margin: '0px 9px',
          }),

          menuList: menuListStyles,
          option: optionStyles,
        }}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        {...rest}
      />
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  display: flex;
`;
