import { useEffect } from 'react';

export const useHandleModalOpen = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  open: boolean,
  handleClose?: (e: KeyboardEvent) => void
) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const elementArray: HTMLElement[] = determineFocuseableElements(
      ref.current
    );
    const handleTrapTabKey = (event: KeyboardEvent) => {
      trapTabKey(event, elementArray, handleClose);
    };
    document.addEventListener('keydown', handleTrapTabKey);
    return () => {
      document.removeEventListener('keydown', handleTrapTabKey);
    };
  }, [ref, handleClose, open]);
};

const determineFocuseableElements = (ref: HTMLDivElement) => {
  const focusableElementsString =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
  const elementArray: HTMLElement[] = [].slice.call(
    ref.querySelectorAll(focusableElementsString)
  );
  return elementArray;
};

const trapTabKey = (
  e: KeyboardEvent,
  elementArray: HTMLElement[],
  handleClose?: false | ((e: KeyboardEvent) => void)
) => {
  if (elementArray.length) {
    const firstTabStop = elementArray[0];
    const lastTabStop = elementArray[elementArray.length - 1];
    if (e.keyCode === 9 || e.key === 'Tab') {
      if (!elementArray.includes(e.target as HTMLElement)) {
        e.preventDefault();
        firstTabStop.focus();
      }
      if (e.shiftKey || e.key === 'Shift') {
        if (document.activeElement === firstTabStop) {
          e.preventDefault();
          lastTabStop.focus();
        }
      } else {
        if (document.activeElement === lastTabStop) {
          e.preventDefault();
          firstTabStop.focus();
        }
      }
    }
  }

  if (e.keyCode === 27 || e.key === 'Escape') {
    !!handleClose && handleClose(e);
  }
};
