import * as React from 'react';
import styled from 'styled-components';
import { SlantedOverlay, SlantPositionProps } from './SlantedOverlay';

const BackgroundImage = styled.div<{ backgroundImage: string }>`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;
`;

type SlantedBackgroundImageProps = SlantPositionProps & {
  src: string;
  alt?: string;
};

export const SlantedBackgroundImage: React.FC<SlantedBackgroundImageProps> = ({
  slantPosition,
  src,
  ...rest
}) => (
  <SlantedOverlay slantPosition={slantPosition} {...rest}>
    <BackgroundImage backgroundImage={src} />
  </SlantedOverlay>
);
