import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { Color, RddkTheme, Typography } from '../../../Foundation';
import { Icons } from '../../../Icons';

export const Control = ({
  selectProps: { noOptions, floatingLabel },
  ...props
}: any) => {
  return (
    <Container isFocused={props.isFocused}>
      <Typography type="h7" display="inline" lineHeight="18px !important">
        <CustomIconWrapper noOptions={noOptions}>
          <Icons.Search />
        </CustomIconWrapper>
        <Separator />
        <Label isFloating={props.isFocused || props.hasValue}>
          {floatingLabel}
        </Label>
        <components.Control {...props} />
      </Typography>
    </Container>
  );
};

const Container = styled.div<{ isFocused?: boolean }>`
  background-color: ${Color.whiteSmoke};
  background-color: ${(props) =>
    props.isFocused ? Color.rdBrightWhite : Color.whiteSmoke};
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.isFocused ? `0 2px 6px 0 rgba(0, 0, 0, 0.15)` : `none`};
`;

const Separator = styled.div`
  height: 22px;
  width: 2px;
  margin: 0px 1px 0px 14px; /* space between icon & separator */
  background-color: ${Color.rdGrey};
  display: inline-block;
  position: relative;
  top: 6px;
`;

const Label = styled.label<{ isFloating?: boolean }>`
  left: 64px;
  pointer-events: none;
  font-family: ${RddkTheme.fonts.sans};
  font-style: italic;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  line-height: 1.2rem;

  top: ${(props) => (props.isFloating ? `7px` : `19px`)};
  color: ${(props) => (props.isFloating ? `#4f4f4f` : `#6b6b6b`)};
  letter-spacing: ${(props) => (props.isFloating ? `0.3px` : `normal`)};
  font-size: ${(props) => (props.isFloating ? `0.688rem` : `0.938rem`)};
`;

const CustomIconWrapper = styled.span<{ noOptions: boolean }>`
  svg {
    width: 16px;
    height: 22px;
    margin: 0px 0px 0px 20px;
    position: relative;
    top: 7px;
  }
  ellipse {
    stroke: ${(props) => (props.noOptions ? Color.rdRed : Color.rdPureBlack)};
  }
  path {
    fill: ${(props) => (props.noOptions ? Color.rdRed : Color.rdPureBlack)};
  }
`;
