import React from 'react';

/** @deprecated Use Icons.Action.SpeechBubble instead.*/
const Topic = () => (
  <svg viewBox="0 0 20 20" width="1em" height="1em">
    <path
      d="M1 14V1h18v13h-9l-5 4v-4z"
      stroke="#D50018"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default Topic;
