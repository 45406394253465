import React from 'react';

/** @deprecated Use Icons.Action.ArrowRight instead.*/
const ArrowLongRight = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <path
      d="M15.226 18.367a.603.603 0 010-.836l4.78-4.9H2.548a.585.585 0 01-.576-.592c0-.325.259-.59.576-.59h17.456l-4.78-4.9a.602.602 0 010-.837c.226-.23.59-.23.816 0l5.76 5.909a.598.598 0 01.006.833c-.002 0-.002.003-.004.004l-5.762 5.909a.566.566 0 01-.815 0"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLongRight;
