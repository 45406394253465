import React from 'react';

const Next = () => (
  <svg viewBox="0 0 529.441 1024" width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        d="M52.056 1024q-9.997 0-18.745-3.749-8.748-3.749-16.246-9.998Q.819 995.257.194 974.012q-.625-21.245 14.371-36.241L408.22 511.624 14.565 86.726Q-.431 70.48.194 49.235q.625-21.244 16.871-34.991Q32.061-.753 53.306.497q21.245 1.25 36.241 16.246l426.147 459.889q13.747 14.996 13.747 34.992 0 19.995-13.747 34.991L89.547 1006.504q-7.498 8.748-17.495 13.122Q62.054 1024 52.056 1024z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default Next;
